<template>
    <div>
        <!-- 详情页content -->
        <el-main>
            <el-row type="flex" class="row-bg" justify="center">
                <el-col :span="16" style="width:1200px;margin:0 auto;flex-shrink: 0;">


                    <div class="xiangmu-detail-div">
                        <h1>{{item.name}}</h1>
                        <el-row :gutter="20">
                            <el-col :span="3">
                                <div class="grid-content">
                                    <div class="detail-lan">联系人</div>
                                    <div class="detail-words">{{item.contact_name}}</div>
                                </div>
                            </el-col>
                            <el-col :span="3">
                                <div class="grid-content">
                                    <div class="detail-lan">联系方式</div>
                                    <div class="detail-words">{{item.contact_number}}</div>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider></el-divider>

                        <el-row :gutter="20">
                            <el-col :span="1">
                                <div class="grid-content detail-small-pic">
                                    <img src="../assets/pic/xiangmu_detail_small1.png" alt="">
                                </div>
                            </el-col>
                            <el-col :span="23">
                                <div class="grid-content" style="text-align:left;margin-left:30px;margin-top:25px;">
                                    <div class="detail-title">预计投资总额</div>
                                    <div class="detail-content">{{item.investment_amount}}（万元）</div>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row :gutter="20">
                            <el-col :span="1">
                                <div class="grid-content detail-small-pic">
                                    <img src="../assets/pic/xiangmu_detail_small2.png" alt="">
                                </div>
                            </el-col>
                            <el-col :span="23">
                                <div class="grid-content" style="text-align:left;margin-left:30px;margin-top:25px;">
                                    <div class="detail-title">预计税金总额</div>
                                    <div class="detail-content">{{item.tax_amount}}（万元）</div>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row :gutter="20">
                            <el-col :span="1">
                                <div class="grid-content detail-small-pic">
                                    <img src="../assets/pic/xiangmu_detail_small3.png" alt="">
                                </div>
                            </el-col>
                            <el-col :span="23">
                                <div class="grid-content" style="text-align:left;margin-left:30px;margin-top:25px;">
                                    <div class="detail-title">所属产业</div>
                                    <div class="detail-content">{{item.industry}}</div>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row :gutter="20">
                            <el-col :span="1">
                                <div class="grid-content detail-small-pic">
                                    <img src="../assets/pic/xiangmu_detail_small4.png" alt="">
                                </div>
                            </el-col>
                            <el-col :span="23">
                                <div class="grid-content" style="text-align:left;margin-left:30px;margin-top:25px;">
                                    <div class="detail-title">意向地区</div>
                                    <div class="detail-content">{{item.intent_area}}</div>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row :gutter="20">
                            <el-col :span="1">
                                <div class="grid-content detail-small-pic">
                                    <img src="../assets/pic/xiangmu_detail_small5.png" alt="">
                                </div>
                            </el-col>
                            <el-col :span="23">
                                <div class="grid-content" style="text-align:left;margin-left:30px;margin-top:25px;">
                                    <div class="detail-title">有效时间</div>
                                    <div class="detail-content">
                                        {{item.effective_begin_time}}~{{item.effective_end_time}}
                                    </div>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row :gutter="20">
                            <el-col :span="1">
                                <div class="grid-content detail-small-pic">
                                    <img src="../assets/pic/xiangmu_detail_small6.png" alt="">
                                </div>
                            </el-col>
                            <el-col :span="23">
                                <div class="grid-content" style="text-align:left;margin-left:30px;margin-top:25px;">
                                    <div class="detail-title">需求类型</div>
                                    <div class="detail-content">{{item.demand_type}}</div>
                                </div>
                            </el-col>
                        </el-row>

                        <el-row :gutter="20">
                            <el-col :span="1">
                                <div class="grid-content detail-small-pic">
                                    <img src="../assets/pic/xiangmu_detail_small7.png" alt="">
                                </div>
                            </el-col>
                            <el-col :span="21" style="padding-bottom:100px">
                                <div class="grid-content" style="text-align:left;margin-left:30px;margin-top:25px;">
                                    <div class="detail-title">项目详情</div>
                                    <div class="detail-content">{{item.project_details}}</div>
                                </div>
                            </el-col>
                        </el-row>

                    </div>
                </el-col>
            </el-row>

        </el-main>
    </div>
</template>
<script>
    export default {
        name: "projectLibraryDetail",

        data() {
            return {
                show: false,
                isAuth: true,
                token: "",
                centerDialogVisible: false,
                squareUrl:
                    "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
                profile: "",
                item: ""
            };
        },
        created() {
            if(this.$route.params.record){
                this.item = this.$route.params.record;
            }else{
                this.item = JSON.parse(sessionStorage.getItem("projectDetialRecord")) 
            } 
        },
        components: {},
        methods: {}
    };
</script>

<style scoped>
    .detail-title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        line-height: 18px;
    }

    .detail-content {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 22px;
        margin-top: 11px;
        width: 100%;
        /* letter-spacing:1px; */
    }

    .detail-small-pic img {
        width: 20px;
        height: 20px;
        margin-left: 42px;
        margin-top: 25px;
    }

    .detail-lan {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 16px;
        text-align: left;
        margin-left: 40px;
    }

    .detail-words {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(250, 100, 0, 1);
        line-height: 24px;
        margin-top: 16px;
        text-align: left;
        margin-left: 40px;
    }

    .xiangmu-detail-div {
        width: 100%;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);

        background-image: url("../assets/pic/c_bg.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center bottom;
        /* background-attachment: fixed; */
        height: 100%;
    }

    h1 {
        text-align: left;
        padding: 40px;
    }
</style>