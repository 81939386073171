<template>
    <div>
        <el-row type="flex" class="footer" style=" background:rgba(0,0,0,0.8);padding-bottom:20px" justify="center">
            <el-col style="width:1200px">
                <el-row type="flex">
                    <div class="grid-nav footer">
                        <img class="web-logo" alt=" logo" src="../assets/footer_logo.png"/>
                    </div>
                </el-row>
                <el-row class="footer-contain" type="flex">
                    <el-col :span="4" v-if="false"
                    >
                        <div class="grid-content">
                            <h1>成功案例</h1>
                            <a >新疆·图木舒克</a>
                            <a >湖南·怀化</a>
                        </div>
                    </el-col>
                    <el-col :span="4"
                    >
                        <div class="grid-content">
                            <h1>业务网络</h1>
                            <a >上海总部</a>
                            <a >湖南分部</a>
                            <a >新疆分部 </a>
                            <a >安徽分部</a>
                        </div>
                    </el-col>
                    <el-col :span="4"
                    >
                        <div class="grid-content">
                            <h1>关于我们</h1>
                            <a >核心团队 </a>
                            <a >服务优势 </a>
                            <a >合作伙伴</a>
                        </div>
                    </el-col>
                    <el-col :span="4"
                    >
                        <div class="grid-content">
                            <h1>联系方式</h1>
                            <a >400-324-123</a>
                        </div>
                    </el-col>
                    <el-col :span="2">
                        <div class="grid-content"></div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content">
                            <el-row class="" type="flex">

                                <div class="qr-code">
                                    <img class="qr-logo" alt=" logo" src="../assets/xiaoguanjia.png"/>
                                    <div class="qr-text">
                                        招商小管家
                                    </div>
                                </div>
                                <div class="qr-code">
                                    <img class="qr-logo" alt=" logo" src="../assets/huoqu.png"/>
                                    <div class="qr-text">
                                        扫一扫获取最新资讯
                                    </div>
                                </div>
                            </el-row>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="" type="flex">
                    <div class="footer-text">
                        版权所有 © 2020 上海形而上科技有限公司 <a href="//www.beian.miit.gov.cn" target="_blank">沪ICP备20002928号-1</a><img style="    padding-left: 20px;
    height: 12px;
    padding-right: 5px;" src="../assets/police_record.png" /><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202012447">沪公网安备31011202012447</a>
                    </div>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "mfooter",

        data() {
            return {
                show: false,
                isAuth: true,
                token: "",
                centerDialogVisible: false,
                squareUrl:
                    "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
                profile: ""
            };
        },
        created() {
        },
        components: {},
        methods: {}
    };
</script>

<style scoped lang="scss">
    .avatar-nav {
        float: right;
        margin-top: 15px;
    }

    .el-col {
        border-radius: 4px;
    }

    .footer {
        text-align: left;

    }

    .web-logo {
        width: 181px;
        /* height:38px; */
        margin-top: 23px;
    }

    .line {
        width: 100%;
        height: 1px;
        background: rgba(255, 255, 255, 0.2);
    }

    .footer-contain {
        color: #ffffff;
        margin-top: 20px;
        padding-top: 30px;
        border-top: solid 1px rgba(255, 255, 255, 0.2);
        border-bottom: solid 1px rgba(255, 255, 255, 0.2);
        height: 240px;
    }

    .grid-content h1 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 30px;
    }
    .grid-content a {
        cursor:default;
    //     -webkit-user-select:none;
    //     -ms-user-select : none;
    //     user-select:none ;
    }
    .footer-text {
        text-align: left;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.4);
        padding-top: 8px;
    }

    .qr-text {
        text-align: center;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.4);
        padding-top: 8px;
    }

    .footer-contain a {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.4);
        display: block;
        text-decoration: none;
        line-height: 30px;
    }

    .qr-code {
        width: 50%;
        text-align: center;
    }

    .qr-logo {
        width: 84px;
        height: 84px;
    }
</style>
