<template>
  <div style="margin-bottom: 3px">
    <el-row type="flex" class="row-bg" justify="center">
      <el-col class="navBox" style="min-width: 1200px; max-width: 1920px">
        <div class="logoBox" style="height: 50px">
          <img
            class="web-logo"
            style="width: 150px"
            alt=" logo"
            src="../assets/pic/logo_white.png" />
          <img
            class="web-logo web-logo2"
            alt=" logo"
            :src="baseUrl +'/sys/common/view/' + logo" />
        </div>
        <div class="grid-content bg-nav">
          <el-menu
            :router="true"
            :default-active="activeLink"
            text-color="#333"
            active-text-color="#004098"
            class="el-menu-nav"
            mode="horizontal"
          >
            <el-menu-item index="/myPark" v-if="permiss.myPark"
              >我的园区
              <div style="position: absolute; left: 50%">
                <div class="nav-ind"></div>
              </div>
            </el-menu-item>
            <el-menu-item index="/zhaoshang" v-if="permiss.zhaoshang"
              >产业招商地图
              <div style="position: absolute; left: 50%">
                <div class="nav-ind"></div>
              </div>
            </el-menu-item>
            <el-menu-item
              index="/merchantsEngine"
              v-if="permiss.merchantsEngine"
              >大数据招商引擎
              <div style="position: absolute; left: 50%">
                <div class="nav-ind"></div>
              </div>
            </el-menu-item>
            <el-menu-item
              index="/industryChainMap"
              v-if="permiss.industryChainMap"
              >产业链库
              <div style="position: absolute; left: 50%">
                <div class="nav-ind"></div>
              </div>
            </el-menu-item>
            <el-menu-item index="/projectLibrary" v-if="permiss.projectLibrary"
              >项目库
              <div style="position: absolute; left: 50%">
                <div class="nav-ind"></div>
              </div>
            </el-menu-item>
            <el-menu-item index="/guanjia" v-if="permiss.guanjia">
                招商小管家
              <div style="position: absolute; left: 50%">
                <div class="nav-ind"></div>
              </div>
            </el-menu-item>
            <el-menu-item> </el-menu-item>
          </el-menu>
          <!-- 退出el-icon-s-promotion -->
          <div
            style="
              font-size: 16px;
              color: #004098;
              line-height: 40px;
              font-weight: bold;
            "
          >
            欢迎您，{{ username }}
          </div>
          <div class="exit" @click="exit">
            <img style="width: 22px" src="../assets/pic/exit.png" alt="" />
            <span>退出</span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { loadDepartMenu } from "../api/api";

export default {
  name: "mheader",
  data() {
    return {
      activeLink: null,
      baseUrl: "",
      // activeLink: '/yuanqu',
      show: false,
      isAuth: true,
      token: "",
      centerDialogVisible: false,
      profile: "",
      logo: "",
      arr: [
        "myPark",
        "zhaoshang",
        "merchantsEngine",
        "industryChainMap",
        "projectLibrary",
        "guanjia",
      ],
      permiss: {},
      username: "",
    };
  },
  watch: {
    $route: {
      handler(route) {
        var res = route.path.split("/");
        var arr = this.arr;
        if (arr.indexOf(res[1]) > -1) {
          this.activeLink = "/" + res[1];
        }
      },
    },
  },
  created() {
    var res = this.$route.path.split("/");
    this.activeLink = "/" + res[1];
    // console.log(res);
    // console.log(this.activeLink);
    this.baseUrl = this.$api.baseUrl;
    this.initMenu();
    this.username = sessionStorage.getItem("username");
  },
  components: {},
  methods: {
    initMenu() {
      let that = this;
      loadDepartMenu({}).then((res) => {
        if (200 == res.code) {
          that.logo = res.result.avatar;
          that.permiss = res.result.codeDict;
        }
      });
    },
    exit() {
      sessionStorage.removeItem("username");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("company");
      sessionStorage.clear();
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.navBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 25px 30px;
  box-shadow: 0 2px 10px 0 #eee;

  .web-logo2 {
    //height: 100%;
    height: 35px;
    padding-left: 10px;
    margin-left: 10px;
    border-left: 1px solid #d8d8d8;
  }

  .logoBox {
    display: flex;
    align-items: center;
  }

  .el-menu-item {
    font-size: 16px;
    font-weight: bold;
    padding: 0 12px;
  }

  .exit {
    cursor: pointer;
    color: #004098;
    font-weight: bold;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #d8d8d8;
    line-height: 40px;
    display: flex;
    align-items: center;

    span {
      margin-left: 8px;
    }
  }
}
</style>
<style scoped>
.avatar-nav {
  float: right;
  margin-top: 15px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-nav {
  background: #fff;
  display: flex;
  align-items: center;
}

.row-bg {
  background: #fff;
}

.bg-purple-light {
  background: #e5e9f2;
}

.el-menu-nav {
  border-bottom: none !important;
}

.is-active {
  border-color: transparent !important;
}

.grid-nav {
  text-align: left;
}

.nav-ind {
  height: 2px;
  width: 20px;
  margin: auto;
  /* position:absolute; */
  background-color: #004098;
  top: 0px;
  position: relative;
  left: -50%;
  display: none;
}

.is-active .nav-ind {
  display: block;
}
</style>
