<template>

    <div class="bg-pic">
        <!-- 蠢办法加载地图 -->
        <!-- <baidu-map></baidu-map> -->
        <el-main style="background:transparent" :loading="loading">
            <el-row type="flex" class="row-bg" justify="center">
                <el-col :span="16">

                    <!-- banner大图 -->
                    <div style="text-align:left">
                        <h1 style="padding-top:80px">产业招商地图</h1>
                        <span class="banner-pic-words">多角度呈现企业分布</span>
                    </div>

                    <!-- 中间三个小div -->
                    <section  class="banner-middle" style="display:flex;">
                        <div v-for="(keyIndustry,index) in keyIndustryData" :key="keyIndustry.id">
                            <div :style="'background-size: cover;margin-left: 5px;background-image: url('+keyIndustry.industryMapCover+')'" 
                                :class=" middle_change===index ?'banner-bg':'banner-bg-small' "
                                @click="middle_change=index,middle_off=index,cqcat=keyIndustry.industryName,cqcatChange(keyIndustry.industryName)">
                                <div class="banner-bg-div-margin">
                                    <img :src="keyIndustry.industryMapIcon" style="width:32px;height:32px"  alt="">
                                    <span class="banner-bg-span">
                                        <a v-text="keyIndustry.industryName"></a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- 热力图 -->
                    <div class="hot_map" style="text-align:center">
                        <div class="float-tab-div">
                            <div v-if="showPanel==false"
                                 :class="{'tab_selected1':clickon===1,'tab_company' : common_off !==1}"
                                 @click="clickon=1,common_off=1,tab_change()">企业分布<p
                                    v-show="common_off===1" class="tab-words-small">全国{{cqcat}}行业相关企业位置聚集分布</p></div>
                            <div v-if="showPanel==false"
                                 :class="{'tab_selected2':clickon===2,'tab_money' : common_off!==2}"
                                 @click="clickon=2,common_off=2,tab_change()">注册资本<p
                                    v-show="common_off===2" class="tab-words-small">全国{{cqcat}}行业相关注册资本聚集分布</p></div>
                            <div v-if="showPanel==false"
                                 :class="{'tab_selected3':clickon===3,'tab_often' : common_off!==3}"
                                 @click="clickon=3,common_off=3,tab_change()">投资频率<p
                                    v-show="common_off===3" class="tab-words-small">全国{{cqcat}}行业相关投资频率聚集分布</p></div>
                            <el-button v-if="showPanel==true" icon="el-icon-caret-left" size="small"
                                       style="position: absolute;left: 20px;top:-5px;padding: 5px 10px;" title="返回"
                                       @click="resetMap()"></el-button>
                            <div v-if="showPanel==true" class="showPanel">
                                <div class="titlePanel" v-if="investmentMapCenter.city">{{investmentMapCenter.city}}
                                </div>
                                <div class="titlePanel" v-else>{{investmentMapCenter.province}}</div>
                                <el-divider></el-divider>
                                <div class="contentPanel" v-for="(com,index) in investmentMapGet10ByCityData" :key="index"> <span
                                        style="font-size:12px;font-weight:bold;">{{index+1}}</span> |
                                    <el-link :underline="false" @click="gotoDetal(com)" :title="com.name">
                                        <template v-if="com.name&&com.name.length<14">{{com.name}}</template>
                                        <template v-else>{{com.name.substr(0,14)+'...'}}</template>
                                    </el-link>
                                </div>

                                <el-divider class="divier-news"></el-divider>
                                <div class="contentMore my-click" @click="goToPage()"> 查看更多</div>


                            </div>

                        </div>
                        <div style="" id="map-wrap" class="map-wrap-back">
                            <div id="bmap-div"></div>
                        </div>

                    </div>
                </el-col>
            </el-row>

        </el-main>

    </div>
</template>
<script>
    /* eslint-disable no-console */
    /* eslint-disable no-unused-vars */
    /* eslint-disable no-unused-components */

    import ECharts from 'vue-echarts'
    import echarts from 'echarts'
    import 'echarts/lib/chart/line'
    import 'echarts/lib/component/tooltip'
    import 'echarts/lib/component/polar'
    import 'echarts/lib/component/title'
    import 'echarts/lib/component/legend'
    import {investmentMapData, investmentMapGet10ByCity,keyIndustryData} from "../api/api";

    let base64 = require('js-base64').Base64

    require('echarts/extension/bmap/bmap')

    export default {
        components: {
            // 'v-chart': ECharts
        },
        data() {
            return {
                loading: false,
                cqcat: '纺织服装',
                showPanel: false,
                bmapChart: {},
                initOptions: {},
                isLoaded: false,
                data: [],
                investmentMapCenter: {
                    province: '',
                    city: ''
                },
                investmentMapGet10ByCityData: [],
                clickon: 1,
                common_off: 1,
                middle_off: 1,
                middle_change: 0,
                hotmap1: {
                    title: {
                        text: '',
                        subtext: '',
                        sublink: 'www.baidu.com',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: function (param) {
                            return `${param.marker}${param.name} ${param.value[3].count}`
                        }
                    },
                    bmap: {
                        center: [104.114129, 37.550339],
                        zoom: 5.5,
                        roam: true,
                        mapStyle: {
                            styleJson: [{
                                'featureType': 'water',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#d1d1d1'
                                }
                            }, {
                                'featureType': 'land',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#f3f3f3'
                                }
                            }, {
                                'featureType': 'railway',
                                'elementType': 'all',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'highway',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#fdfdfd'
                                }
                            }, {
                                'featureType': 'highway',
                                'elementType': 'labels',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'arterial',
                                'elementType': 'geometry',
                                'stylers': {
                                    'color': '#fefefe'
                                }
                            }, {
                                'featureType': 'arterial',
                                'elementType': 'geometry.fill',
                                'stylers': {
                                    'color': '#fefefe'
                                }
                            }, {
                                'featureType': 'poi',
                                'elementType': 'all',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'green',
                                'elementType': 'all',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'subway',
                                'elementType': 'all',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'manmade',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#d1d1d1'
                                }
                            }, {
                                'featureType': 'local',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#d1d1d1'
                                }
                            }, {
                                'featureType': 'arterial',
                                'elementType': 'labels',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'boundary',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#fefefe'
                                }
                            }, {
                                'featureType': 'building',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#d1d1d1'
                                }
                            }, {
                                'featureType': 'label',
                                'elementType': 'labels.text.fill',
                                'stylers': {
                                    'color': '#999999'
                                }
                            }]
                        }
                    },
                    series: [
                        {
                            name: 'normal',
                            type: 'scatter',
                            coordinateSystem: 'bmap',
                            data: [],
                            symbolSize: function (val) {
                                return val[2] / 10;
                                 
                            },
                            label: {
                                normal: {
                                    formatter: '{b}',
                                    position: 'right',
                                    show: false
                                },
                                emphasis: {
                                    show: true
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: '#004098'
                                }
                            }
                        },
                        {
                            name: 'Top 5',
                            type: 'effectScatter',
                            coordinateSystem: 'bmap',
                            data: [],
                            symbolSize: function (val) {
                                return val[2] / 10;  
                            },
                            showEffectOn: 'render',
                            rippleEffect: {
                                brushType: 'stroke'
                            },
                            hoverAnimation: true,
                            label: {
                                normal: {
                                    formatter: '{b}',
                                    position: 'right',
                                    show: true
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: '#004098',
                                    shadowBlur: 10,
                                    shadowColor: '#333'
                                }
                            },
                            zlevel: 1
                        }
                    ]
                },
                hotmap2: {
                    title: {
                        text: '',
                        subtext: '',
                        sublink: '',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: function (param) {
                            return `${param.marker}${param.name} ${param.value[3].zczb}亿元`
                        }
                    },
                    bmap: {
                        center: [104.114129, 37.550339],
                        zoom: 5.5,
                        roam: true,
                        mapStyle: {
                            styleJson: [{
                                'featureType': 'water',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#d1d1d1'
                                }
                            }, {
                                'featureType': 'land',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#f3f3f3'
                                }
                            }, {
                                'featureType': 'railway',
                                'elementType': 'all',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'highway',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#fdfdfd'
                                }
                            }, {
                                'featureType': 'highway',
                                'elementType': 'labels',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'arterial',
                                'elementType': 'geometry',
                                'stylers': {
                                    'color': '#fefefe'
                                }
                            }, {
                                'featureType': 'arterial',
                                'elementType': 'geometry.fill',
                                'stylers': {
                                    'color': '#fefefe'
                                }
                            }, {
                                'featureType': 'poi',
                                'elementType': 'all',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'green',
                                'elementType': 'all',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'subway',
                                'elementType': 'all',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'manmade',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#d1d1d1'
                                }
                            }, {
                                'featureType': 'local',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#d1d1d1'
                                }
                            }, {
                                'featureType': 'arterial',
                                'elementType': 'labels',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'boundary',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#fefefe'
                                }
                            }, {
                                'featureType': 'building',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#d1d1d1'
                                }
                            }, {
                                'featureType': 'label',
                                'elementType': 'labels.text.fill',
                                'stylers': {
                                    'color': '#999999'
                                }
                            }]
                        }
                    },
                    series: [
                        {
                            name: 'normal',
                            type: 'scatter',
                            coordinateSystem: 'bmap',
                            data: [],
                            symbolSize: function (val) { 
                                 return val[2] / 10;
                            },
                            label: {
                                normal: {
                                    formatter: '{b}',
                                    position: 'right',
                                    show: false
                                },
                                emphasis: {
                                    show: true
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: '#249E2C'
                                }
                            }
                        },
                        {
                            name: 'Top 5',
                            type: 'effectScatter',
                            coordinateSystem: 'bmap',
                            data: [],
                            symbolSize: function (val) {
                                 return val[2] / 10;  
                            },
                            showEffectOn: 'render',
                            rippleEffect: {
                                brushType: 'stroke'
                            },
                            hoverAnimation: true,
                            label: {
                                normal: {
                                    formatter: '{b}',
                                    position: 'right',
                                    show: true
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: '#249E2C',
                                    shadowBlur: 10,
                                    shadowColor: '#333'
                                }
                            },
                            zlevel: 1
                        }
                    ]
                },
                hotmap3: {
                    title: {
                        text: '',
                        subtext: '',
                        sublink: '',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter: function (param) {
                            return `${param.marker}${param.name} ${param.value[3].tzpl}`
                        }
                    },
                    bmap: {
                        center: [104.114129, 37.550339],
                        zoom: 5.5,
                        roam: true,
                        mapStyle: {
                            styleJson: [{
                                'featureType': 'water',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#d1d1d1'
                                }
                            }, {
                                'featureType': 'land',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#f3f3f3'
                                }
                            }, {
                                'featureType': 'railway',
                                'elementType': 'all',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'highway',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#fdfdfd'
                                }
                            }, {
                                'featureType': 'highway',
                                'elementType': 'labels',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'arterial',
                                'elementType': 'geometry',
                                'stylers': {
                                    'color': '#fefefe'
                                }
                            }, {
                                'featureType': 'arterial',
                                'elementType': 'geometry.fill',
                                'stylers': {
                                    'color': '#fefefe'
                                }
                            }, {
                                'featureType': 'poi',
                                'elementType': 'all',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'green',
                                'elementType': 'all',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'subway',
                                'elementType': 'all',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'manmade',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#d1d1d1'
                                }
                            }, {
                                'featureType': 'local',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#d1d1d1'
                                }
                            }, {
                                'featureType': 'arterial',
                                'elementType': 'labels',
                                'stylers': {
                                    'visibility': 'off'
                                }
                            }, {
                                'featureType': 'boundary',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#fefefe'
                                }
                            }, {
                                'featureType': 'building',
                                'elementType': 'all',
                                'stylers': {
                                    'color': '#d1d1d1'
                                }
                            }, {
                                'featureType': 'label',
                                'elementType': 'labels.text.fill',
                                'stylers': {
                                    'color': '#999999'
                                }
                            }]
                        }
                    },
                    series: [
                        {
                            name: 'normal',
                            type: 'scatter',
                            coordinateSystem: 'bmap',
                            data: [],
                            symbolSize: function (val) {
                                return val[2] / 10;  
                            },
                            label: {
                                normal: {
                                    formatter: '{b}',
                                    position: 'right',
                                    show: false
                                },
                                emphasis: {
                                    show: true
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: '#FCA001'
                                }
                            }
                        },
                        {
                            name: 'Top 5',
                            type: 'effectScatter',
                            coordinateSystem: 'bmap',
                            data: [],
                            symbolSize: function (val) {
                               return val[2] / 10; 
                            },
                            showEffectOn: 'render',
                            rippleEffect: {
                                brushType: 'stroke'
                            },
                            hoverAnimation: true,
                            label: {
                                normal: {
                                    formatter: '{b}',
                                    position: 'right',
                                    show: true
                                }
                            },
                            itemStyle: {
                                normal: {
                                    color: '#FCA001',
                                    shadowBlur: 10,
                                    shadowColor: '#333'
                                }
                            },
                            zlevel: 1
                        }
                    ]
                },
                keyIndustryData:[]

            }
        },
        created() {
            this.initData()
        },
        methods: {
            getRandomNum(minNum, maxNum, excludeArr) {
                switch (arguments.length) {
                    case 1:
                        return parseInt(Math.random() * minNum + 1, 10)
                    case 2:
                        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
                    //或者 Math.floor(Math.random()*( maxNum - minNum + 1 ) + minNum );
                    case 3:
                        let ranNum = parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
                        if (excludeArr && excludeArr instanceof Array) {
                            if (excludeArr.indexOf(ranNum) != -1) {
                                ranNum = this.getRandomNum(minNum, maxNum, excludeArr)
                            }
                        }
                        return ranNum
                    default:
                        return -1;
                }
            },
            cqcatChange(cqname) {
                this.showPanel = false;
                this.clickon =1;
                this.common_off =1;
                this.$set(this['hotmap' + this.clickon].bmap, 'zoom', 5.5)
                this.$set(this['hotmap' + this.clickon].bmap, 'center', [104.114129, 37.550339])
                this.getInvestmentMapData(cqname)
            },
            gotoDetal(info) {
                let routeUrl = this.$router.resolve({
                    path: "/merchantsEngineDetail/" + info.id,
                    query: {
                        sp: new Number(info.sp)*0.5
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            initData() {
                let that = this 
                keyIndustryData({}).then(res => { 
                    if (res.success) {
                        this.keyIndustryData = res.result; 
                        this.getInvestmentMapData(res.result[0].industryName) 
                    }
                }).catch(e => {
                    console.log(e)
                })
            },
            getInvestmentMapData(cqname){
                this.cqcat = cqname; 
                investmentMapData({industryName:cqname}).then(data => { 
                    if (data.success) {
                        this.data = data.data;  
                        this.drawMap() 
                    }
                }).catch(e => {
                    console.log(e)
                })
            },
            filterAndConvertDrawMapData(top6){
                let cqcat = this.cqcat
                let resultArr =[];
                if( this.data.length <= 0 ){
                    return []
                } 
                let dataArr = this.data[0] 
                if (dataArr.length <= 0 && dataArr.children.length > 0) {
                    return []
                } 
                dataArr.children.forEach(o => {
                     if (o.children && o.children.length > 0) {
                           o.children.forEach(c => {
                               let value = 0;
                               value = filterValue(c,this.clickon) 
                                try {
                                    resultArr.push({
                                        name: c.cityName,
                                        value: [c.center.lng, c.center.lat, value, c]
                                    })
                                } catch (e) {
                                    // debugger
                                }
                           })
                     }else{
                        let value = 0
                        value = filterValue(o,this.clickon)  
                        resultArr.push({
                            name: o.provinceName,
                            value: [o.center.lng, o.center.lat, value, o]
                        })
                     }
                })
                function filterValue(data,clickon){
                    let num = 0;
                    switch (clickon) {
                        case 1:
                           // num = data.count / 50 * 3;
                            num = diffDegree(data.count)* 40
                            break;
                        case 2 :
                            num = diffDegree(data.zczb) * 40;
                            break;
                        default :
                            num =  diffDegree(data.tzpl * 10)* 40;
                    } 
                    return num
                }
                function diffDegree(value){
                    let num = 0
                    switch(true){
                        case value == 0:
                            num = 0;break;
                        case value < 50:
                            num = 1;break;
                        case value < 100 :
                            num = 2; break;
                        case value < 500 :
                            num = 3; break;
                        case value < 1000 :
                            num = 4; break;
                        case value < 3000 :
                            num = 5; break;
                        case value < 5000 :
                            num = 6; break;
                        case value < 8000 :
                            num = 7; break;
                        case value < 10000 :
                            num = 8; break; 
                        default :
                            num = 9
                    }
                    return num
                }
                let tmpArr = resultArr.sort(function (a, b) {
                    return b.value[2] - a.value[2];
                }) 
                if (top6) {
                    resultArr = resultArr.sort(function (a, b) {
                        return b.value[2] - a.value[2];
                    }).slice(0, 6)
                }
                return resultArr
            },
            goToPage() {
                let routeUrl = this.$router.resolve({
                    name: "MerchantsEngineResult",
                    query: {
                        searchType: 2,
                        kw: base64.encode(this.cqcat),
                        source: 'kid',
                        province: this.investmentMapCenter.province,
                        city: this.investmentMapCenter.city
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            drawMap() {
                // debugger
                var bmapChart = echarts.init(document.getElementById('bmap-div'));
                this.bmapChart = bmapChart;
                let options = this['hotmap' + this.clickon]
                options.series[0].data = this.filterAndConvertDrawMapData()
                
                options.series[1].data = this.filterAndConvertDrawMapData(true)
                
                bmapChart.setOption(options);
                let that = this;
                bmapChart.off('click')
                bmapChart.on("click", function (e) {
                    //console.log('ke');
                    //console.log(e);
                    that.showPanel = true;
                    that.investmentMapCenter.province = e.value[3].provinceName
                    that.investmentMapCenter.city = e.value[3].cityName
                    that.investmentMapGet10ByCityData = []  
					let cqcat = that.cqcat;
               	    //  if(that.cqcat =='生物医药' ){
                    //         cqcat = '生物工程'; 
                    // }
                      investmentMapGet10ByCity({city: e.name, q: cqcat}).then(res => {
                    
                        if (res.success) {
                            that.investmentMapGet10ByCityData = res.data
                            that.showPanel = true;
                            // that.hotmap1.bmap.zoom = 10;
                            that.$set(that['hotmap' + that.clickon].bmap, 'zoom', 10)
                            that.$set(that['hotmap' + that.clickon].bmap, 'center', [e.value[0], e.value[1]])
                            // that.hotmap1.bmap.center = [e.value[0], e.value[1]];
                            that.bmapChart.setOption(that['hotmap' + that.clickon], true);
                        }
                    }).catch(e => {
                        console.log(e)
                    })
                });


            },
            resetMap() {
                this.showPanel = false;
                this.$set(this['hotmap' + this.clickon].bmap, 'zoom', 5.5)
                this.$set(this['hotmap' + this.clickon].bmap, 'center', [104.114129, 37.550339])
                this.bmapChart.setOption(this['hotmap' + this.clickon], true);
            },
            tab_change() {
                this.drawMap(this['hotmap' + this.clickon]);
            }
        },
        // mounted() {
        //     setTimeout(() => {
        //         this.drawMap(this.hotmap1);
        //     }, 300);
        // },


    }


</script>


<style scoped>
    .tab-words-small {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 16px;
        margin-top: 5px;
    }

    .tab_selected3 {
        width: 260px;
        /*height: 86px;*/
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 14px;
        background: rgba(252, 160, 1, 1);
        margin-top: 8px;
        text-align: left;
        padding: 20px;
    }

    .tab_selected2 {
        width: 260px;
        /*height: 86px;*/
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 14px;
        background: rgba(36, 158, 44, 1);
        margin-top: 8px;
        text-align: left;
        padding: 20px;
    }

    .tab_selected1 {
        width: 260px;
        /*height: 86px;*/
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 14px;
        background: rgba(0, 64, 152, 1);
        margin-top: 8px;
        text-align: left;
        padding: 20px;
    }

    .tab_company {
        width: 56px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 64, 152, 1);
        line-height: 14px;
        width: 108px;
        border-radius: 2px;
        border: 1px solid rgba(0, 64, 152, 1);
        padding: 14px 0px;
        background: rgba(255, 255, 255, 1);
        /* margin-top:8px; */
        cursor: pointer;
    }

    .tab_money {
        width: 56px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(36, 158, 44, 1);
        line-height: 14px;
        width: 108px;
        border-radius: 2px;
        border: 1px solid rgba(36, 158, 44, 1);
        padding: 14px 0px;
        background: rgba(255, 255, 255, 1);
        margin-top: 8px;
        cursor: pointer;
    }

    .tab_often {
        width: 56px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(252, 160, 1, 1);
        line-height: 14px;
        width: 108px;
        border-radius: 2px;
        border: 1px solid rgba(252, 160, 1, 1);
        padding: 14px 0px;
        background: rgba(255, 255, 255, 1);
        margin-top: 8px;
        cursor: pointer;
    }

    .hot_map {
        width: 100%;
        /* height:800px; */
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.06);
        border-radius: 40px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        margin-top: 40px;
        position: relative;

    }

    .float-tab-div {
        z-index: 2;
        position: absolute;
        margin-left: 30px;
        margin-top: 50px;
    }

    .banner-pic-words {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        line-height: 20px;
        padding-top: 40px;
    }

    h1 {
        font-size: 44px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        line-height: 44px;
    }
    
    .bg-pic {
        width: 100%;
        /* height:100%; */
        background-image: url("../assets/pic/zhaoshang_background.png");
        background-size: cover;
    }
    .banner-bg{
        width: 304px;
        height: 100px;
        text-align: left;
        border-radius: 5px;
        margin-left: 5px;
    }
    .banner-bg-small{
        width: 200px;
        height: 100px;
        text-align: left;
        border-radius: 5px;
        cursor: pointer;
       
    }
 
    .banner-bg-div-margin {
        margin-top: 55px;
        margin-left: 10px;
        display: flex;
        position: absolute;
    }

    .banner-bg-span {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 35px;
        margin-left: 5px;
    }

    .banner-middle {
        margin-top: 80px;
    }

    .bm-view {
        width: 100%;
        height: 100%;
    }

    .anchorBL {
        display: none;
    }

    .BMap_cpyCtrl {
        display: none;
    }

    .map-wrap-back {
        /* border:1px solid; */
        border-radius: 25px;
        /* width:1143px; */
        height: 748px;
        margin: 0 auto;
        /* margin-top:20px; */
        overflow: hidden;
        padding: 30px;
    }

    #bmap-div {
        /* border-radius:25px; */
        /* overflow:hidden; */
        height: 100%;
        overflow: hidden;
        border-radius: 25px;
    }

    .showPanel {
        text-align: left;
        width: 240px;
        /* height:522px; */
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background-color: white;
        margin: 20px;
        padding: 18px;

    }

    .titlePanel {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
    }

    .contentPanel {
        /*width: 200px;*/
        /* height:16px; */
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.6);
        line-height: 16px;
        margin-top: 13px;
        padding: 5px 0px;
    }

    .contentPanel:hover {
        background: rgba(0, 64, 152, 0.05);
    }

    .contentMore {
        text-align: center;
        font-size: 15px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
    }

    .divier-news {
        margin: 15px 0 !important;
    }
</style>
