<template>
    <el-row>
        <el-row type="flex" class="top-cont" justify="center">
            <el-col :xs="20" :sm="18" :md="16" :lg="14" :xl="12">
                <el-main>
                    <div style="color:white;font-size: 26px;font-weight: bold;">产业链库</div>
                    <div style="color:white;font-size: 16px;font-weight: bold;">我的产业链及系统全部产业链。查漏补缺，以产业链节点找企业，换企业</div>
                    <el-card shadow="hover" class="me-search-el-card">
                        <div style="color:black;font-size: 16px;font-weight: bold;">关键词搜索</div>
                        <el-form :model="form.search" :rules="rules.search" ref="searchForm">
                            <el-form-item prop="kw">
                                <el-input v-model="form.search.kw" size="large" placeholder="请输入关键词搜索"
                                          class="me-search-input" disabled>
                                    <el-button slot="append"
                                               disabled
                                               class="me-search-el-button"
                                               icon="el-icon-search" @click="handleSearchSubmit('searchForm')">搜索
                                    </el-button>
                                </el-input>
                                <div slot="error" slot-scope="error" class="el-form-item__error"
                                     style="margin-top:-10px;">{{error.error}}
                                </div>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-main>
            </el-col>
        </el-row>
        <el-row type="flex" justify="center">
            <el-col :xs="22" :sm="20" :md="18" :lg="16" :xl="14">
                <el-card class="me-search-result-el-card" v-loading="loading.rootData">
                    <el-tabs v-model="actt">
                        <el-tab-pane :label="`我的产业链(${data.my.length})`" name="my">
                            <el-row v-if="data.my&&data.my.length>0">
                                <el-col   :span="8" v-for="(node,index) in data.my"
                                        :key="index">
                                    <el-card :body-style="{ padding: '0px' }" style="margin: 10px;cursor: pointer;"
                                             @click.native="gotoDetal(node)">
                                         <div style="  padding-bottom: 56%;width: 100%;position: relative;">
                                                  <img :src="node.industryImage"  class="image ">
                                             </div> 
                                        <div style="padding: 14px;font-weight: bold;font-size: 19px;text-align: center;    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;">
                                            <span>{{node.industaryName}}</span>
                                        </div>
                                    </el-card>
                                </el-col>
                            </el-row>
                            <el-row v-else>
                                <el-col :span="24">
                                    暂无可用产业链，请联系园区管理员
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane :label="`全部产业链(${data.all.length})`" name="all">
                            <el-row>
                                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" :xxl="6" v-for="(node,index) in data.all" :key="index">
                                    <el-card :body-style="{ padding: '0px' }" style="margin: 10px;cursor: pointer;"
                                             @click.native="gotoDetal(node)">
                                             <div style="  padding-bottom: 56%;width: 100%;position: relative;">
                                                  <img :src="node.industryImage"  class="image ">
                                             </div> 
                                        <div style="padding: 14px;font-weight: bold;font-size: 19px;text-align: center;    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;">
                                            <span>{{node.industaryName}}</span>
                                        </div>
                                    </el-card>
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </el-card>
            </el-col>
        </el-row>
    </el-row>
</template>
<script>
    import {findMyIndustryChain,allIndustryChain} from "../api/api";

    let base64 = require('js-base64').Base64

    export default {
        components: {},
        data() {
            return {
                actt: 'my',
                loading: {
                    rootData: false
                },
                form: {
                    search: {
                        kw: ''
                    }
                },
                rules: {
                    search: {
                        kw: [{required: true, message: '请输入搜索关键词!', trigger: 'blur'}]
                    }
                },
                data: {
                    my: [],
                    all: []
                },
                pagination: {
                    background: true,
                    layout: "sizes, prev, pager, next",
                    pageSizes: [10, 20, 50, 100],
                    pageSize: 10,
                    pagerCount: 5,
                    total: 101
                }
            }
        },
        created() {
            if (this.$route.query.actt) {
                this.actt = this.$route.query.actt
            }
            this.loadDefaultData()
        },
        methods: {
            loadDefaultData() {
                let that = this;
                that.loading.rootData = true;
                allIndustryChain().then(res => {
                    that.loading.rootData = false
                    if (res.success && res.result && res.result.length > 0) {
                        that.loading.rootData = false
                        that.data.all = res.result
                        console.log( that.data.all)
                    }
                }).catch(() => {
                    this.loading.rootData = false
                })
                that.loading.rootData = true
                findMyIndustryChain().then(res => {
                    that.loading.rootData = false
                    if (res.success && res.result && res.result.length > 0) { 
                        that.data.my = res.result
                    }
                }).catch(() => {
                    this.loading.rootData = false
                })
            },
            gotoDetal(node) {
                let routeUrl = this.$router.resolve({
                    path: "/industryChainMapDetail",
                    query: {
                        tn: base64.encode(node.industaryName)
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            handlePaginationSizeChange() {
                console.log(this.pagination)
            },
            handlePaginationCurrentChange() {
                console.log(this.pagination)
            },
            handleSearchSubmit(formName) {
                let that = this
                that.$refs[formName].validate((valid) => {
                    if (valid) {
                        // this.$router.push({path: '/merchantsEngineResult', query: this.form.search})
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    }

</script>


<style scoped>
    * {
        text-align: initial;
    }

    .time {
        font-size: 13px;
        color: #999;
    }

    .bottom {
        margin-top: 13px;
        line-height: 12px;
    }

    .button {
        padding: 0;
        float: right;
    }

    .image {
        /* width: 100%;
        display: block;
        /* width: 316px; 
        height: 178px; */
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;  
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .top-cont {
        height: 300px;
        background-image: url('../assets/pic/chanyelianku_banner.png');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .me-search-el-card {
        margin: 30px 0px;
    }

    .me-search-input {
        margin: 15px 0px;
    }

    .me-search-el-button {
        background-color: #409EFF !important;
        color: #fff !important;
        padding: 13px 20px !important;
        border-radius: 0px !important;
    }

    .me-search-result-el-card {
        margin: 20px 0px;
    }

    .me-search-result-item-el-card {
        border: 0px;
    }

    .me-search-result-item-el-card:hover {
        /*border: 1px solid rgba(0, 64, 152, 0.0862745098039216);*/
        background-color: rgba(0, 64, 152, 0.1);
    }

    .me-search-result-el-pagination {

    }

</style>
