<template>
   <el-main style="background:white;width:100%;"    >
        <el-row type="flex" class="row-bg" justify="center" style="width:100%;">
            <el-col :span="16" class="col-bg">
                <section class="rp_header"  >
                    <div class="bg"></div>
                    <div class="line"></div>
                </section>
                <section class="company_info">
                    <el-image
                        style="width: 100px; height: 100px;float: right;"
                        :src="companyDetial.logo"
                    ></el-image>
                    <p  class="name" v-text="companyDetial.companyName"></p>  
                    <p  class="info" style="padding-top: 24px;">
                        <span class="fl">企业地址</span>
                        <span class="content" v-text="companyDetial.regLocation"></span>
                    </p>   
                    <el-divider v-if="isShowProduct"></el-divider>
                     <p   v-if="isShowProduct" class="info"  >
                        <span class="fl">企业产品</span>
                        <span class="content" v-text="product"></span>
                    </p> 
                    <el-divider></el-divider> 
                     <p  class="info" style="padding-bottom:24px;">
                        <span class="fl">经营范围</span>
                        <span class="content"  v-text="companyDetial.businessScope"></span>
                    </p>  
                </section>
                <div class="line" ></div>
                <section class="part" style="padding-top: 36px;">
                    <div class="top">
                        <img src="@/assets/rp_left.png" height="20px"   />
                        <span class="title">上下游结构</span>
                        <img src="@/assets/rp_right.png" style="width: calc(100% - 140px);" />
                    </div>
                    <mhmap />
                </section>
                <section class="part" style="padding-top: 36px;">
                    <div class="top">
                        <img src="@/assets/rp_left.png" height="20px"   />
                        <span class="title">行业现状</span>
                        <img src="@/assets/rp_right.png" style="width: calc(100% - 140px);" />
                    </div>
                     <div class="partinfo" v-if="policy.industryContent" v-text="policy.industryContent"></div>
                    <div v-else class="empty">暂无 </div>
                </section>
                <section class="part">
                    <div class="top">
                        <img src="@/assets/rp_left.png" height="20px"   />
                        <span class="title">学校</span>
                        <img src="@/assets/rp_right.png" style="width: calc(100% - 140px);" />
                    </div>
                    <div style="padding: 20px;padding-left: 36px;">
                        <div v-for="(univeristy,uindex) in policy.univeristys" :key="univeristy.id" style="display: inline-block;width: 6vw;text-align: center;vertical-align: top;" >
                            <el-popover 
                                width="800"
                                trigger="click" 
                                popper-class="snow"
                                :placement="handlePop(uindex)"
                                >
                                <img :src="univeristy.logo"   style="width: 4vw;padding-left:8px;">
                                <p v-text="univeristy.name" style="font-size:16px;font-weight:600;padding:12px;padding-bottom:0;"></p> 
                                <p v-text="univeristy.introduction" style=" font-size: 14px;padding: 12px;line-height: 1.5;"></p>  
                                <div  slot="reference">
                                    <img :src="univeristy.logo"   style="width: 4vw;">
                                    <p v-text="univeristy.name" style="font-weight:600;padding-top:4px;"></p>
                                </div>
                                <p v-if="univeristy.importSubject" style="font-size:16px;font-weight:600;padding:12px;padding-bottom:0;">重点专业</p>
                                <p v-if="univeristy.importSubject" v-text="univeristy.importSubject" style=" font-size: 14px;padding: 12px;line-height: 1.5;"></p>  
                            </el-popover>

                        </div>
                    </div>
                </section>
                <section class="part">
                    <div class="top">
                        <img src="@/assets/rp_left.png" height="20px"   />
                        <span class="title">税收政策</span>
                        <img src="@/assets/rp_right.png" style="width: calc(100% - 140px);" />
                    </div>
                    <div class="partinfo" v-if="policy.taxPolicy" v-text="policy.taxPolicy"></div>
                    <div v-else class="empty">暂无 </div>
                </section>
                <section class="part">
                    <div class="top">
                        <img src="@/assets/rp_left.png" height="20px"   />
                        <span class="title">财政奖励</span>
                        <img src="@/assets/rp_right.png" style="width: calc(100% - 140px);" />
                    </div>
                    <div class="partinfo" v-if="policy.finalaAward" v-text="policy.finalaAward"></div>
                    <div v-else class="empty">暂无 </div>
                </section>
                <section class="part">
                    <div class="top">
                        <img src="@/assets/rp_left.png" height="20px"   />
                        <span class="title">设备补贴</span>
                        <img src="@/assets/rp_right.png" style="width: calc(100% - 140px);" />
                    </div>
                    <div class="partinfo" v-if="policy.equipSubsidy" v-text="policy.equipSubsidy"></div>
                    <div v-else class="empty">暂无 </div>
                </section>
                 <section class="part">
                    <div class="top">
                        <img src="@/assets/rp_left.png" height="20px"   />
                        <span class="title">厂房补贴</span>
                        <img src="@/assets/rp_right.png" style="width: calc(100% - 140px);" />
                    </div>
                    <div class="partinfo" v-if="policy.plantSubsidy" v-text="policy.plantSubsidy"></div>
                    <div v-else class="empty">暂无 </div>
                </section>
                 <section class="part">
                    <div class="top">
                        <img src="@/assets/rp_left.png" height="20px"   />
                        <span class="title">贷款贴息</span>
                        <img src="@/assets/rp_right.png" style="width: calc(100% - 140px);" />
                    </div>
                    <div class="partinfo" v-if="policy.subInterest" v-text="policy.subInterest"></div>
                    <div v-else class="empty">暂无 </div>
                </section>
                 <section class="part">
                    <div class="top">
                        <img src="@/assets/rp_left.png" height="20px"   />
                        <span class="title">运费补贴</span>
                        <img src="@/assets/rp_right.png" style="width: calc(100% - 140px);" />
                    </div>
                    <div class="partinfo" v-if="policy.primage" v-text="policy.primage"></div>
                    <div v-else class="empty">暂无 </div>
                </section>
                 <section class="part">
                    <div class="top">
                        <img src="@/assets/rp_left.png" height="20px"   />
                        <div class="title">
                            <p>用工补贴</p> 
                        </div>
                        <img src="@/assets/rp_right.png" style="width: calc(100% - 140px);" />
                    </div>
                    <div class="partinfo" v-if="policy.employSubsidy" v-text="policy.employSubsidy"></div>
                    <div v-else class="empty">暂无 </div>
                </section>
                 <section class="part">
                    <div class="top">
                        <img src="@/assets/rp_left.png" height="20px"   />
                        <span class="title"  style="width:160px;">创新创业上市奖励</span>
                        <img src="@/assets/rp_right.png" style="width: calc(100% - 200px);" />
                    </div>
                    <div class="partinfo" style="padding-bottom: 60px;"  v-if="policy.listingReward" v-text="policy.listingReward"> </div>
                    <div v-else class="empty">暂无 </div>
                </section>
            </el-col>
        </el-row>
   </el-main>
</template>

<script>
import {getHelpReport} from "../api/api";
import Mhmap from "@/components/mhMap"
export default {
  name: "",
  components: {
      Mhmap
  },
  data() {
    return {
        companyDetial:{
            "subAppbkinfo":[],
            "companyName":"",
            "regLocation":"",
            "logo":"",
            "businessScope":""
        },
        industryCode:'',
        policy:{
            "employSubsidy": "",
            "equipSubsidy": "",
            "finalaAward": "",
            "industryContent": "",
            "listingReward": "",
            "pid": 0,
            "plantSubsidy": "",
            "primage": "",
            "subInterest": "",
            "taxPolicy": "",
            "univeristys": [
                {
                    "city": "",
                    "cityId": "",
                    "firstLevelIndustry": "",
                    "fourthLevelIndustry": "",
                    "id": 0,
                    "introduction": "",
                    "logo": "",
                    "name": "",
                    "province": "",
                    "provinceId": "",
                    "secondLevelIndustry": "",
                    "thirdLevelIndustry": ""
                }
            ]
        }
    };
  },
  created() {
      let companyDetial = sessionStorage.getItem("companyDetial")
      console.log(JSON.parse(companyDetial))
      Object.assign(this.companyDetial, JSON.parse(companyDetial))  
      this.industryCode = this.$route.params.industryCode;
      this.initData()
  },
  mounted() {},
  methods: {
      initData(){
          let param ={
              industryCode:this.industryCode
          }
          getHelpReport(param).then(res =>{ 
               if (res.success && res.result) {
                    Object.assign(this.policy, res.result) 
                }
             
          })
      },
      handlePop(num){
          if(num < 3){
              return 'bottom-start'
          }
          if(num > 6){
              return 'bottom-end'
          }
      }
  },
  computed: {
      isShowProduct:function(){
          if(this.companyDetial.subAppbkinfo && this.companyDetial.subAppbkinfo.length >0){
              return true
          }else{
              return false
          }
      },
      product:function(){
          if(this.isShowProduct){
              let productStr =  ''
              this.companyDetial.subAppbkinfo.map(item =>{
                  productStr += item.name+ "、";
              })
             return  productStr.substring(0,productStr.length-1)
          }else{
              return null
          }
      }
  }
};
</script>

<style lang="scss" scoped>

.col-bg{
    background:white;
    & .line{
        height:10px;
        background-image: url("../assets/rp_line_1.png");
        background-size: 100% auto; 
        background-repeat: no-repeat;
        margin-left: 36px;
        margin-right: 36px;
    }
    .rp_header{
        width:100%;
        height:210px; 
        & .bg{
            height:200px;
            background-image: url("../assets/rp_header.png");
            background-size: 100% auto; 
            background-repeat: no-repeat;
        }
    
    }
    .company_info{
        padding-left: 36px;
        padding-right: 36px;
        .name{
            color:#C94332;
            font-size: 24px; 
            font-weight: normal;
            font-weight: bolder;
            padding-top: 24px;
            
        }
        .info{ 
            font-size: 12px;
            font-weight: 600;
            
            & .content{ 
                font-weight: normal; 
                padding-left: 80px;
                display: block;
            }
        }
    }

    .part{ 
        //padding-top: 36px;
        font-size: 14px; 
        & .top{
            height: 40px;

            & .title{
                font-size: 16px; 
                font-weight: 600;  
                text-align: center;
                width: 100px; 
                display: inline-block;
            }
            & img{
                display: inline-block;
            }
        }
        & .empty{
            color:#32363B;    
            font-size: 12px;
            text-align: center;
            font-weight: 600;
            height: 60px;
            line-height: 60px;
        }
        & .partinfo{
            font-size: 14px;
            padding: 10px 40px 20px 40px;
            line-height: 1.5;
            //box-sizing: border-box;
            //text-indent:2em;
           white-space:pre-wrap;
            white-space:-moz-pre-wrap;
            white-space:-o-pre-wrap;
            word-wrap:break-word;
        }
    }
}

.fl{
    float: left;
}
</style>
