<template>
  <div class="main">
    <!-- banner pic -->
    <div class='banner-pic'>
      <el-main style="background:transparent">
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="24">
            <h1>招商小管家</h1>
            <div class="banner-words">针对政府和园区提供专业化、信息化、数字化的招商综合解决方案。通过招商推广、招商管理、商机收集，运营管理等人工智能招商工具，系统性解决招商难、招商杂等系列问题，
              真正实现“大数据+人工智能”精准招商。</div>
            <div class="erwei_pic">
              <img src="../assets/xiaoguanjia.png" alt="">
              <div>扫码联系我哦</div>
            </div>
            <div class="banner-small-pic">
              <div class="small-block">
                <img src="../assets/pic/zs_banner_1.png" alt="">
                <p>产业链服务</p>
              </div>
              <div class="small-block">
                <img src="../assets/pic/zs_banner_2.png" alt="">
                <p>企业沟通服务</p>
              </div>
              <div class="small-block">
                <img src="../assets/pic/zs_banner_3.png" alt="">
                <p>项目推荐服务</p>
              </div>
              <div class="small-block">
                <img src="../assets/pic/zs_banner_4.png" alt="">
                <p>系统定制服务</p>
              </div>
              <div class="small-block">
                <img src="../assets/pic/zs_banner_5.png" alt="">
                <p>其他任何有关问题</p>
              </div>
            </div>
          </el-col>
        </el-row>

      </el-main>
    </div>
    <!-- down banner -->
    <el-main>
      <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="16">

          <div class="down-banner">
            <el-row type="flex" class="row-bg">
              <el-col :span="4" class="down-banner-float">
                <div class='buttom-line'></div>
                <div class="grid-content buttom-section">
                  <div>
                    <img src="../assets/pic/zs_buttom_1.png" style="width:32px;height:32px;" alt="">
                    <img   src="../assets/pic/ar.png" style="margin-top:5px;width:22px;height:22px; float:right" alt="">

                    <!-- <span style="width:14px;height:12px;padding-left:136px;">→</span> -->
                  </div>
                  <div>产业链数据</div>
                  <div>多元项目数据</div>
                  <div>行业数据</div>
                  <div>企业舆情数据</div>
                  <div>企业工商数据</div>
                  <div>...</div>
                </div>
              </el-col>
              <el-col :span="4" class="down-banner-float">
                <div class='buttom-line'></div>
                <div class="grid-content buttom-section">
                  <div>
                    <img src="../assets/pic/zs_buttom_2.png" style="width:32px;height:32px;" alt="">
                    <img src="../assets/pic/ar.png" style="margin-top:5px;width:22px;height:22px; float:right" alt="">

                    <!-- <span style="width:14px;height:12px;padding-left:136px;">→</span> -->
                  </div>
                  <div>大数据治理技术</div>
                  <div>NLP自然语言处理技术</div>
                  <div>模型匹配算法</div>
                  <div>ML机器学习</div>
                  <div>...</div>
                </div>
              </el-col>
              <el-col :span="4" class="down-banner-float">
                <div class='buttom-line'></div>
                <div class="grid-content buttom-section">
                  <div>
                    <img src="../assets/pic/zs_buttom_3.png" style="width:32px;height:32px;" alt="">
                    <img src="../assets/pic/ar.png" style="margin-top:5px;width:22px;height:22px; float:right" alt="">
                    <!-- <span style="width:14px;height:12px;padding-left:136px;">→</span> -->
                  </div>
                  <div>产业招商地图</div>
                  <div>大数据招商引擎</div>
                  <div>产业链库</div>
                  <div>企业档案</div>
                </div>
              </el-col>
              <el-col :span="4" class="down-banner-float">
                <div class='buttom-line'></div>
                <div class="grid-content buttom-section">
                  <div>
                    <img src="../assets/pic/zs_buttom_4.png" style="width:32px;height:32px;" alt="">
                    <img src="../assets/pic/ar.png" style="margin-top:5px;width:22px;height:22px; float:right" alt="">

                    <!-- <span style="width:14px;height:12px;padding-left:136px;">→</span> -->
                  </div>
                  <div>企业筛选</div>
                  <div>企业查找</div>
                  <div>企业监控</div>
                  <div>企业调研</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="buttom-words">
                  <div class="buttom-words-title">精准招商</div>
                  <div class="buttom-words-small">
                    通过运用大数据采集与处理技术，各种企业数据、舆情数据的清洗、整合，为园区用户呈现一幅多维度的企业画像。帮助园区用户更快速、更高效地了解、联系企业。同时，系统结合产业链、产业招商地图、项目库等功能，使得园区用户能够快速的缩小招商企业的范围，提高招商效率。数据结合功能，从而帮助用户实现更快速、更精准的招商。
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>

        </el-col>
      </el-row>

    </el-main>
  </div>
</template>
<script>
export default {
  name: "guanjia",

  data() {
    return {
      show: false,
      isAuth: true,
      token: "",
      centerDialogVisible: false,
      squareUrl:
        "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      profile: ""
    };
  },
  created() {},
  components: {},
  methods: {}
};
</script>

<style scoped>
.buttom-words{
  padding:40px;
  text-align:left;
  width:311px;
  /* height:392px; */
  background:rgba(0,64,152,1);
  border-radius:4px;
}
.buttom-words-small{
  /* width:239px;
  height:252px; */
  font-size:14px;
  font-family:PingFangSC-Medium,PingFang SC;
  font-weight:500;
  color:rgba(255,255,255,1);
  line-height:28px;
  margin-top:24px;
}
.buttom-words-title{
  /* width:96px;
  height:24px; */
  font-size:24px;
  font-family:PingFangSC-Medium,PingFang SC;
  font-weight:500;
  color:rgba(255,255,255,1);
  line-height:24px;
  margin-top:20px;
}
.buttom-line{
  width:2px;
  height:392px;
  background:linear-gradient(180deg,rgba(0,64,152,1) 0%,rgba(255,255,255,1) 100%);
}
.down-banner-float{
  display:flex;
}
.banner-words{
  width:45%;
  /* height:84px; */
  font-size:20px;
  font-family:PingFangSC-Medium,PingFang SC;
  font-weight:500;
  color:rgba(255,255,255,1);
  line-height:28px;
  text-align:left;
  margin-left:30%;
  margin-top:5%;
}
.banner-pic{
  width:100%;
  height:1042px;
  background-image: url("../assets/pic/guanjia_banner.png");
  background-size: auto 1042px;
  text-align:center;
  /* margin-left:-400px;
  margin-top:-20px; */
}
h1{
  /* width:220px; */
  /* height:44px; */
  font-size:44px;
  font-family:PingFangSC-Semibold,PingFang SC;
  font-weight:600;
  color:rgba(255,255,255,1);
  line-height:44px;
  padding-top:100px;
}
.main{
  text-align:center;
}
.erwei_pic img{
  width:302px;
  height:302px;
  padding:50px;
}
.erwei_pic div{
font-size:20px;
font-family:PingFangSC-Medium,PingFang SC;
font-weight:500;
color:rgba(255,255,255,1);
line-height:28px;
}
.banner-small-pic div{
  display:inline-block;
  padding:65px;
  margin-top:40px;
}
.small-block p{
  /* width:100px;
  height:28px; */
  font-size:20px;
  font-family:PingFangSC-Medium,PingFang SC;
  font-weight:500;
  color:rgba(255,255,255,1);
  line-height:28px;
  padding-top:32px;
}
.small-block img{
  width:64px;
  height:64px;
}
.down-banner{
  margin-top:50px;
}
.buttom-section div{

  font-size:16px;
  font-family:PingFangSC-Medium,PingFang SC;
  font-weight:500;
  color:rgba(0,64,152,1);
  line-height:16px;
  text-align:left;
  padding:18px;
  width:100%;
}
.buttom-section{
  padding-left:16px;
    width:100%;

}
</style>