<template>
<el-container>
  <el-aside width="600px">
    <div id="main" style="width: 600px;height:650px;"></div>
  </el-aside>
  <el-main>
    <section class="up card"  v-if="upData.length >0">  
        <el-card shadow="always"> 
          <p class="title">上游企业</p>
          <el-row  v-if="groupBy(upData,'category') && groupBy(upData,'category').length>0" :gutter="20">
              <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="8"  style="padding-top:20px;min-height: 96px;"
              v-for="(sec,secIndex) in groupBy(upData,'category')" :key="secIndex">
               
                  <p v-text="sec[0].category" class="category"></p> 
                  <div v-if="sec && sec.length>0"
                      v-for="company in sec" :key="company.companyId">
                       <el-tooltip class="item" effect="dark" :content="company.name" placement="bottom" :disabled="company.name.length<14">
                            <p v-text="company.name" class="company" @click="gotoDetal(company)"></p> 
                        </el-tooltip>
                       
                  </div>
              </el-col>
          </el-row> 
        </el-card>
    </section>
    <section class="down card" v-if="downData.length >0">  
        <el-card shadow="always"> 
             <p class="title">下游企业</p>
            <el-row  v-if="groupBy(downData,'category') && groupBy(downData,'category').length>0" :gutter="20">
                <el-col  :xs="24" :sm="12" :md="12" :lg="8" :xl="8"  style="padding-top:20px;min-height: 96px;"
                v-for="(sec,secIndex) in groupBy(downData,'category')" :key="secIndex">
                
                    <p v-text="sec[0].category" class="category"></p> 
                    <div v-if="sec && sec.length>0"
                        v-for="company in sec" :key="company.companyId">
                        <el-tooltip class="item" effect="dark" :content="company.name" placement="bottom" :disabled="company.name.length < 15">
                                <p v-text="company.name" class="company" @click="gotoDetal(company)"></p> 
                            </el-tooltip>
                        
                    </div>
                </el-col>
            </el-row> 
        </el-card>
    </section> 
  </el-main>
</el-container>
 
 
 
 
  
</template>
<script>
 
import {jzData,markPointData,mhArea} from '@/util/mhq_jz'
import echarts from 'echarts'
export default {
  name: "guanjia",
  computed: {
      upData :function(){
        let list =[]
        let that =this;
        if(this.isShow || this.markPointCurrent.length >0){
          this.downData =[]
           this.markPointCurrent.map(item =>{
                if(item.isUp){
                   list.push(item)
                }else{
                    that.downData.push(item)
                }
           })
        } 
        return list
      }
  },
  data() {
    return { 
      isShow:false,
      markPointCurrent:[],
      downData:[],
      jzData:jzData,
      markPointData:markPointData, 
      industryData:[],
      options:{
          title: {
              show:false,
          },
          tooltip: {
            show:false,
          },
          visualMap: {
              left: 'right',
              min: 2,
              max: 13,
              inRange: {
                  color: ['#ffffff', '#B2B2B2']
              },
              text: ['High', 'Low'],           // 文本，默认为数值文本
              calculable: true,
              show:false
          },
          toolbox: {
              show: false,
          },
          series: [
              {
                  name: '闵行街镇',
                  type: 'map',
                  roam: true,
                  map: 'mhArea',
                  zoom:1.2,
                  scaleLimit:{
                      min:1,
                      max:10,
                  },
                  emphasis: {
                      label: {
                          show: true,
                      },
                      itemStyle:{
                        areaColor:'#94aac6'
                      }
                  },
                  // 文本位置修正
                  textFixed: {
                      Alaska: [20, -20]
                  },
                  data:[],
                  markPoint : {
                      //symbol:'pin',
                    symbol:'image://http://47.103.143.238/map/red.png',
                    symbolSize : function (v){
                        return  20
                    },
                    effect : {
                        show: true,
                        shadowBlur : 0
                    },
                    label:{show:false,
                     formatter: '{b}',
                    },
                    itemStyle:{
                        normal:{
                            label:{show:false,
                             formatter: '{b}',
                            }
                        }
                    },
                    data : []
                  } 
              },
          ]
      }
    };
  }, 
  methods: {
    groupBy (array, name) { 
      const groups = {}
      array.forEach(function (o) {
        const group = JSON.stringify(o[name])
        groups[group] = groups[group] || []
        groups[group].push(o)
      })
      return Object.keys(groups).map(function (group) {
        return groups[group]
      })
    },
    gotoDetal(info) { 
        let routeUrl = this.$router.resolve({
            path: "/merchantsEngineDetail/" + info.companyId,
            query: {
                sp: new Number(info.sp)
            }
        });
        window.open(routeUrl.href, '_blank');
    }
  },
  mounted(){
      let list = []
      this.markPointData.map(item =>{
         list =list.concat(item.value)
      })  
       var myChart = echarts.init(document.getElementById('main')); 
       echarts.registerMap('mhArea', mhArea);
       this.options.series[0].data = JSON.parse(JSON.stringify(jzData));
       this.options.series[0].markPoint.data = list
       myChart.setOption(this.options, true)
       let that = this;
       myChart.on('click', function (params) { 
        //console.log(params)
        that.isShow =false;
        that.options.series[0].data = JSON.parse(JSON.stringify(jzData));
        that.options.series[0].data[params.dataIndex].selected = true;
        that.markPointCurrent = that.options.series[0].markPoint.data = that.markPointData[params.dataIndex].value; 
         
        myChart.setOption(that.options, true)
        that.isShow =true;    
      });
      
  }
};
</script>

<style lang="scss" scoped >
.card{
    margin-top: 40px;
    /deep/ .title{
        font-size:16px;
        font-weight: 600; 
        color:#e74916;
    }
} 
.category{
  font-size:14px;
  font-weight: 600; 
  padding-bottom: 2px;
}
.company{
  font-size: 12px; 
  padding-top: 6px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>