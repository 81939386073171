import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        company:'',
        username:'',
    },
    mutations: {
        SET_INFO: (state, result) => {
            state.company = result.company;
            state.username = result.userInfo.realname; 
            let company ={
                id:result.company.id
            }
            sessionStorage.setItem("company",JSON.stringify(company));
            sessionStorage.setItem("username",result.userInfo.realname);
        },
    },
    actions: {
        setInfo:function({ commit }, result){
            commit('SET_INFO',result)
        },
    
    },
    getters: {
        isTMSK: state => {
            let company = sessionStorage.getItem('company')
            if(company){
                state.company = JSON.parse(company)
            }
            return state.company.id == "657362944b4a4782925e6d8b26beb67f"
        },
        userName: state => {
            state.username = sessionStorage.getItem('username');
            return  state.username
        },
    },
  })