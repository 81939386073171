import Cookies from 'js-cookie'

const TokenKey = 'x-access-token'
//8小时
const expires = 8 * 60 * 60 * 1000

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token, {expires: new Date(new Date().getTime() + expires)})
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}
