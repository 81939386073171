<template>
    <el-row>
        <el-row type="flex" class="top-cont" justify="center">
            <el-col :xs="20" :sm="18" :md="16" :lg="14" :xl="12">
                <el-main>
                    <div style="color:white;font-size: 26px;font-weight: bold;">大数据招商引擎</div>
                    <div style="color:white;font-size: 16px;font-weight: bold;">多条件筛选符合条件的企业</div>
                    <el-card shadow="hover" class="me-search-el-card">
                        <div style="color:black;font-size: 16px;font-weight: bold;">关键词搜索</div>
                        <el-form :model="form.search" :rules="rules.search" ref="searchForm">
                            <el-form-item prop="kw">
                                <el-input v-model="form.search.kw" size="large" placeholder="请输入关键词搜索"
                                          class="me-search-input"
                                          @keyup.enter.native="handleSearchSubmit('searchForm')">
                                    <el-select v-model="form.search.searchType" slot="prepend" style="width: 100px">
                                        <el-option label="搜企业" :value="1"></el-option>
                                        <el-option label="搜产品" :value="2"></el-option>
                                    </el-select>
                                    <el-button slot="append"
                                               class="me-search-el-button"
                                               icon="el-icon-search" @click="handleSearchSubmit('searchForm')">搜索
                                    </el-button>
                                </el-input>
                                <div slot="error" slot-scope="error" class="el-form-item__error"
                                     style="margin-top:-10px;margin-left: 100px;">{{error.error}}
                                </div>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-main>
            </el-col>
        </el-row>
        <el-row type="flex" justify="center">
            <el-col :xs="22" :sm="20" :md="18" :lg="16" :xl="14">
                <el-card class="me-search-result-el-card" v-loading="loading.recommend">
                    <div></div>
                    <el-divider/>
                    <el-row class="box">
                        <el-col :xs="24" :lg="12" v-for="com in list.recommend" style="padding: 5px;" :key="com.id">
                            <el-card shadow="never" class="me-search-result-item-el-card">
                                <div style="float:left;width: 50px">
                                    <el-avatar shape="square" :size="50"
                                               :src="com.logo">
                                        <img :src="require('@/assets/pic/defalut_building.png')"/>
                                    </el-avatar>
                                </div>
                                <div style="width: calc(100% - 65px);margin-left: 65px;">

                                    <el-link :underline="false" @click="gotoDetal(com)"
                                             style="font-weight: bold;font-size: 16px;margin-bottom: 3px;">{{com.name}}
                                    </el-link>
                                    <el-tooltip>
                                        <div slot="content" style="max-width: 300px;">{{com.regLocation}}</div>
                                        <div style="color: #aaa;font-size: 13px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                                            {{com.regLocation}}
                                        </div>
                                    </el-tooltip>
                                    <div style="font-size: 14px;margin-top: 10px;">
                                        <span
                                                style="font-weight: bold;float: left;">主营产品&nbsp;&nbsp;</span>
                                        <el-tooltip
                                                v-if="com.bigData&&com.bigData.subBaseinfo&&com.bigData.subBaseinfo.length>0">
                                            <div slot="content" style="max-width: 300px;">
                                                {{com.bigData.subBaseinfo[0].businessScope}}
                                            </div>
                                            <span style="display:block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{com.bigData.subBaseinfo[0].businessScope}}</span>
                                        </el-tooltip>
                                    </div>
                                    <div style="font-size: 14px;margin-top: 5px;"><span 
                                            style="font-weight: bold;">匹配度 </span><span
                                            style="color: orange;">{{(new Number(com.sp)*100*0.5).toFixed(0)+'%'}}</span>
                                    </div>
                                    <div style="font-size: 14px;margin-top:15px;">
                                        <el-button v-if="com.cat" type="info" size="mini"
                                                   style="cursor:default;margin:0 10px 5px 0;" plain
                                                   round disabled>
                                            {{com.cat}}
                                        </el-button>
                                        <el-button v-if="com.cqcat" type="info" size="mini"
                                                   style="cursor:default;margin:0 10px 5px 0;"
                                                   plain round disabled>
                                            {{com.cqcat}}
                                        </el-button>
                                        <el-button v-if="com.crole" type="info" size="mini"
                                                   style="cursor:default;margin:0 10px 5px 0;"
                                                   plain round disabled>
                                            {{com.crole}}
                                        </el-button>
                                        <el-button v-if="com.ctype" type="info" size="mini"
                                                   style="cursor:default;margin:0 10px 5px 0;"
                                                   plain round disabled>
                                            {{com.ctype}}
                                        </el-button>
                                    </div>

                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <!--                    <el-divider/>-->
                    <!--                    <el-pagination v-bind="pagination" @size-change="handlePaginationSizeChange"-->
                    <!--                                   @current-change="handlePaginationCurrentChange"-->
                    <!--                                   class="me-search-result-el-pagination">-->
                    <!--                    </el-pagination>-->
                </el-card>
            </el-col>
        </el-row>
    </el-row>
</template>
<script>
    import {engineResult} from "../api/api";
    import recommend from './recommend.json'
    export default {
        components: {},
        data() {
            return {
                loading: {
                    searchResult: false,
                    recommend: false
                },
                form: {
                    search: {
                        searchType: 1,
                        kw: ''
                    }
                },
                rules: {
                    search: {
                        kw: [{required: true, message: '请输入搜索关键词!', trigger: 'blur'}]
                    }
                },
                list: {
                    recommend: []
                },
                pagination: {
                    background: true,
                    layout: "sizes, prev, pager, next",
                    pageSizes: [10, 20, 50, 100],
                    pageSize: 20,
                    pagerCount: 5,
                    total: 101
                }
            }
        },
        created() {
            this.loadDefaultData()
        },
        methods: {
            getRandomDefLogo() {
                let ran = Math.ceil(Math.random() * 15).toString(16)
                return require(`../assets/pic/deflogo${ran}.png`)
            },
            gotoDetal(info) {
                // let routeUrl = this.$router.resolve({
                //     path: "/merchantsEngineDetail/" + id
                // });
                // window.open(routeUrl.href, '_blank');
                this.$router.push({
                    path: "/merchantsEngineDetail/" + info.id,
                    query: {
                        sp: new Number(info.sp)*0.5
                    }
                })
            },
            loadDefaultData() {
                this.list.recommend=recommend
                // const params = {
                //     searchType: 2,
                //     kw: '电子信息',
                //     appendBigData: true,
                //     appendEsData: true
                // }
                // this.loading.recommend = true
                // engineResult(params).then(res => {
                //     this.loading.recommend = false
                //     this.list.recommend = res.data
                // }).catch(() => {
                //     this.loading.recommend = false
                // })
            },
            handlePaginationSizeChange() {
                //console.log(this.pagination)
            },
            handlePaginationCurrentChange() {
                //console.log(this.pagination)
            },
            handleSearchSubmit(formName) {
                let that = this
                that.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$router.push({path: '/merchantsEngineResult', query: this.form.search})
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    }

</script>


<style scoped>
    * {
        text-align: initial;
    }

    .box {
        display: flex;
        flex-wrap: wrap;
    }

    .top-cont {
        height: 300px;
        background-image: url('../assets/pic/dashuju_banner.png');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .me-search-el-card {
        margin: 30px 0px;
    }

    .me-search-input {
        margin: 15px 0px;
    }

    .me-search-el-button {
        background-color: #409EFF !important;
        color: #fff !important;
        padding: 13px 20px !important;
        border-radius: 0px !important;
    }

    .me-search-result-el-card {
        margin: 20px 0px;
    }

    .me-search-result-item-el-card {
        border: 0px;
    }

    .me-search-result-item-el-card:hover {
        /*border: 1px solid rgba(0, 64, 152, 0.0862745098039216);*/
        background-color: rgba(0, 64, 152, 0.1);
    }

    .me-search-result-el-pagination {

    }

</style>
