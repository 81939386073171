<template>
  <el-row>
    <el-row type="flex" justify="center">
      <el-col :span="16">
        <el-card class="me-search-el-card">
          <div style="color:black;font-size: 22px;font-weight: bold;">
            <span>{{key}}</span>
          </div>
          <br />
          <el-form :model="form.search" :rules="rules.search" ref="searchForm" v-loading="loading.searchResult">
            <el-form-item class="me-search-el-form-item" label="注册资本">
              <el-checkbox-group style="float: left;" size="mini" v-model="form.search.sCapital.notLimited">
                <el-checkbox-button @change="(checked)=>handleNotLimitedChange(checked,'sCapital')">
                  不限
                </el-checkbox-button>
              </el-checkbox-group>
              <el-checkbox-group style="margin-left: 126px;" size="mini" v-model="form.search.sCapital.value">
                <el-checkbox-button v-for="(rc,index) in dictOptions.dictCapital" :label="rc.value"
                  @change="(checked)=>{handleCheckboxChange(checked,'sCapital')}" :key="index">{{rc.text}}
                </el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item class="me-search-el-form-item" label="所属行业">
              <el-checkbox-group style="float: left;" size="mini" v-model="form.search.sCat.notLimited">
                <el-checkbox-button @change="(checked)=>handleNotLimitedChange(checked,'sCat')">
                  不限
                </el-checkbox-button>
              </el-checkbox-group>
              <el-checkbox-group style="margin-left: 126px;" size="mini" v-model="form.search.sCat.value">
                <el-checkbox-button v-for="(rc,index) in dictOptions.dictCat" :label="rc.value"
                  @change="(checked)=>{handleCheckboxChange(checked,'sCat')}" :key="index">{{rc.text}}
                </el-checkbox-button>
              </el-checkbox-group>
            </el-form-item>

          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center">
      <el-col :span="16">
        <el-card class="me-search-result-el-card" v-loading="loading.searchResult">
          <el-row>
            <el-col :span="19">
              共有 {{pagination.total}} 家企业
            </el-col>
            <el-col :span="5" style="text-align: center;">
              <el-link :underline="false">匹配度</el-link>
              <el-link :underline="false" style="margin-left:40px">注册资金</el-link>
            </el-col>
          </el-row>
          <el-divider />
          <el-row v-if="pagination.total>0">
            <el-col :xs="24" v-for="com in list.result" style="padding: 5px;" :key="com.id">
              <el-card shadow="never" class="me-search-result-item-el-card">

                <div style="float:left;width: 70px;margin-top: 6px;">
                  <el-avatar shape="square" :size="70"
                    :src="require('@/assets/pic/defalut_building.png')">
                    <img :src="require('@/assets/pic/defalut_building.png')" />
                  </el-avatar>
                </div>
                <div style="width: calc(100% - 85px);margin-left: 85px;">
                  <el-row>
                    <el-col :span="20">
                      <el-link :underline="false" @click="gotoDetal(com)"
                        style="font-weight: bold;font-size: 16px;margin-bottom: 3px;">
                        {{com.name}}
                      </el-link>
                      <el-tooltip>
                        <div slot="content">{{com.regLocation}}</div>
                        <div
                          style="color: #aaa;font-size: 13px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                          {{com.regLocation}}
                        </div>
                      </el-tooltip>
                      <div style="font-size: 14px;margin-top:15px;">
                        <el-button v-if="com.cat" type="info" size="mini" style="cursor:default;" plain round disabled>
                          {{com.cat}}
                        </el-button>
                        <el-button v-if="com.cqcat" type="info" size="mini" style="cursor:default;" plain round
                          disabled>
                          {{com.cqcat}}
                        </el-button>
                        <el-button v-if="com.crole" type="info" size="mini" style="cursor:default;" plain round
                          disabled>
                          {{com.crole}}
                        </el-button>
                        <el-button v-if="com.ctype" type="info" size="mini" style="cursor:default;" plain round
                          disabled>
                          {{com.ctype}}
                        </el-button>
                      </div>
                    </el-col>
                    <el-col :span="4" style="text-align: center;padding: 30px 0px;">
                      <span style="font-size: 18px;font-weight: bold;">
                        <span v-if="com.sp">{{new Number(com.sp*100).toFixed(0)+'%'}}</span>
                        <span v-else> - </span>
                      </span>
                      <el-divider direction="vertical"></el-divider>
                      <span v-if="com.cap&&com.cap!='-'">
                        <span style="font-size: 18px;font-weight: bold;">{{/\d+(\.\d+)?/.exec(com.cap)[0]}}</span>
                        <span style="font-size: 11px;">{{com.cap.replace(/\d+(\.\d+)?/g,'')}}</span>
                      </span>
                      <span v-else> - </span>
                    </el-col>
                  </el-row>
                </div>
              </el-card>
            </el-col>
          </el-row>
          <el-row v-else>
            <el-col :span="24">
              <h3 style="text-align: center">无结果</h3>
            </el-col>
          </el-row>
          <el-divider v-if="pagination.total>0" />
          <el-pagination v-if="pagination.total>0" v-bind="pagination" @size-change="handlePaginationSizeChange"
            @current-change="handlePaginationCurrentChange" class="me-search-result-el-pagination">
          </el-pagination>
        </el-card>
      </el-col>
    </el-row>
  </el-row>
</template>
<script>
import {
  initDictOptions,
  filterDictText
} from "@/components/dict/JDictSelectUtil";
import { engineResult } from "../api/api";

export default {
  components: {},
  data() {
    return {
      loading: {
        searchResult: false
      },
      form: {
        search: {
          sCapital: {
            notLimited: true,
            value: []
          },
          sCat: {
            notLimited: true,
            value: []
          },
          sProvince: {
            notLimited: true,
            value: []
          }
        }
      },
      rules: {
        search: {
          searchKeyWord: [
            { required: true, message: "请输入搜索关键词!", trigger: "blur" }
          ]
        }
      },
      list: {
        result: {}
      },
      pagination: {
        background: true,
        layout: "sizes, prev, pager, next",
        pageSizes: [10, 20, 50, 100],
        pageSize: 20,
        pagerCount: 5,
        currentPage: 1,
        total: 0
      },
      dictOptions: {
        dictCapital: [],
        dictCat: [],
        dictProvince: []
      }
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path.indexOf("/merchantsEngineDetail") > -1) {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false;
    }
    next();
  },
  activated() {
    if (!this.$route.meta.isBack) {
      this.pagination.currentPage =1
      this.key = this.$route.params.key;
      // Object.assign(this.form.search, this.$route.query)
      this.initDictConfig();
      this.loadSearchResult();
    } else {
      this.$route.meta.isBack = false;
    }
  },
  created() {
    this.pagination.currentPage =1
    this.key = this.$route.params.key;
    // Object.assign(this.form.search, this.$route.query)
    this.initDictConfig();
    this.loadSearchResult();
  },
  methods: {
    gotoDetal(info) {
      // let routeUrl = this.$router.resolve({
      //     path: "/merchantsEngineDetail/" + id
      // });
      // window.open(routeUrl.href, '_blank');
      this.$router.push({
        path: "/merchantsEngineDetail/" + info.id,
        query: {
          sp: new Number(info.sp)*0.5
        }
      });
    },
    loadSearchResult() {
      let that = this;
      that.loading.searchResult = true;
      let params = {
        kw: that.key,
        searchType: 1,
        appendEsData: true,
        from: (that.pagination.currentPage - 1) * that.pagination.pageSize,
        size: that.pagination.pageSize
      };

      if (that.form.search.sCat && that.form.search.sCat.value.length > 0) {
        params.cat = that.form.search.sCat.value;
      } else {
        delete params["cat"];
      }
      if (
        that.form.search.sCapital &&
        that.form.search.sCapital.value.length > 0
      ) {
        params.capital = that.form.search.sCapital.value;
      } else {
        delete params["capital"];
      }
      if (
        that.form.search.sProvince &&
        that.form.search.sProvince.value.length > 0
      ) {
        params.province = that.form.search.sProvince.value;
      } else {
        delete params["province"];
      }

      engineResult(params)
        .then(res => {
          that.loading.searchResult = false;
          if (res.success) {
            that.list.result = res.data;
            that.pagination.total = res.count;
          } else {
          }
        })
        .catch(() => {
          that.loading.searchResult = false;
        });
    },
    initDictConfig() {
      initDictOptions("zs_condition_registered_capital").then(res => {
        if (res.success) {
          this.$set(this.dictOptions, "dictCapital", res.result);
        }
      });
      initDictOptions("zs_condition_registered_cat").then(res => {
        if (res.success) {
          this.$set(this.dictOptions, "dictCat", res.result);
        }
      });
    },
    handleNotLimitedChange(checked, formItemName) {
      if (checked) {
        this.form.search[formItemName].value = [];
      } else {
        this.form.search[formItemName].notLimited = true;
      }
      this.loadSearchResult();
    },
    handleCheckboxChange(checked, formItemName) {
      if (checked) {
        this.form.search[formItemName].notLimited = false;
      } else {
        if (this.form.search[formItemName].value.length === 0) {
          this.form.search[formItemName].notLimited = true;
        }
      }
      this.loadSearchResult();
    },
    handlePaginationSizeChange(pageSize) {
      this.pagination.pageSize = pageSize;
      this.loadSearchResult();
    },
    handlePaginationCurrentChange(currentPage) {
      this.pagination.currentPage = currentPage;
      this.loadSearchResult();
    },
    handleSearchSubmit(formName) {
      let that = this;
      that.$refs[formName].validate(valid => {
        if (valid) {
          console.log(this.form.search);
          // that.loading.searchResult = true
          // setTimeout(() => {
          //     // that.loading.searchResult = false
          //     // alert('submit!');
          // }, 3000)
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>


<style scoped>
* {
  text-align: initial;
}

.me-search-el-form-item {
  margin: 5px 0px;
}

.me-search-el-card {
  margin: 30px 0px;
}

.me-search-result-el-card {
  margin: 20px 0px;
}

.me-search-result-item-el-card {
  border: 0px;
}

.me-search-result-item-el-card:hover {
  /*border: 1px solid rgba(0, 64, 152, 0.0862745098039216);*/
  background-color: rgba(0, 64, 152, 0.1);
}

.me-search-result-el-pagination {
}
</style>
