<template>
  <div class="checkWrap">
    <p class="title">{{title}}</p>
    <ul class="checkList">
      <li :class="{active: !emitArr.length}" @click="resetSelect">{{allText}}</li>
       <!-- <li v-for="(item,index) in renderData" :class="{active:item.active}" :key="Math.random()"
          @click="addAction(index)"> -->
      <li v-for="(item,index) in renderData" :class="{active:item.active}" :key="index"
          @click="addAction(index)">{{showIndex?(index+1)+" ":''}}{{item[pt]}}
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    inheritAttrs: false,
    props: {
      showIndex:{
        type: Boolean,
        default:false,
        required:false
      },
      renderData: {
        type: Array,
        default: function(){
          return []
        }
      },
      title: {
        type: String
      },
      allText: {
        type: String,
        default: "全部"
      },
      pt: {
        type: String,
        default: "text"
      },
      pv: {
        type: String,
        default: "value"
      },
      reset: {
        type: Number
      }
    },
    data() {
      return {
        emitArr: []
      }
    },
    watch: {
      reset(newda) {
        console.log(newda)
        this.resetSelect()
      }
    },
    methods: {
      removeKeyFromArr(arr,key){
        let removeIndex= arr.findIndex(value => value==key);
        if(-1!=removeIndex){
          arr.splice(removeIndex, 1)
        }
      },
      addAction(index) {
        let data = this.renderData[index];
        data.active = !data.active
        this.$set(this.renderData, index, data)
        let value = data[this.pv];
        if (data.active) {
          this.emitArr.push(value)
        } else {
          this.removeKeyFromArr(this.emitArr,value);
        }
        this.$emit('input', this.emitArr.join(","))
      },
      resetSelect() {
        this.emitArr = []
        this.renderData.forEach(item => {
          item.active = false
        })
        this.$emit('input', this.emitArr.join(","))
      }
    }
  }
</script>

<style scoped>
  p {
    margin: 0;
  }

  .checkWrap {
    padding: 15px 0px 10px 0;
    font-size: 14px;
    border-bottom: 1px dotted #E9E9E9;
  }

  .checkWrap p.title {
    display: inline-block;
    /*width: 120px;*/
    float: left;
    text-align: left;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: bold;
    height: 24px;
  }

  .checkList {
    margin-left: 70px;
    /*text-align: justify;*/
    margin-bottom: 0;
  }

  .checkList li {

    display: inline-block;
    color: rgba(0, 0, 0, 0.65);
    margin: 0px 8px;
    line-height: 24px;
    padding: 0px 8px;
    border-radius: 2px;
    cursor: pointer;
    margin-bottom: 5px;
    transition: 0.3s;
  }

  .checkList img {
    display: inline;
    vertical-align: middle;
  }

  .checkList li.active {
    background-color: #1890ff;
    color: white;
  }
</style>
