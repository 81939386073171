<template>
    <div class="mypark">
        <div class="bannerBox">
            <div class="banner">
                <el-carousel indicator-position="outside" arrow="hover">
                    <el-carousel-item>
                        <img class="web-banner" alt="" src="../assets/u105.png">
                    </el-carousel-item>
                     <el-carousel-item>
                        <img class="web-banner" alt="" src="../assets/u106.png">
                    </el-carousel-item>
                    <el-carousel-item>
                        <img class="web-banner" alt="" src="../assets/u108.png">
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <!-- 欢迎 -->
        <el-main style="padding-bottom:0px;overflow:hidden">
            <el-row type="flex" class="row-bg" justify="center">
                <el-col style="width:1200px;overflow:hidden"> 
                    <!-- 我的关注企业状态 -->
                    <div class="page-panel-div">
                        <el-row type="flex" class="row-bg">
                            <el-col :span="12">
                                <div class="grid-content bg-nav"
                                     style="font-size:24px;font-family:PingFangSC-Semibold,PingFang SC;font-weight:600;">
                                    跟进企业状态
                                </div>
                            </el-col> 
                        </el-row>
                        <el-row type="flex" class="row-bg">
                            <div class="grid-content bg-nav panel-div ">
                                <div class="hover-div" @click="resultList(statusCount.collectedCount,1,'无关注企业')">
                                    <div class="company_box">
                                        <img src="../assets/pic/base_banner_icon1.png" alt=""
                                             style="width:23px;height:23px;">
                                        <span>关注企业</span>
                                    </div>
                                    <div class="panel-div-number">
                                        {{statusCount.collectedCount}}
                                    </div>
                                </div>
                                <div class="divider"></div>
                            </div>
                            <div class="grid-content bg-nav panel-div ">
                                <div class="hover-div" @click="resultList(statusCount.communicatedCount,2,'无已沟通企业')">
                                    <div class="company_box">
                                        <img src="../assets/pic/base_banner_icon2.png" alt=""
                                             style="width:23px;height:23px;">
                                        <span>已沟通企业</span>
                                    </div>
                                    <div class="panel-div-number">
                                        {{statusCount.communicatedCount}}

                                    </div>
                                </div>
                                <div class="divider"></div>
                            </div>
                            <div class="grid-content bg-nav panel-div ">
                                <div class="hover-div"
                                     @click="resultList(statusCount.haveTheIntentionCount,3,'无有意向企业')">
                                    <div class="company_box">
                                        <img src="../assets/pic/base_banner_icon3.png" alt=""
                                             style="width:23px;height:23px;">
                                        <span>有意向企业</span>
                                    </div>
                                    <div class="panel-div-number">
                                        {{statusCount.haveTheIntentionCount}}
                                    </div>
                                </div>
                                <div class="divider"></div>
                            </div>
                            <div class="grid-content bg-nav panel-div ">
                                <div class="hover-div" @click="resultList(statusCount.toBeIgnedCount,4,'无无意向企业')">
                                    <div class="company_box">
                                        <img src="../assets/pic/base_banner_icon4.png" alt=""
                                             style="width:23px;height:23px;">
                                        <span>无意向企业</span>
                                    </div>
                                    <div class="panel-div-number">
                                        {{statusCount.toBeIgnedCount}}

                                    </div>
                                </div>
                                <div class="divider"></div>
                            </div>
                            <div class="grid-content bg-nav panel-div ">
                                <div class="hover-div" @click="resultList(statusCount.landedCount,5,'无已落地企业')">
                                    <div class="company_box">
                                        <img src="../assets/pic/base_banner_icon5.png" alt=""
                                             style="width:23px;height:23px;">
                                        <span>已落地企业</span>
                                    </div>
                                    <div class="panel-div-number">
                                        {{statusCount.landedCount}}

                                    </div>
                                </div>
                            </div>
                        </el-row>
                    </div>
                    <!-- 动态图分析 -->
                    <div class="page-chart-div" v-if="false">
                        <el-row type="flex" class="row-bg" :gutter="20">
                            <el-col :span="12">
                                <div class="grid-content bg-chart-tilte-special2">
                                    <div style="display:flex">
                                        <div class="blue-block" style="margin-top:12px;"></div>
                                        <h1>园区发展概览</h1>
                                    </div>
                                    <div class="chart-body" style="padding-left:25px;padding-top:5px;">
                                        分析招商引资增长速度，每年任务完成度。提高招商效率。
                                    </div>
                                </div> 
                                <el-tabs class="tabs-div" type="border-card" >
                                    <el-tab-pane label="园区引资计划与实际引资额">
                                        <div class="echarts-border">
                                            <v-chart :options="polar" autoresize/>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane label="园区发展情况">
                                        <div class="echarts-border">
                                            <v-chart :options="parkDev" autoresize/>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane label="园区人力构成">
                                        <div class="echarts-border">
                                            <v-chart :options="parkManPower" autoresize/>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>

                            </el-col>
                            <el-col :span="12">
                                <div class="grid-content bg-chart-tilte-special2">
                                    <div style="display:flex">
                                        <div class="blue-block" style="margin-top:12px;"></div>
                                        <h1>园区企业行为分析统计</h1>
                                    </div>
                                    <div class="chart-body" style="padding-left:25px;padding-top:5px;">
                                        多维度对园区企业数量进行排名和统计帮助你快速了解园区内的企业概况
                                    </div>

                                </div>
                                <div class="view-park-behavior" > 
                                    <v-chart :options="parkBehavior" style="margin-top:50px;min-width:700px"
                                             @click="parkBehaviorClick"
                                             autoresize/> 
                                    <div v-if="showDetails" id="park-behavior-detail" class="view-park-behavior-detail">
                                        <p>{{parkBehavior.series[1].name}} 23%</p>
                                        <p>共有企业：23家</p>
                                        <p>占比全部企业2.82%</p>
                                        <el-link :underline="false" type="primary" class="agreement-div"
                                                 style="color: #004098;"
                                                 @click="toPage('制造业')">查看企业列表
                                        </el-link>
                                    </div>
                                </div>
                            </el-col>
                        </el-row> 
                    </div>
                </el-col>
            </el-row>

        </el-main>

        <el-main style="overflow:hidden;">
            <el-row type="flex" class="row-bg" justify="center" style="width:1200px;overflow:hidden;margin:0 auto">
                <el-col :span="16" style="width:1200px">

                    <!-- 关注的企业新闻 -->
                    <div class="page-news-div">
                        <el-row type="flex" class="row-bg" :gutter="20">
                           
                            <!-- 我的重点产业 -->
                            <el-col :span="12">
                                <div class="grid-content bg-chart-tilte-special">
                                    <el-row>
                                        <el-col :span="12">
                                            <div class="grid-content bg-purple news-words">
                                                <div class="blue-block"></div>
                                                <p style="padding-left:20px;">我的重点产业</p>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                        </el-col>
                                    </el-row>
                                </div>
                                <!-- 我的重点产业内容 -->
                                <div class="news-detail-right" style="padding-top:10px" v-loading="loading">
                                    <div class="myImportant" v-for="kid in keyIndustryData" :key="kid.id"
                                         @click="handleKeyIndustryClick(kid.industryName)"> 
                                        <img :src="kid.industryParkCover" alt="">
                                        <div>{{kid.industryName}}</div>
                                    </div>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="grid-content bg-chart-tilte-special">
                                    <el-row>
                                        <el-col :span="12">
                                            <div class="grid-content bg-purple news-words">
                                                <div class="blue-block"></div>
                                                <p style="padding-left:20px;">投资项目</p>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content  news-words-more-special">
                                                <router-link to="/projectLibrary" style="margin-right: 10px;">更多
                                                </router-link>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>

                                <div class="news-detail-right" style="padding-top:10px" v-loading="loading">
                                    <div v-for="(item,index) in projectLibraryListData" :key="index">
                                        <div class="div-panel" @click="ProjectLibraryDetail(item)">
                                            <div style="text-align:left">
                                                <p class="news-detail-right-title"><a>{{item.name}}</a></p>
                                            </div>
                                            <el-row>
                                                <el-col :span="12">
                                                    <div class="grid-content bg-purple">
                            <span class="news-detail-right-num">{{unitConvert(item.investment_amount).num}}<span
                                    style="padding-left:8px;"
                                    class="news-detail-right-danwei">{{unitConvert(item.investment_amount).unit}}</span></span>
                                                    </div>
                                                    <div class="grid-content">
                                                        <span class="news-detail-right-words">预计投资总额</span>
                                                    </div>
                                                </el-col>
                                                <el-col :span="12"
                                                        style="border-left:1px solid #DCDFE6;padding-left:30px">
                                                    <div class="grid-content">
                            <span class="news-detail-right-num">{{unitConvert(item.tax_amount).num}}<span
                                    style="padding-left:8px;"
                                    class="news-detail-right-danwei">{{unitConvert(item.tax_amount).unit}}</span></span>
                                                    </div>
                                                    <div class="grid-content">
                                                        <span class="news-detail-right-words">预计税金总额</span>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </div>
                                        <el-divider class="el-divider--horizontal2"></el-divider>
                                    </div>

                                </div>
                            </el-col>
                        </el-row>

                    </div>

                </el-col>
            </el-row>

        </el-main>
        <!-- 产业链概览 -->
        <div>
            <el-main style="background:transparent !important;overflow:hidden">
                <el-row type="flex" class="row-bg" justify="center" style="width:1200px;margin:0 auto">
                    <el-col :span="16" style="width:1200px">

                        <el-row v-if="icmData.my&&icmData.my.length>0">
                            <el-col :span="12">
                                <div class="grid-content bg-purple point-top-words">
                                    <p>我的产业链</p>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div class="grid-content   point-top-right-words">
                                    <a href="/industryChainMap">详情</a>
                                </div>
                            </el-col>
                        </el-row>
                        <el-row class="wode-box" v-if="icmData.my&&icmData.my.length>0">
                            <el-col class="wode" v-for="(icm,index) in icmData.my" :key="index">
                                <div class="grid-content" :style="icm.style" @click="gotoIcmDetal(icm)">
                                    <p class='point-words'>{{icm.industaryName}}</p>
                                </div>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>

            </el-main>
        </div>

        <el-main style="overflow:hidden">
            <el-row type="flex" class="row-bg" justify="center" style="width:1200px;margin:0 auto;">
                <el-col :span="16" style="width:1200px;">

                    <!--产业链推荐  -->
                    <div class="page-recommend-div">
                        <el-row type="flex" class="row-bg">
                            <el-col :span="24">
                                <div class="grid-content bg-chart-tilte-special">
                                    <el-row>
                                        <el-col :span="12">
                                            <div class="grid-content news-words">
                                                <div class="blue-block"></div>
                                                <p style="padding-left:20px;">产业链推荐</p>
                                            </div>
                                        </el-col>
                                        <el-col :span="12">
                                            <div class="grid-content news-words-more-special">
                                                <a href="/industryChainMap?actt=all" style="margin-right:10px">更多</a>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-col>
                        </el-row>
                        <div style="padding-top:20px;">
                            <el-row class="recommend">
                                <div class="grid-content"
                                     :style="icm.style"
                                     v-for="(icm,index) in icmData.ran" :key="index">
                                    <p class='point-words'>{{icm.industaryName}}</p>
                                </div>
                            </el-row>
                        </div>
                    </div>

                </el-col>
            </el-row>

        </el-main>
    </div>
</template>

<script>
    /* eslint-disable no-console */
    /* eslint-disable no-unused-vars */
    import ECharts from "vue-echarts";
    import "echarts/lib/chart/line";
    import "echarts/lib/component/polar";
    import "echarts/lib/component/title";
    import "echarts/lib/component/legend";
    import "echarts/lib/chart/pie";
    import {getAction} from "@/api/api";
    import {Message} from "element-ui";
    import {recommendIndustryChain,allIndustryChain,findMyIndustryChain, keyIndustryData} from "../api/api";

    let base64 = require('js-base64').Base64

    export default {
        name: "base_page",

        components: {
            "v-chart": ECharts
        },
        methods: {
            gotoIcmDetal(node) {
                let routeUrl = this.$router.resolve({
                    path: "/industryChainMapDetail",
                    query: {
                        tn: base64.encode(node.industaryName)
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            getRandomNum(minNum, maxNum, excludeArr) {
                switch (arguments.length) {
                    case 1:
                        return parseInt(Math.random() * minNum + 1, 10)
                    case 2:
                        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
                    //或者 Math.floor(Math.random()*( maxNum - minNum + 1 ) + minNum );
                    case 3:
                        let ranNum = parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
                        if (excludeArr && excludeArr instanceof Array) {
                            if (excludeArr.indexOf(ranNum) != -1) {
                                ranNum = this.getRandomNum(minNum, maxNum, excludeArr)
                            }
                        }
                        return ranNum
                    default:
                        return -1;
                }
            },
            loadDefaultData() {
                let that = this;
                // industryChainMapData({all: true}).then(cres => {
                //     if (cres.success && cres.data && cres.data.length > 0) {
                //         let allData = cres.data;
                //         let ranArr = []
                //         ranArr[0] = this.getRandomNum(0, allData.length - 1)
                //         ranArr[1] = this.getRandomNum(0, allData.length - 1, ranArr)
                //         ranArr[2] = this.getRandomNum(0, allData.length - 1, ranArr)

                //         that.icmData.ran.push(allData[ranArr[0]])
                //         that.icmData.ran.push(allData[ranArr[1]])
                //         that.icmData.ran.push(allData[ranArr[2]])

                //         that.icmData.ran.forEach(tn => {
                //             let tmp = require('@/assets/icm/' + tn.name + '.png')
                //             tn.style = {
                //                 backgroundImage: 'url(' + tmp.toString() + ')'
                //             }
                //         })
                //     }
                // }).catch(() => {
                // })
                recommendIndustryChain().then(res =>{
                     if (res.success && res.result && res.result.length > 0) { 
                        that.icmData.ran = res.result;
                        that.icmData.ran.forEach(tn => {  
                            tn.style = {
                                backgroundImage: 'url(' + tn.industryImage.toString() + ')'
                            }
                        })
                    } 
                }).catch(() => {
                }) 
               findMyIndustryChain( ).then(res => {
                   if (res.success && res.result && res.result.length > 0) { 
                        that.icmData.my = res.result.slice(0, 4);
                        that.icmData.my.forEach(tn => {  
                            tn.style = {
                                backgroundImage: 'url(' + tn.industryImage.toString() + ')'
                            }
                        })
                    }
                   
                }).catch(() => {
                })
            },
            handleKeyIndustryClick(value) {
                let routeUrl = this.$router.resolve({
                    name: "MerchantsEngineResult",
                    query: {
                        searchType: 2,
                        kw: base64.encode(value),
                        source: 'kid'
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            toPage(val) {
                // this.$router.push({
                //     name: "KeyIndustry",
                //     params: {
                //         key: val
                //     }
                // });
                // this.$router.push({
                //     name: "MerchantsEngineResult",
                //     query: {
                //         searchType: 1,
                //         cqcat: val,
                //         source: 'cqcat'
                //     }
                // });
                let routeUrl = this.$router.resolve({
                    name: "MerchantsEngineResult",
                    query: {
                        searchType: 1,
                        cqcat: val,
                        source: 'cqcat'
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            resultList(count, status, message) {
                if (count == 0) {
                    Message({
                        title: "提示",
                        message: message,
                        type: "error",
                        duration: 1500
                    });
                } else {
                    // this.$router.push({name: 'FavoriteCompanyList', params: {status: status}});

                    let routeUrl = this.$router.resolve({
                        name: "FavoriteCompanyList",
                        query: {
                            status: status
                        }
                    });
                    window.open(routeUrl.href, '_blank');
                }
            },
            parkBehaviorClick(event, instance, echarts) {
                console.log(arguments[0]);
                // if(arguments[0].componentIndex == 1){
                //     return;
                // }
                if (arguments[0].seriesIndex == "1") {
                    if (this.activeSector2 == arguments[0].name) {
                        this.activeSector2 = "";
                        this.showDetails = false;
                    } else {
                        this.showDetails = true;
                        this.activeSector2 = arguments[0].name;
                        console.log(arguments[0].seriesIndex);
                    }

                    return;
                }
                // var show = document.getElementById('park-behavior-detail');
                // show.style = "display:block";
                // console.log(this.parkBehavior.series);
                switch (arguments[0].data.name) {
                    case "建筑业":
                        console.log("筑业");

                        if (this.activeSector === "建筑业") {
                            //
                            this.activeSector = "";
                            this.showDetails = false;
                            this.parkBehavior.series[0].radius = [0, "80%"];
                            this.parkBehavior.series[1].data = [];
                        } else {
                            this.activeSector = "建筑业";
                            this.parkBehavior.series[1].name = "建筑业";
                            this.parkBehavior.series[0].radius = [0, "40%"];

                            this.parkBehavior.series[1].data = [
                                {value: 4, name: "房屋建筑"},
                                {value: 3, name: "土木工程"},
                                {value: 8, name: "节能环保"}
                            ];
                        }

                        break;
                    case "采矿业":
                        if (this.activeSector === "采矿业") {
                            //
                            this.activeSector = "";
                            this.showDetails = false;
                            this.parkBehavior.series[0].radius = [0, "80%"];

                            this.parkBehavior.series[1].data = [];
                        } else {
                            this.activeSector = "采矿业";
                            this.parkBehavior.series[1].name = "采矿业";
                            this.parkBehavior.series[0].radius = [0, "40%"];
                            this.parkBehavior.series[1].data = [
                                {value: 4, name: "煤炭开采"},
                                {value: 3, name: "石油开采"},
                                {value: 8, name: "金属矿开采"},
                                {value: 5, name: "非金属矿"}
                            ];
                        }

                        break;
                    case "批发和零售业":
                        if (this.activeSector === "批发和零售业") {
                            //
                            this.activeSector = "";
                            this.showDetails = false;
                            this.parkBehavior.series[0].radius = [0, "80%"];
                            this.parkBehavior.series[1].data = [];
                        } else {
                            this.activeSector = "批发和零售业";
                            this.parkBehavior.series[0].radius = [0, "40%"];
                            this.parkBehavior.series[1].name = "批发和零售业";
                            this.parkBehavior.series[1].data = [
                                {value: 14, name: "批发业"},
                                {value: 9, name: "零售业"}
                            ];
                        }

                        break;
                    case "其他":
                        if (this.activeSector === "其他") {
                            //
                            this.activeSector = "";
                            this.showDetails = false;
                            this.parkBehavior.series[0].radius = [0, "80%"];
                            this.parkBehavior.series[1].data = [];
                        } else {
                            this.activeSector = "其他";
                            this.parkBehavior.series[1].name = "其他";
                            this.parkBehavior.series[0].radius = [0, "40%"];
                            this.parkBehavior.series[1].data = [
                                {value: 7, name: "谷物磨制"},
                                {value: 3, name: "蔬菜、菌类"},
                                {value: 8, name: "饲料加工"},
                                {value: 10, name: "植物油加"},
                                {value: 1, name: "制糖业"},
                                {value: 5, name: "屠宰及肉"},
                                {value: 6, name: "其他"},
                                {value: 20, name: "水产品"}
                            ];
                        }

                        break;

                    case "制造业":
                        if (this.activeSector === "制造业") {
                            //
                            this.activeSector = "";
                            this.showDetails = false;
                            this.parkBehavior.series[0].radius = [0, "80%"];
                            this.parkBehavior.series[1].data = [];
                        } else {
                            this.activeSector = "制造业";
                            this.parkBehavior.series[0].radius = [0, "40%"];
                            this.parkBehavior.series[1].name = "制造业";
                            this.parkBehavior.series[1].data = [
                                {value: 7, name: "谷物磨制"},
                                {value: 3, name: "蔬菜、菌类"},
                                {value: 8, name: "饲料加工"},
                                {value: 10, name: "植物油加"},
                                {value: 1, name: "制糖业"},
                                {value: 5, name: "屠宰及肉"},
                                {value: 6, name: "其他"},
                                {value: 20, name: "水产品"}
                            ];
                        }
                        break;
                    default:
                        // this.parkBehavior.series[1].name = '制造业';
                        // this.parkBehavior.series[1].data = [
                        //           {value:7, name:'谷物磨制'},
                        //           {value:3, name:'蔬菜、菌类'},
                        //           {value:8, name:'饲料加工'},
                        //           {value:10, name:'植物油加'},
                        //           {value:1, name:'制糖业'},
                        //           {value:5, name:'屠宰及肉'},
                        //           {value:6, name:'其他'},
                        //           {value:20, name:'水产品'},

                        //       ]
                        break;
                }

                //this.parkBehavior.setOptions(this.parkManPower,true);
            },
            gotoRow(index, data) {
                //console.log(data);
                this.$router.push({path: `/projects/${data[index].id}`}); // -> /user/123
            },
            visible(i) {
                this.seen = i;
            },
            projectLibraryList() {
                this.loading = true
                getAction(this.url.projectLibraryList, {
                    pageNo: "1",
                    pageSize: "4"
                }).then(res => {
                    if (res.success) {
                        this.loading = false
                        this.projectLibraryListData = res.result.records;
                    } else {
                        this.loading = false
                        Message({
                            title: "提示",
                            message: res.message,
                            type: "error",
                            duration: 1500
                        });
                    }
                });
            },
            ProjectLibraryDetail: function (record) {
                sessionStorage.setItem("projectDetialRecord",JSON.stringify(record));
                this.$router.push({
                    name: "ProjectLibraryDetail",
                    params: {record: record}
                });
            },
            unitConvert: function (num) {
                var moneyUnits = ["万元", "亿元", "万亿"];
                var dividend = 10000;
                var curentNum = num;
                //转换数字
                var curentUnit = moneyUnits[0];
                //转换单位
                for (var i = 0; i < 4; i++) {
                    curentUnit = moneyUnits[i];
                    if (this.strNumSize(curentNum) < 5) {
                        break;
                    }
                    curentNum = curentNum / dividend;
                }
                var m = {num: 0, unit: ""};
                if (curentUnit == "万元") {
                    m.num = curentNum.toFixed(0);
                } else {
                    m.num = Math.floor(curentNum * 100) / 100;
                }
                m.unit = curentUnit;
                return m;
            },
            strNumSize: function (tempNum) {
                var stringNum = tempNum.toString()
                var index = stringNum.indexOf(".")
                var newNum = stringNum;
                if (index != -1) {
                    newNum = stringNum.substring(0, index)
                }
                return newNum.length
            }
        },
        created() {
            this.projectLibraryList();
            keyIndustryData().then((res) => {
                if (res.success) {
                    this.keyIndustryData = res.result;
                }
            })
            this.loadDefaultData()
            getAction(this.url.queryCountByStatus, {}).then((res) => {
                if (res.success) {
                    this.statusCount = res.result
                } else {
                    Message({
                        title: "提示",
                        message: res.message,
                        type: "error",
                        duration: 1500
                    });
                }
            })
          
        },

        data() {
            let data = [];
            var colors = ["#5793f3", "#d14a61", "#675bba"];

            for (let i = 0; i <= 360; i++) {
                let t = (i / 180) * Math.PI;
                let r = Math.sin(2 * t) * Math.cos(2 * t);
                data.push([r, i]);
            }
            return {
                loading: false,
                keyIndustryData: [],
                projectLibraryListData: [],
                url: {
                    projectLibraryList: "/merchants/projectLibrary/list",
                    queryCountByStatus: "/merchants/merchantsFavoriteCompany/queryCountByStatus"
                },
                icmData: {
                    my: [],
                    ran: []
                },
                statusCount: {},
                seen: 0,
                showDetails: false,
                activeName: "1",
                activeSector: "",
                activeSector2: "",
                parkBehavior: {
                    // tooltip: {
                    //     trigger: 'item',
                    //     formatter: function (params) {
                    //         var res = params.data.datas[0]+'<br/>';
                    //         return res;
                    //       },
                    //     // show: false,
                    // },
                    series: [
                        {
                            name: "分类",
                            type: "pie",
                            selectedMode: "single",
                            radius: [0, "80%"],
                            center: ["40%", "50%"],
                            label: {
                                normal: {
                                    position: "inner",
                                    textStyle: {
                                        fontSize: 12
                                    }
                                }
                            },
                            labelLine: {
                                normal: {
                                    show: false
                                }
                            },
                            data: [
                                {value: 23, name: "批发和零售业"},
                                {value: 60, name: "制造业", selected: true},
                                {value: 15, name: "建筑业"},
                                {value: 22, name: "采矿业"},
                                {value: 30, name: "其他"}
                            ]
                        },
                        {
                            name: "制造业",
                            type: "pie",
                            radius: ["50%", "65%"],
                            center: ["40%", "50%"],
                            label: {
                                normal: {
                                    formatter: "{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}  {per|{d}%}  ",
                                    backgroundColor: "#eee",
                                    borderColor: "#aaa",
                                    borderWidth: 1,
                                    borderRadius: 4,

                                    rich: {
                                        a: {
                                            color: "#999",
                                            lineHeight: 22,
                                            align: "center"
                                        },

                                        hr: {
                                            borderColor: "#aaa",
                                            width: "100%",
                                            borderWidth: 0.5,
                                            height: 0
                                        },
                                        b: {
                                            fontSize: 14,
                                            lineHeight: 33
                                        },
                                        per: {
                                            color: "#eee",
                                            backgroundColor: "#334455",
                                            padding: [2, 4],
                                            borderRadius: 2
                                        }
                                    }
                                }
                            },
                            data: [
                                // {value:7, name:'谷物磨制'},
                                // {value:3, name:'蔬菜、菌类'},
                                // {value:8, name:'饲料加工'},
                                // {value:10, name:'植物油加'},
                                // {value:1, name:'制糖业'},
                                // {value:5, name:'屠宰及肉'},
                                // {value:6, name:'其他'},
                                // {value:20, name:'水产品'},
                            ]
                        }
                    ]
                },
                parkManPower: {
                    tooltip: {
                        trigger: "item",
                        formatter: "{a} <br/>{b} : {c} ({d}%)"
                    },
                    // grid: {
                    //     left: '8%',
                    //     width: '60%',
                    //     // height: '60%',
                    //     // containLabel: true
                    // },
                    series: [
                        {
                            name: "人力构成",
                            type: "pie",
                            radius: "70%",
                            center: ["35%", "50%"],
                            data: [
                                {value: 55, name: "高中及以下"},
                                {value: 30, name: "本科"},
                                {value: 12, name: "硕士"},
                                {value: 3, name: "博士"}
                            ],
                            itemStyle: {
                                emphasis: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)"
                                }
                            }
                        }
                    ]
                },
                parkDev: {
                    legend: {},
                    tooltip: {},
                    dataset: {
                        source: [
                            ["product", "2016", "2017", "2018"],
                            ["从业人员（千人）", 70, 100, 170],
                            ["园区产值（亿元）", 270, 465, 500]
                        ]
                    },
                    grid: {
                        left: "8%"
                        // width: '60%',
                        // height: '60%',
                        // containLabel: true
                    },
                    xAxis: {type: "category"},
                    yAxis: {
                        type: "value",
                        axisLabel: {
                            formatter: function (v) {
                                return v.toFixed(2);
                            }
                        }
                    },
                    // Declare several bar series, each will be mapped
                    // to a column of dataset.source by default.
                    series: [
                        {type: "bar", barGap: "0%"},
                        {type: "bar"},
                        {type: "bar"}
                    ]
                },
                polar: {
                    xAxis: {
                        type: "category",
                        data: ["2014", "2015", "2016", "2017", "2018"],
                        axisLabel: {
                            fontSize: 10
                        }
                    },
                    yAxis: {
                        type: "value",
                        axisLabel: {
                            formatter: function (v) {
                                return v.toFixed(2);
                            },
                            fontSize: 10
                        },
                        name: "十亿",
                        nameLocation: "end"
                        // nameGap: 50,
                        // nameTextStyle:{
                        //   aling:'left',
                        // }
                    },
                    legend: {
                        // icon: 'rect',

                        data: [{name: "计划金额"}, {name: "实际金额"}],

                        bottom: -5,
                        left: 150
                    },
                    title: {
                        text: "图木舒克市近五年招商引资金额",
                        // left: 'center',
                        textStyle: {
                            fontSize: 20
                        },
                        show: true,
                        left: "14%"
                    },
                    grid: {
                        left: "8%"
                        // width: '60%',
                        // height: '60%',
                        // containLabel: true
                    },
                    series: [
                        {
                            data: [30, 40, 50, 60, 70],
                            type: "line",
                            itemStyle: {normal: {label: {show: true}}},
                            name: "计划金额",
                            label: {
                                align: "left"
                            }
                        },
                        {
                            data: [23.4, 48.4, 59.8, 65.5, 73.4],
                            type: "line",
                            itemStyle: {normal: {label: {show: true}}},
                            name: "实际金额",
                            label: {
                                align: "left"
                            }
                        }
                    ]
                },
                dialogVisible: true,
                checked: false,
                activeClass: -1,
                activeClass2: 1,
                isA1: false,
                isA2: false,
                isA3: false,
                act_index: 1,
                act_btn_index: 0,
                act_bg_index: 0,
                cm_code: ""
            };
        }
    };
</script>

<style scoped lang="scss">
    .bannerBox {
        position: relative;
        width: 100%;
        min-width: 1200px;
        height: 680px;
        margin: 0 auto;
        overflow: hidden;
    }

    .banner {
        width: 1920px;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }
    .mypark{
         /deep/ .el-carousel__container {
                height: 680px !important;
            }
    }
   

    .el .echarts-border {
        width: 100%;
        min-height: 500px;
    }

    .view-park-behavior-detail {
        position: absolute;
        text-align: center;
        margin-top: -90px;
        font-size: 2px;
        /* border-color: #999999; */
        /* border-width:15px; */
        border: 1px solid #aaa;
        padding: 10px;
        border-radius: 5px;
        background: #eee;
    }

    .view-park-behavior {
        position: relative;
    }

    .active_bg_select {
        background: rgba(0, 64, 152, 0.05);
    }

    .active_btn_select {
        /* border:1px solid rgba(237,125,49,1); */
        /* border-radius:5px; */
    }

    .active_select {
        border: solid 1px white !important;
        border-radius: 5px;
        -webkit-transition: border linear 0.2s;
        border-color: rgba(146, 193, 236, 0.75);
        -webkit-box-shadow: 0 0 5px rgba(251, 251, 251, 1);
    }

    .news-detail-right-title {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 20px;
        display: inline-block;
        padding-bottom: 20px;
    }

    .news-detail-right-words {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 14px;
    }

    .news-detail-right-num {
        font-size: 20px;
        font-family: DINPro-Medium, DINPro;
        font-weight: 500;
        color: rgba(250, 100, 0, 1);
        line-height: 20px;
    }

    .news-detail-right-danwei {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(250, 100, 0, 1);
        line-height: 12px;
    }

    .news-detail-right {
        text-align: left;
    }

    .myImportant {
        position: relative;
        cursor: pointer;
    }

    .myImportant div {
        position: absolute;
        bottom: 20px;
        left: 20px;
        color: #fff;
    }

    .news-detail-left {
        padding: 20px;
    }

    .blue-block {
        width: 4px;
        height: 24px;
        background: rgba(0, 64, 152, 1);
    }

    .page-table-div {
        /* background-color: #f9f9fc; */
        padding: 20px;
        margin-bottom: 0px;
        padding-bottom: 0px;
        text-align: left;
    }

    h2 {
        /* color: #1d2088; */
        font-size: 44px;
        font-weight: 600;
    }

    .dialog-nda {
        text-align: left;
        margin-bottom: 20px;
        max-height: 400px;
        overflow-y: auto;
    }

    .pop-up-nda {
        padding-left: 20px;
        padding-right: 20px;
    }

    .hello-text {
        font-size: 24px;
        font-weight: 600;
        padding-bottom: 20px;
    }

    .web-banner {
        /* height: 480px;
        width: 100%; */
    }

    .page-panel-div {
        background-color: #004098;
        padding: 20px;
        margin-bottom: 20px;
        text-align: left;
        color: white;
        margin-top: 20px;
        border-radius: 5px;
    }

    .page-point-div {
        width: 100%;
        /* height:256px; */
        background: rgba(249, 249, 249, 1);
        /* margin-bottom:24px; */
    }

    .details-btn,
    .details-btn:hover {
        background-color: #004098 !important;
        border-color: white !important;
        color: white !important;
        border-radius: 2px;
    }

    .panel-div {
        text-align: center;
        width: 20%;
        padding-top: 30px;
        font-size: 18px;
        font-weight: 500;
        position: relative;
        /* margin-inner:15px; */
    }

    .panel-div-number {
        font-weight: 500;
        font-size: 40px;
    }

    .page-news-div {
        width: 100%;
        /* height:700px; */
    }

    .page-introduce-div {
        width: 100%;
        background: rgba(251, 251, 251, 1);
    }

    .page-recommend-div {
        width: 100%;
        margin-top: 32px;
        padding-bottom: 30px;
    }

    .divider {
        position: absolute;
        right: 0%;
        top: 40%;
        bottom: 20%;
        border-left: 1px solid rgba(255, 255, 255, 0.4);
    }

    .point-pic1 {
        position: relative;
        width: 100%;
        height: 190px;
        background-image: url("../assets/pic/main_dianzi.png");
        background-size: cover;
    }

    .point-pic2 {
        position: relative;
        width: 100%;
        height: 190px;
        background-image: url("../assets/pic/main_yiyao.png");
        background-size: cover;
    }

    .wode-box {
        display: flex;
        align-items: center;
    }

    .wode {
        height: 356px;
        transition: all 0.6s;
        overflow: hidden;
    }

    .wode-box .point-words {
        transition: all 0.6s;
    }

    .wode-box .wode:hover .point-words {
        transform: translate(100px, 120px);
        font-size: 30px;
    }

    .wode-box:hover .wode {
        width: 300px;
    }

    .wode-box .wode:hover {
        width: 632px;
    }

    .wode-box .grid-content {
        transition: all 0.6s;
        position: relative;
        width: 632px;
        height: 356px; 
        background-image: url("../assets/pic/wode_pic1.png");
        background-size: cover;
        cursor: pointer;
    }

    .wode-box .grid-content:hover {
        transform: scale(1.1);
    }

    .wode-box .wode-pic1 {
        position: relative;
        width: 100%;
        height: 377px;
        background-image: url("../assets/pic/wode_pic1.png");
        background-size: cover;
        cursor: pointer;
    }

    .wode-box .wode-pic2 {
        width: 100%;
        height: 377px;
        background-image: url("../assets/pic/tuijian_big2.png");
        background-size: cover;
    }

    .wode-box .wode-pic3 {
        width: 100%;
        height: 377px;
        background-image: url("../assets/pic/tuijian3.png");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .recommend {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .recommend .grid-content {
        width: 204px;
        flex-shrink: 0;
        margin-right: 30px;
        height: 129px;
        border-radius: 3px;
        background-size: cover;
        /*cursor: pointer;*/
        background-image: url("../assets/pic/tuijian_big3.png");
        transition: all 0.6s;
    }

    .recommend .grid-content .point-words {
        transition: all 0.6s;
        padding: 10px;
    }

    .recommend .grid-content:hover .point-words {
        transform: translate(45px, 45px);
        font-size: 20px;
    }

    .recommend .tuijian-pic1 {
        background-image: url("../assets/pic/tuijian_big3.png");
    }

    .recommend .tuijian-pic2 {
        background-image: url("../assets/pic/tuijian_big2.png");
    }

    .recommend .tuijian-pic3 {
        background-image: url("../assets/pic/tuijian3.png");
    }

    .point-pic3 {
        position: relative;
        width: 100%;
        height: 190px;
        background-image: url("../assets/pic/main_shangmao.png");
        background-size: cover;
    }

    .point-pic4 {
        position: relative;
        width: 100%;
        height: 190px;
        background-image: url("../assets/pic/main_zhizao.png");
        background-size: cover;
    }

    .point-words-chanye {
        height: 30px;
        width: 85%;
        bottom: 20px;
        right: 0;

        position: absolute;
    }

    .point-words-chanye2 {
        height: 30px;
        width: 85%;
        bottom: 20px;
        right: 0;

        position: absolute;
    }

    .point-words-chanye p {
        padding: 10px;
        /* padding-top:90px; */
        width: 100%;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgb(0, 0, 0);
        line-height: 18px;
        text-align: left;
        background: white;
        /* height:25px; */
    }

    .point-words-chanye2 p {
        padding: 10px;
        /* padding-top:90px; */
        width: 100%;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 64, 152, 1);
        line-height: 18px;
        text-align: left;
        background: rgba(229, 226, 226, 0.822);
        /* height:25px; */
    }

    .point-words {
        width: 100%;
        /* height:18px; */
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        line-height: 18px;
        text-align: left;
        padding-left: 30px;
        padding-top: 35px;
    }

    .point-top-words p {
        width: 100%;
        /* height:20px; */
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        /* line-height:20px; */
        margin-bottom: 30px;
        text-align: left;
        padding-top: 18px;
        /* padding-bottom:18px; */
    }

    .point-top-right-words {
        width: 100%;
        /* height:14px; */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 14px;
        text-align: right;
        padding-top: 21px;
        padding-bottom: 21px;
    }

    .news-words {
        width: 100%;
        /* height:20px; */
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        line-height: 20px;
        /* padding-left:22px; */
        padding-top: 22px;
        align-items: center;
        display: flex;
    }

    .news-words2 {
        width: 100%;
        /* height:20px; */
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        line-height: 20px;
        /* padding-left:22px; */
        padding-top: 18px;
        align-items: center;
        display: flex;
    }

    .news-words-more {
        width: 100%;
        /* height:14px; */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 14px;
        align-items: center;
        text-align: right;
        padding-right: 18px;
        padding-top: 18px;
    }

    .news-words-more-special {
        width: 100%;
        /* height:14px; */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 14px;
        align-items: center;
        text-align: right;
        padding-right: 30px;
        padding-top: 32px;
    }

    .point-pic-banner {
        padding-top: 0px;
    }

    .page-news-div {
        padding-top: 22px;
    }

    .news-detail-words {
        width: 492px;
        /* height:24px; */
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 24px;
        text-align: left;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .news-detail-words-first-more {
        cursor: pointer;
        width: 95%;
        height: 46px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        margin: 10px;
        background: #f7f7f7;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 18px;
        text-align: left;
        /* margin-left:10px; */
        margin-right: 10px;
        padding: 10px 10px;
    }

    .recommend-words-left {
        text-align: center;
        font-size: 56px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        /* line-height:56px; */
        padding: 145px 0px;
    }

    .pic-block {
        width: 48px;
        height: 4px;
        background: rgba(255, 255, 255, 1);
        text-align: center;
    }

    .btn-div {
        text-align: left;
        margin-bottom: 20px;
    }

    .gailan-btn-selected {
        /* height:44px; */
        background: rgba(0, 64, 152, 1);
        border-radius: 2px;
        padding: 10px 15px;
        margin-left: 5px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 22px;
    }

    .gailan-btn {
        padding: 10px 15px;
        margin-left: 5px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 22px;
    }

    .shangyou-btn {
        text-align: left;
        align-items: left;
    }

    .shangyou-detail {
        width: 100%;
        background: rgba(0, 64, 152, 0.05);
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px;
    }

    .shangyou-detail-btn {
        width: fit-content;
        height: 36px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(0, 64, 152, 1);
        display: inline-block;
        margin-left: 10px;
    }

    .shangyou-detail-btn p {
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 64, 152, 1);
        line-height: 36px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }

    .zhongyou-detail {
        width: 100%;
        background: rgba(237, 125, 49, 0.1);
        border-radius: 2px;
        border: 1px solid rgba(237, 125, 49, 0.1);
        margin-top: 10px;
        padding: 20px;
    }

    .zhongyou-detail-block {
        vertical-align: top; /* here */

        width: fit-content;
        /* height:206px; */
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(237, 125, 49, 1);
        display: inline-block;
        margin-left: 16px;
        text-align: center;
    }

    .zhongyou-detail-block ul {
        list-style: none;
    }

    .xiayou-detail {
        width: 100%;
        background: rgba(224, 32, 32, 0.1);
        border-radius: 2px;
        border: 1px solid rgba(224, 32, 32, 1);
        margin-top: 10px;
        padding: 10px;
    }

    .xiayou-detail-btn {
        width: fit-content;
        height: 36px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(224, 32, 32, 1);
        display: inline-block;
        margin-left: 10px;
        margin-top: 5px;
    }

    .xiayou-detail-btn p {
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(224, 32, 32, 1);
        line-height: 36px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }

    .introduce-detail-div {
        width: 100%;
        height: 650px;
        background: rgba(255, 255, 255, 1);
        border-radius: 2px;
    }

    .bg-chart-tilte-special {
        height: 72px;
        background: rgba(248, 248, 248, 1);
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        text-align: left;
        /* padding: 18px; */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        /* padding-left:20px; */
    }

    .bg-chart-tilte-special2 {
        height: 88px;
        background: rgba(248, 248, 248, 1);
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        text-align: left;
        /* padding: 18px; */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        /* padding-left:20px; */
    }

    .bg-chart-tilte {
        height: 88px;
        background: rgba(248, 248, 248, 1);
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        text-align: left;
        padding: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
    }

    .bg-chart-tilte h1 {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
    }

    .bg-chart-tilte-special h1 {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        /* padding:18px; */
        padding-left: 10px;
        padding-top: 13px;
    }

    .bg-chart-tilte-special2 h1 {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        /* padding:18px; */
        padding-left: 20px;
        padding-top: 13px;
    }

    .echarts {
        width: 100%;
    }

    .tabs-div {
        margin-top: 20px;
    }

    ul {
        margin: 0px;
        padding: 10px;
    }

    .orange-btn {
        background: rgba(237, 125, 49, 1);
        border-radius: 4px;
        color: white;
        border: 1px solid rgba(237, 125, 49, 1);
        font-size: 16px;
    }

    .orange-btn:hover {
        background: rgba(237, 125, 49, 1);
        border-radius: 4px;
        color: white;
        border: 1px solid rgba(237, 125, 49, 1);
        font-size: 16px;
    }

    li {
        margin: 4px;
        /* height:14px; */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(237, 125, 49, 1);
        /* line-height:14px; */
    }

    .hover-div {
        padding-top: 10px;
        margin: 0 20px;
        border: solid 1px #004098;
        cursor: pointer;
    }

    .hover-div:hover {
        border: solid 1px white !important;
        border-radius: 5px;
        -webkit-transition: border linear 0.2s;
        border-color: rgba(146, 193, 236, 0.75);
        -webkit-box-shadow: 0 0 5px rgba(251, 251, 251, 1);
    }

    .hover-div .company_box {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .hover-div .company_box img {
        margin-right: 10px;
    }

    .el-button--primary {
        background-color: #004098 !important;
        border-color: #004098 !important;
        color: white !important;
    }

    .el-button--warning {
        background-color: #ed7d31 !important;
        border-color: #ed7d31 !important;
        color: white !important;
    }

    .div-panel {
        padding: 17px 20px;
        border-radius: 5px;
        cursor: pointer;
    }

    .div-panel:hover {
        background: rgba(0, 64, 152, 0.05);
    }

    .el-divider--horizontal2 {
        display: block;
        height: 1px;
        width: 100%;
        margin: 10px 0;
    }

    .el-tag--dark.el-tag--danger {
        background-color: red;
        border-color: red;
        border-radius: 15px;
        font-weight: bold;
    }

    .el-main {
        background-color: white !important;
    }

    a:hover {
        /* color: white;
         */
        color: inherit;
    }

    .page-img-div {
        height: 480px;
        width: 100%;
        background-image: url("../assets/banner.png");
        background-size: cover;
    }

    .divier-news {
        margin: 15px 0 !important;
    }

    .important {
        transition: all 0.6s;
        cursor: pointer;
    }

    .important:hover .point-words-chanye p {
        /* transform: translate(100px, 120px); */
        opacity: 0.8;
        color: #004098;
    }
</style>
