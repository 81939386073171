<template>
    <el-row>
        <el-row type="flex" justify="center">
            <el-col :span="16">
                <el-card class="me-search-el-card">
                    <div v-if="referer.source=='kid'" v-loading="loading.kidData">
                        <div style="color:black;font-size: 16px;font-weight: bold;">
                            重点产业分类
                        </div>
                        <br/>
                        <el-form :model="form.search" :rules="rules.search" ref="searchForm"
                                 v-loading="loading.searchResult">
                            <el-form-item class="me-search-el-form-item">
                                <span slot="label">&nbsp;&nbsp;&nbsp;&nbsp;重点产业</span>
                                <el-cascader
                                        :options="data.kid"
                                        v-model="form.search.kw"
                                        :props="elCascaderPropsKI"
                                        :show-all-levels="false" @change="handleCascaderKIChange"></el-cascader>
                            </el-form-item>
                        </el-form>
                        <el-divider/>
                    </div>
                    <div v-if="referer.source=='icm'" v-loading="loading.icmData">
                        <div style="color:black;font-size: 16px;font-weight: bold;">
                            产业链节点
                        </div>
                        <br/>
                        <el-form :model="form.search" :rules="rules.search" ref="searchForm"
                                 v-loading="loading.searchResult">
                            <el-form-item class="me-search-el-form-item">
                                <span slot="label">&nbsp;&nbsp;&nbsp;&nbsp;产业链</span>
                                <el-cascader
                                        :options="data.icm"
                                        v-model="form.search.kw"
                                        :props="elCascaderPropsICM"
                                        @active-item-change="handleCascaderICMATitle"
                                        :show-all-levels="false" @change="handleCascaderICMChange">
                                    <template slot-scope="{ node, data }">
                                        <span :style="renderIcmStyle(node,data)">{{ data.name }}</span>
                                        <span v-if="!node.isLeaf" :style="renderIcmStyle(node,data)"> ({{ data.children.length }}) </span>
                                    </template>
                                </el-cascader>
                            </el-form-item>
                        </el-form>
                        <el-divider/>
                    </div>
                    <div v-if="form.search.kw&&referer.source!='kid'">
                        <div style="color:black;font-size: 16px;font-weight: bold;">
                            搜索“<span>{{form.search.kw}}</span>”结果
                        </div>
                        <br/>
                    </div>
                    <el-form :model="form.search" :rules="rules.search" ref="searchForm"
                             v-loading="loading.searchResult">
                        <el-form-item v-if="1==this.form.search.searchType" class="me-search-el-form-item"
                                      label="注册资本">
                            <el-checkbox-group style="float: left;" size="mini"
                                               v-model="form.search.sCapital.notLimited">
                                <el-checkbox-button
                                        @change="(checked)=>handleNotLimitedChange(checked,'sCapital')">
                                    不限
                                </el-checkbox-button>
                            </el-checkbox-group>
                            <el-checkbox-group
                                    style="margin-left: 126px;"
                                    size="mini"
                                    v-model="form.search.sCapital.value">
                                <el-checkbox-button v-for="(rc,index) in dictOptions.dictCapital"
                                                    :label="rc.value"
                                                    @change="(checked)=>{handleCheckboxChange(checked,'sCapital')}"
                                                    :key="index">{{rc.text}}
                                </el-checkbox-button>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item v-if="1==this.form.search.searchType" class="me-search-el-form-item"
                                      label="所属行业">
                            <el-checkbox-group style="float: left;" size="mini"
                                               v-model="form.search.sCat.notLimited">
                                <el-checkbox-button
                                        @change="(checked)=>handleNotLimitedChange(checked,'sCat')">
                                    不限
                                </el-checkbox-button>
                            </el-checkbox-group>
                            <el-checkbox-group
                                    style="margin-left: 126px;"
                                    size="mini"
                                    v-model="form.search.sCat.value">
                                <el-checkbox-button v-for="(rc,index) in dictOptions.dictCat"
                                                    :label="rc.value"
                                                    @change="(checked)=>{handleCheckboxChange(checked,'sCat')}"
                                                    :key="index">{{rc.text}}
                                </el-checkbox-button>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item v-if="1==this.form.search.searchType" class="me-search-el-form-item"
                                      label="所属产业">
                            <el-checkbox-group style="float: left;" size="mini"
                                               v-model="form.search.sCqcat.notLimited">
                                <el-checkbox-button
                                        @change="(checked)=>handleNotLimitedChange(checked,'sCqcat')">
                                    不限
                                </el-checkbox-button>
                            </el-checkbox-group>
                            <el-checkbox-group
                                    style="margin-left: 126px;"
                                    size="mini"
                                    v-model="form.search.sCqcat.value">
                                <el-checkbox-button v-for="(rc,index) in dictOptions.dictCqcat"
                                                    :label="rc.value"
                                                    @change="(checked)=>{handleCheckboxChange(checked,'sCqcat')}"
                                                    :key="index">{{rc.text}}
                                </el-checkbox-button>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item class="me-search-el-form-item">
                            <span slot="label">地域-省份</span>
                            <!--                            <el-cascader-panel-->
                            <!--                                    ref="elCascaderArea"-->
                            <!--                                    :options="dictOptions.dictProvince"-->
                            <!--                                    :props="elCascaderPropsArea"-->
                            <!--                                    :collapse-tags="true" @change="handleCascaderAreaChange"></el-cascader-panel>-->
                            <el-checkbox-group style="float: left;" size="mini"
                                               v-model="form.search.sProvince.notLimited">
                                <el-checkbox-button
                                        @change="(checked)=>{handleNotLimitedChange(checked,'sProvince')}">
                                    不限
                                </el-checkbox-button>
                            </el-checkbox-group>
                            <el-checkbox-group
                                    style="margin-left: 126px;"
                                    size="mini"
                                    v-model="form.search.sProvince.value">
                                <el-checkbox-button v-for="(rc,index) in dictOptions.dictProvince"
                                                    :label="rc.value"
                                                    @change="(checked)=>{handleCheckboxChange(checked,'sProvince',rc)}"
                                                    :key="index">{{rc.text}}
                                </el-checkbox-button>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item class="me-search-el-form-item"
                                      v-if="dictOptions.dictCity&&dictOptions.dictCity.length>0">
                            <span slot="label">地域-城市</span>
                            <el-checkbox-group style="float: left;" size="mini"
                                               v-model="form.search.sCity.notLimited">
                                <el-checkbox-button
                                        @change="(checked)=>handleNotLimitedChange(checked,'sCity')">
                                    不限
                                </el-checkbox-button>
                            </el-checkbox-group>
                            <el-checkbox-group
                                    style="margin-left: 126px;"
                                    size="mini"
                                    v-model="form.search.sCity.value">
                                <el-checkbox-button v-for="(rc,index) in dictOptions.dictCity"
                                                    :label="rc.value"
                                                    @change="(checked)=>{handleCheckboxChange(checked,'sCity')}"
                                                    :key="index">{{rc.text}}
                                </el-checkbox-button>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>
        <el-row type="flex" justify="center">
            <el-col :span="16">
                <el-card class="me-search-result-el-card" v-loading="loading.searchResult">
                    <el-row>
                        <el-col :span="20">
                            共有 {{pagination.total}} 家企业
                        </el-col>
                        <!--                        <el-col :span="4" style="text-align: center;">-->
                        <!--                            <el-link :underline="false">匹配度排序<i class="el-icon-sort"></i></el-link>-->
                        <!--                            <el-link :underline="false">注册资金排序<i class="el-icon-sort"></i></el-link>-->
                        <!--                        </el-col>-->
                    </el-row>
                    <el-divider/>
                    <el-row v-if="pagination.total>0">
                        <el-col :xs="24" v-for="com in data.result"
                                style="padding: 5px;" :key="com.id">
                            <el-card shadow="never" class="me-search-result-item-el-card">
                                <div style="float:left;width: 70px;margin-top: 6px;line-height: 70px;">
                                    <img class="logo" :src="com.logo?com.logo:require('@/assets/pic/defalut_building.png')" style="vertical-align: middle;"/>
                                </div>
                                <div style="width: calc(100% - 85px);margin-left: 85px;">
                                    <el-row>
                                        <el-col :span="20">
                                            <el-link :underline="false" @click="gotoDetal(com)"
                                                     style="font-weight: bold;font-size: 16px;margin-bottom: 3px;">
                                                {{com.name}}
                                            </el-link>
                                            <el-tooltip>
                                                <div slot="content">{{com.regLocation}}</div>
                                                <div style="color: #aaa;font-size: 13px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                                                    {{com.regLocation}}
                                                </div>
                                            </el-tooltip>
                                            <div style="font-size: 14px;margin-top:15px;" v-if="com.bsData">
                                                <el-button v-for="tag in com.bsData.tags" :key="tag" type="info" size="mini"
                                                           style="cursor:default;" plain
                                                           round disabled>
                                                    {{tag}}
                                                </el-button>
                                                <el-button v-if="com.bsData.domain"   type="info" size="mini"
                                                           style="cursor:default;" plain
                                                           round disabled>
                                                    {{com.bsData.domain}}
                                                </el-button>
                                                <el-button v-if="com.bsData.status"   type="info" size="mini"
                                                           style="cursor:default;" plain
                                                           round disabled>
                                                    {{com.bsData.status}}
                                                </el-button>
                                                
                                            </div>
                                        </el-col>
                                        <el-col :span="4" style="text-align: center;padding: 30px 0px;">
                                            <span style="font-size: 18px;font-weight: bold;">
                                                <span v-if="com.sp">{{new Number(com.sp*100*0.5).toFixed(0)+'%'}}</span>
                                                <span v-else> - </span>
                                            </span>
                                            <el-divider direction="vertical"></el-divider>
                                            <span v-if="com.cap&&com.cap!='-'">
                                                <span style="font-size: 18px;font-weight: bold;">{{/\d+(\.\d+)?/.exec(com.cap)[0]}}</span>
                                            <span style="font-size: 11px;">{{com.cap.replace(/\d+(\.\d+)?/g,'')}}</span>
                                            </span>
                                            <span v-else> - </span>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row v-else>
                        <el-col :span="24"><h3 style="text-align: center">无结果</h3></el-col>
                    </el-row>
                    <el-divider v-if="pagination.total>0"/>
                    <el-pagination v-if="pagination.total>0" v-bind="pagination"
                                   @size-change="handlePaginationSizeChange"
                                   @current-change="handlePaginationCurrentChange"
                                   class="me-search-result-el-pagination">
                    </el-pagination>
                </el-card>
            </el-col>
        </el-row>
    </el-row>
</template>
<script>
    import City from "@/components/dict/city"
    import {initDictOptions, filterDictText} from '@/components/dict/JDictSelectUtil'
    import {engineResult, allIndustryChainDetail, industryChainMapResultData, keyIndustryData} from "../api/api";

    let base64 = require('js-base64').Base64
    
    export default {
        components: {},
        data() {
            return {
                loading: {
                    icmData: false,
                    kidData: false,
                    searchResult: false
                },
                referer: {
                    source: ''
                },
                elCascaderPropsArea: {
                    emitPath: true,
                    checkStrictly: false,
                    // expandTrigger: 'hover',
                    label: 'text',
                    value: 'text',
                    children: 'citys',
                    multiple: true
                    // lazy: true,
                    // lazyLoad: (node, resolve) => {
                    //     // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                    //     if (node.root) {
                    //         //异步加载数据
                    //
                    //
                    //     } else {
                    //         resolve([]);
                    //     }
                    // }
                },
                elCascaderPropsICM: {
                    emitPath: false,
                    checkStrictly: true,
                    expandTrigger: 'hover',
                    label: 'name',
                    value: 'name'
                    // lazy: true,
                    // lazyLoad: (node, resolve) => {
                    //     // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                    //     if (node.root) {
                    //         //异步加载数据
                    //
                    //
                    //     } else {
                    //         resolve([]);
                    //     }
                    // }
                },
                elCascaderPropsKI: {
                    emitPath: false,
                    checkStrictly: true,
                    expandTrigger: 'hover',
                    label: 'name',
                    value: 'value'
                    // lazy: true,
                    // lazyLoad: (node, resolve) => {
                    //     // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                    //     if (node.root) {
                    //         //异步加载数据
                    //
                    //
                    //     } else {
                    //         resolve([]);
                    //     }
                    // }
                },
                form: {
                    search: {
                        title:'',
                        kw: '',
                        cqcat: '',
                        sArea: '',
                        sCapital: {
                            notLimited: true,
                            value: []
                        },
                        sCat: {
                            notLimited: true,
                            value: []
                        },
                        sCqcat: {
                            notLimited: true,
                            value: []
                        },
                        sProvince: {
                            notLimited: true,
                            value: []
                        },
                        sCity: {
                            notLimited: true,
                            value: []
                        }
                    }
                },
                rules: {
                    search: {
                        searchKeyWord: [{required: true, message: '请输入搜索关键词!', trigger: 'blur'}]
                    }
                },
                data: {
                    icm: [],
                    kid: [],
                    result: {}
                },
                pagination: {
                    background: true,
                    layout: "prev, pager, next",
                    //layout: "sizes, prev, pager, next",
                    pageSizes: [10, 20, 50, 100],
                    pageSize: 20,
                    pagerCount: 5,
                    currentPage: 1,
                    total: 0
                },
                dictOptions: {
                    dictCapital: [],
                    dictCat: [],
                    dictCqcat: [],
                    dictProvince: [],
                    dictCity: []
                },
                title:''
            }
        },
        created() {
            Object.assign(this.referer, this.$route.query)
            Object.assign(this.form.search, this.$route.query)
            this.initDictConfig()
            if (this.form.search.province) {
                this.form.search.sProvince.notLimited = false
                this.form.search.sProvince.value = [this.form.search.province]
                let province = this.dictOptions.dictProvince.filter(p => p.value === this.form.search.province)
                if (province.length > 0 && province[0].citys.length > 0) {
                    this.dictOptions.dictCity = province[0].citys
                }
            }
            if (this.form.search.city) {
                this.form.search.sCity.notLimited = false
                this.form.search.sCity.value = [this.form.search.city]
            }
            if (this.form.search.cqcat && '' != this.form.search.cqcat) {
                this.form.search.sCqcat.notLimited = false
                this.form.search.sCqcat.value = [this.form.search.cqcat]
            }
            if (this.referer.source != '') {
                switch (this.referer.source) {
                    case 'icm':
                        this.loadIcmData()
                        // this.form.search.kwArr = [this.form.search.kw]
                        break
                    case 'kid':
                        this.loadKIData()
                        this.form.search.kw = base64.decode(this.form.search.kw)
                        break
                    // case 'cqcat':
                    //     if (this.form.search.cqcat && '' != this.form.search.cqcat) {
                    //         this.form.search.sCqcat.notLimited = false
                    //         this.form.search.sCqcat.value = [this.form.search.cqcat]
                    //     }
                    //     break
                }
            }
            this.loadSearchResult()
        },
        methods: {
            renderIcmStyle(node, data) { 
                if (node.level === 3 || node.level === 4) {
                    return {color: data.isHigh ? '' : '#ccc'}
                }
                return {}
            },
            getRandomDefLogo() {
                let ran = Math.ceil(Math.random() * 15).toString(16)
                return require(`../assets/pic/deflogo${ran}.png`)
            },
            gotoDetal(info) {
                // let routeUrl = this.$router.resolve({
                //     path: "/merchantsEngineDetail/" + id
                // });
                // window.open(routeUrl.href, '_blank');
                // this.$router.push({
                //     path: "/merchantsEngineDetail/" + info.id,
                //     query: {
                //         sp: info.sp
                //     }
                // })
                let routeUrl = this.$router.resolve({
                    path: "/merchantsEngineDetail/" + info.id,
                    query: {
                        sp: new Number(info.sp)*0.5
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            loadSearchResult() {
                let that = this
                that.loading.searchResult = true
                let params = {
                    kw: that.form.search.kw,
                    //kw: that.form.search.title+that.form.search.kw,
                    searchType: that.form.search.searchType,
                    appendEsData: true,
                    from: (that.pagination.currentPage - 1) * that.pagination.pageSize,
                    size: that.pagination.pageSize
                }
              
                if (that.form.search.sCat && that.form.search.sCat.value.length > 0) {
                    params.cat = that.form.search.sCat.value
                } else {
                    delete params['cat']
                }
                if (that.form.search.sCqcat && that.form.search.sCqcat.value.length > 0) {
                    params.cqcat = that.form.search.sCqcat.value
                } else {
                    delete params['cqcat']
                }
                if (that.form.search.sCapital && that.form.search.sCapital.value.length > 0) {
                    params.capital = that.form.search.sCapital.value
                } else {
                    delete params['capital']
                }
                if (that.form.search.sProvince && that.form.search.sProvince.value.length > 0) {
                    params.province = that.form.search.sProvince.value
                } else {
                    delete params['province']
                }

                if (that.form.search.sCity && that.form.search.sCity.value.length > 0) {
                    params.city = that.form.search.sCity.value
                } else {
                    delete params['city']
                }
                if ('icm' === that.form.search.source) {
                    industryChainMapResultData(params).then((res) => {
                        that.loading.searchResult = false
                        if (res.success) {
                            that.data.result = res.data
                            that.pagination.total = res.count
                        } else {

                        }
                    }).catch(() => {
                        that.loading.searchResult = false
                    })
                } else {
                    engineResult(params).then((res) => {
                        that.loading.searchResult = false
                        if (res.success) {
                            that.data.result = res.data
                            that.pagination.total = res.count
                        } else {

                        }
                    }).catch(() => {
                        that.loading.searchResult = false
                    })
                }
            },
            initDictConfig() {

                initDictOptions('zs_condition_registered_capital').then((res) => {
                    if (res.success) {
                        this.$set(this.dictOptions, 'dictCapital', res.result)
                    }
                })
                initDictOptions('zs_condition_registered_cat').then((res) => {
                    if (res.success) {
                        this.$set(this.dictOptions, 'dictCat', res.result)
                    }
                })

                initDictOptions('zs_condition_cqcat').then((res) => {
                    if (res.success) {
                        this.$set(this.dictOptions, 'dictCqcat', res.result)
                    }
                })
                //序列化省份
                for (let pkey in City.province_list) {
                    let dictProvince = {
                        key: pkey,
                        value: City.province_list[pkey],
                        text: City.province_list[pkey],
                        title: City.province_list[pkey],
                        citys: []
                    }
                    for (let ckey in City.city_list) {
                        if (ckey.startsWith(pkey.substr(0, 2))) {
                            dictProvince.citys.push({
                                key: ckey,
                                value: City.city_list[ckey],
                                text: City.city_list[ckey],
                                title: City.city_list[ckey],
                            })
                        }
                    }
                    this.dictOptions.dictProvince.push(dictProvince)
                }
            },
            loadIcmData() {
                let params = {}
                this.loading.icmData = true
                allIndustryChainDetail().then(res => {
                    this.loading.icmData = false 
                    this.data.icm = res.result;
                }).catch(() => {
                    this.loading.icmData = false
                })
            },
            loadKIData() {
                this.loading.kidData = true
                keyIndustryData().then((res) => {
                    this.loading.kidData = false
                    if (res.success) {
                         this.data.kid = res.result.map(ki => {
                            ki.value =  ki.industryName
                            ki.name = ki.industryName
                            // if (ki.children) {
                            //     ki.children.forEach(cki => {
                            //         cki.value = cki.search ? cki.search : cki.name
                            //     })
                            // }
                            return ki
                        })
                    }
                }).catch(() => {
                    this.loading.kidData = false
                })
            },
            handleCascaderAreaChange() {
                let nodes = this.$refs.elCascaderArea.getCheckedNodes();
                let area = {}
                nodes && nodes.forEach(node => {
                    if (area[node.path[0]]) {
                        area[node.path[0]].push(node.path[1])
                    } else {
                        if (node.path[1]) {
                            area[node.path[0]] = [node.path[1]]
                        } else {
                            area[node.path[0]] = []
                        }
                    }
                })
                console.log(area)
            },
            handleCascaderICMChange(value) {
                let query = this.$router.history.current.query;
                let path = this.$router.history.current.path;
                //对象的拷贝
                
                let newQuery = JSON.parse(JSON.stringify(query));
                newQuery.kw = this.form.search.kw;
                if(value !== query.kw){
                    this.form.search.title =this.title.substring(0,this.title.length-2);
                    newQuery.title = this.title.substring(0,this.title.length-2);
                }
                this.$router.push({path, query: newQuery});
                this.loadSearchResult()
            },
            handleCascaderKIChange(value) {
                let query = this.$router.history.current.query;
                let path = this.$router.history.current.path;
                //对象的拷贝
                let newQuery = JSON.parse(JSON.stringify(query));
                newQuery.kw = base64.encode(this.form.search.kw);
                this.$router.push({path, query: newQuery});
                this.loadSearchResult()
            },
            handleNotLimitedChange(checked, formItemName) {
                if (checked) {
                    this.form.search[formItemName].value = []
                } else {
                    this.form.search[formItemName].notLimited = true
                }
                if (checked && 'sProvince' === formItemName) {
                    this.dictOptions.dictCity = []
                    this.form.search.sCity.notLimited = false
                    this.form.search.sCity.value = []
                }
                this.loadSearchResult()
            },
            handleCheckboxChange(checked, formItemName, param) {

                if (checked) {
                    this.form.search[formItemName].notLimited = false
                } else {
                    if (this.form.search[formItemName].value.length == 0) {
                        this.form.search[formItemName].notLimited = true
                        if ('sProvince' === formItemName) {
                            this.dictOptions.dictCity = []
                            this.form.search.sCity.notLimited = true
                            this.form.search.sCity.value = []
                        }
                    }
                }

                if ('sProvince' === formItemName) {
                    if (param) {
                        this.dictOptions.dictCity = param.citys
                    } else {
                        // this.dictOptions.dictCity = []
                        // this.form.search.sCity.notLimited = false
                        // this.form.search.sCity.value = []
                    }
                }

                this.loadSearchResult()
            },
            handlePaginationSizeChange(pageSize) {
                this.pagination.pageSize = pageSize
                this.loadSearchResult()
            },
            handlePaginationCurrentChange(currentPage) {
                this.pagination.currentPage = currentPage
                this.loadSearchResult()
            },
            handleCascaderICMATitle(value){
                this.title = value[0];
            },
            handleSearchSubmit(formName) {
                let that = this
                that.$refs[formName].validate((valid) => {
                    if (valid) {
                        console.log(this.form.search)
                        // that.loading.searchResult = true
                        // setTimeout(() => {
                        //     // that.loading.searchResult = false
                        //     // alert('submit!');
                        // }, 3000)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    }

</script>


<style scoped  >
    * {
        text-align: initial;
    }

    .me-search-el-form-item {
        margin: 5px 0px;
    }

    .me-search-el-card {
        margin: 30px 0px;
    }

    .me-search-result-el-card {
        margin: 20px 0px;
    }

    .me-search-result-item-el-card {
        border: 0px;
    }

    .me-search-result-item-el-card:hover {
        /*border: 1px solid rgba(0, 64, 152, 0.0862745098039216);*/
        background-color: rgba(0, 64, 152, 0.1);
    }

    .me-search-result-el-pagination {

    } 
    .logo{
        width:70px;
        max-height:70px;
    }
</style>
