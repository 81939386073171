<template>
    <div class="yish-background" style="min-width:1250px;min-height:880px;position: relative;padding-bottom:45px;">
        <!-- 欢迎 -->

        <el-row>
            <el-col :span="14">
                <div class="grid-content bg-padding">
                    <div class="page-table-div">
                        <h2 style="font-size: 64px;">大数据招商系统</h2>
                        <div class="hello-text">园区精准招商工具</div>
                        <div class="content-text">通过人工智能+大数据分析产业链，精准绘制招商地图，提高招商效率。<br> 针对政府和园区提供信息化，智能化，专业化的招商综合解决方案。
                        </div>

                    </div>
                </div>
            </el-col>
            <el-col :span="8" style="min-width:520px;float:right">
                <div class="grid-content bg-padding">
                    <div class="login-form" style="min-width:430px">
                        <h3 style="margin-bottom:50px;font-size:30px">系统登录</h3>
                        <!-- :rules="rules"  -->
                        <el-form label-position="top" status-icon ref="ruleForm" label-width="120px"
                                 class="demo-ruleForm"
                                 :model="loginParams">
                            <el-form-item label="用户名" prop="userName">
                                <el-input v-model="loginParams.username" type="" placeholder="请输入用户名"
                                          autocomplete="off">
                                </el-input>
                            </el-form-item>
                            <el-form-item label="密码" prop="password">
                                <el-input v-model="loginParams.password" type="password" placeholder="请输入密码"
                                          autocomplete="off">
                                </el-input>
                            </el-form-item>

                            <el-form-item>
                                <el-button class="btn-invest" type="primary" @click="submitForm()"><a>登录</a>
                                </el-button>
                                <!-- <el-button class="btn-invest-cancel" @click="resetForm('ruleForm')">密码登录</el-button> -->
                            </el-form-item>
                        </el-form>
                        <div class="agreement-div">
                            登录注册即表明您已经阅读并同意
                            <span class="agreement-div"
                                  style="text-decoration: underline; color: #004098;cursor: pointer;" @click="touserAgreement">《个人注册服务协议》</span>

                            以及
                            <span class="agreement-div"
                                  style="text-decoration: underline; color: #004098;cursor: pointer;" @click="toprivacyAgreement">《隐私权协议》</span>

                        </div>
                        <div class="agreement-div"><img src="../assets/pic/logo.png" alt="" class="card-pic">
                        </div>

                    </div>
                </div>
            </el-col>
        </el-row>

        <!-- 欢迎大图 -->
        <footer style="position: absolute; bottom: 15px; width: 100%; text-align: center; font-size: small; background-color: rgba(0, 0, 0, 0.03);color: white;">
           <div class="footer-text">
                        版权所有 © 2020 上海形而上科技有限公司 <a href="//www.beian.miit.gov.cn" target="_blank">沪ICP备20002928号-1</a><img style="    padding-left: 20px;
    height: 12px;
    padding-right: 5px;" src="../assets/police_record.png" /><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202012447">沪公网安备31011202012447</a>
                    </div>
        </footer>
    </div>
</template>

<script>
    import {Message} from "element-ui";
    import {setToken} from "../util/auth";
    import {loadDepartMenu} from "../api/api";

    export default {
        name: "base_page",
        data() {
            return {
                loginParams: {
                    username: "",
                    password: ""
                },
                data: "",
                message: "",
                arr:['myPark','zhaoshang','merchantsEngine','industryChainMap','projectLibrary','guanjia']
            };
        },
        components: {},
        methods: {
            touserAgreement(){
                this.$router.push('/userAgreement')
            },
            toprivacyAgreement(){
                this.$router.push('/privacyAgreement')
            },
            
            // submitForm() {
            //   this.$router.push({ path: "/myPark" }); // -> /user/123

            // },
            goToPage(){
                let that=this;
                loadDepartMenu({}).then(res=>{
                    if(200 == res.code){
                        let permiss=res.result.codeDict;
                        let arr=that.arr;
                        for(let i of arr){
                            if(permiss&&permiss[i]){
                                this.$router.push({path: "/"+i});
                                return
                            }
                        }
                        this.$router.push({path: "/myPark"});
                    }
                })

            },
            submitForm() {
                if (
                    this.loginParams.username.length &&
                    this.loginParams.password.length
                ) {
                    this.$api
                        .login(this.loginParams)
                        .then(res => {
                            if (res.success) { 
                                this.$store.dispatch('setInfo',res.result)
                                setToken(res.result.token)
                                Message({
                                    title: "提示",
                                    message: "登录成功",
                                    type: "success",
                                    duration: 1500
                                });
                                this.goToPage();

                            } else {
                                Message({
                                    title: "提示",
                                    message: res.message,
                                    type: "error",
                                    duration: 1500
                                });
                            }
                            // this.setUser(this.loginParams.phone)
                            // sessionStorage.setItem("username",this.loginParams.phone)
                            // this.setIsLogin(true)
                            // this.setLogin(false)
                        })
                        .catch(err => {
                            console.log(err);
                            Message({
                                title: "提示",
                                message: err,
                                type: "error",
                                duration: 1500
                            });

                            this.message = err;
                        });
                } else {
                    Message({
                        title: "提示",
                        message: "请填写正确的用户名和密码！",
                        type: "error",
                        duration: 1500
                    });
                }
            },
            gotoRow(index, data) {
                console.log(data);
                this.$router.push({path: `/projects/${data[index].id}`}); // -> /user/123
            },
            visible(i) {
                this["seen" + i] = true;
            },
            invisible(i) {
                this["seen" + i] = false;
            },
            say(msg) {
                alert(msg);
            }
        },
        created() {
            var _self = this;

            document.onkeydown = function (e) {
                var key = window.event.keyCode;

                if ((key == 13) && _self.$route.path == "/login") {
                    _self.submitForm();
                }
            };
        }
    };
</script>

<style scoped>
    .yish-background {
        background-image: url("../assets/bg.png");
        background-repeat: no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 100%;
    }

    .page-table-div {
        /* background-color: #f9f9fc; */
        padding: 20px;
        margin-bottom: 20px;
        margin-left: 120px;
        text-align: left;
    }

    h2 {
        /* color: #1d2088; */
        color: white;

        font-size: 44px;
        font-weight: 600;
    }

    .dialog-nda {
        text-align: left;
        margin-bottom: 20px;
        max-height: 400px;
        overflow-y: auto;
    }

    .pop-up-nda {
        padding-left: 20px;
        padding-right: 20px;
    }

    .hello-text {
        font-size: 32px;
        font-weight: 400;
        color: white;
    }

    .web-banner {
        height: 480px;
        width: 100%;
    }

    .page-panel-div {
        background-color: #004098;
        padding: 20px;
        margin-bottom: 20px;
        text-align: left;
        color: white;
        margin-top: 20px;
        border-radius: 5px;
    }

    .page-point-div {
        width: 100%;
        height: 256px;
        background: rgba(249, 249, 249, 1);
    }

    .details-btn,
    .details-btn:hover {
        background-color: #004098 !important;
        border-color: white !important;
        color: white !important;
        border-radius: 2px;
    }

    .panel-div {
        text-align: center;
        width: 20%;
        padding-top: 20px;
        font-size: 18px;
        font-weight: 500;
        position: relative;
    }

    .panel-div-number {
        font-weight: 500;
        font-size: 40px;
    }

    .page-news-div {
        width: 100%;
        /* height:700px; */
    }

    .page-introduce-div {
        width: 100%;
        background: rgba(251, 251, 251, 1);
    }

    .page-recommend-div {
        width: 100%;
        height: 450px;
        margin-top: 32px;
    }

    .divider {
        position: absolute;
        right: 0%;
        top: 40%;
        bottom: 20%;
        border-left: 1px solid rgba(255, 255, 255, 0.4);
    }

    .point-pic1 {
        width: 100%;
        height: 140px;
        background-image: url("../assets/pic/main_dianzi.png");
        background-size: cover;
    }

    .point-pic2 {
        width: 100%;
        height: 140px;
        background-image: url("../assets/pic/main_yiyao.png");
        background-size: cover;
    }

    .tuijian-pic1 {
        width: 100%;
        height: 377px;
        background-image: url("../assets/pic/tuijian_big3.png");
        background-size: cover;
    }

    .tuijian-pic2 {
        width: 100%;
        height: 377px;
        background-image: url("../assets/pic/tuijian_big2.png");
        background-size: cover;
    }

    .tuijian-pic3 {
        width: 100%;
        height: 377px;
        background-image: url("../assets/pic/tuijian3.png");
        background-repeat: no-repeat;
        background-size: cover;
    }

    .point-pic3 {
        width: 100%;
        height: 140px;
        background-image: url("../assets/pic/main_shangmao.png");
        background-size: cover;
    }

    .point-pic4 {
        width: 100%;
        height: 140px;
        background-image: url("../assets/pic/main_zhizao.png");
        background-size: cover;
    }

    .point-words {
        width: 100%;
        /* height:18px; */
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        line-height: 18px;
        padding-right: 200px;
        padding-top: 100px;
    }

    .point-top-words p {
        width: 100%;
        /* height:20px; */
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        line-height: 20px;
        text-align: left;
        padding-top: 18px;
        padding-bottom: 18px;
    }

    .point-top-right-words {
        width: 100%;
        /* height:14px; */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 14px;
        text-align: right;
        padding-top: 21px;
        padding-bottom: 21px;
    }

    .news-words {
        width: 100%;
        /* height:20px; */
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
        line-height: 20px;
        padding-left: 22px;
        padding-top: 18px;
        align-items: center;
    }

    .news-words-more {
        width: 100%;
        /* height:14px; */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 14px;
        align-items: center;
        text-align: right;
        padding-right: 18px;
        padding-top: 18px;
    }

    .point-pic-banner {
        padding-top: 20px;
    }

    .page-news-div {
        padding-top: 92px;
    }

    .news-detail-words {
        width: 492px;
        /* height:24px; */
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 24px;
        text-align: left;
    }

    .news-detail-words-first-more {
        width: 100%;
        /* height:72px; */
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 18px;
        text-align: left;
        padding-top: 10px;
    }

    .recommend-words-left p {
        margin: 0 auto;
        width: 280px;
        height: 56px;
        font-size: 56px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        line-height: 56px;
    }

    .btn-div {
        text-align: left;
        margin-bottom: 20px;
    }

    .gailan-btn {
        text-align: left;
    }

    .shangyou-btn {
        text-align: left;
        align-items: left;
    }

    .shangyou-detail {
        width: 100%;
        background: rgba(0, 64, 152, 0.05);
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px;
    }

    .shangyou-detail-btn {
        width: fit-content;
        height: 36px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(0, 64, 152, 1);
        display: inline-block;
        margin-left: 10px;
    }

    .shangyou-detail-btn p {
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 64, 152, 1);
        line-height: 36px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }

    .zhongyou-detail {
        width: 100%;
        background: rgba(237, 125, 49, 0.1);
        border-radius: 2px;
        border: 1px solid rgba(237, 125, 49, 0.1);
        margin-top: 10px;
        padding: 20px;
    }

    .zhongyou-detail-block {
        width: fit-content;
        /* height:206px; */
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(237, 125, 49, 1);
        display: inline-block;
        margin-left: 16px;
        text-align: center;
    }

    .zhongyou-detail-block ul {
        list-style: none;
    }

    .xiayou-detail {
        width: 100%;
        background: rgba(224, 32, 32, 0.1);
        border-radius: 2px;
        border: 1px solid rgba(224, 32, 32, 1);
        margin-top: 10px;
        padding: 10px;
    }

    .xiayou-detail-btn {
        width: fit-content;
        height: 36px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(224, 32, 32, 1);
        display: inline-block;
        margin-left: 10px;
        margin-top: 5px;
    }

    .xiayou-detail-btn p {
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(224, 32, 32, 1);
        line-height: 36px;
        padding-left: 15px;
        padding-right: 15px;
        text-align: center;
    }

    .introduce-detail-div {
        width: 100%;
        height: 650px;
        background: rgba(255, 255, 255, 1);
        border-radius: 2px;
    }

    .bg-chart-tilte {
        height: 88px;
        background: rgba(248, 248, 248, 1);
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        text-align: left;
        padding: 18px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
    }

    .bg-chart-tilte h1 {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
    }

    .echarts {
        width: 100%;
        height: 460px;
    }

    .bg-padding {
        padding-top: 120px;
    }

    .login-form {
        border-radius: 15px;
        background-color: white;
        margin-right: 90px;
        padding: 30px;
        min-height: 700px;
        text-align: left;
        max-width: 510px;
    }

    .btn-invest {
        width: 100%;
        height: 64px;
        background: rgba(0, 64, 152, 1);
        border-radius: 4px;
        margin-bottom: 10px;
    }

    .btn-invest,
    .btn-invest:hover {
        width: 100%;
        height: 64px;
        background: rgba(0, 64, 152, 1);
        border-radius: 4px;
        margin-bottom: 10px;
        border-color: rgba(0, 64, 152, 1) !important;
        font-size: 16px;
    }

    .btn-invest-cancel,
    .btn-invest-cancel:hover {
        width: 100%;
        height: 64px;
        background: white;
        border-color: #004098 !important;
        color: #004098 !important;
        font-size: 16px;

        border-radius: 4px;
        margin-bottom: 10px;
    }

    .el-button + .el-button {
        margin-left: 0px;
    }

    .content-text {
        margin-top: 70px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
    }

    .agreement-div {
        text-align: center;
        font-size: 12px;
        font-weight: 400;
    }

    .card-pic {
        width: 180px;
        height: 55px;
        margin-top: 90px;
    }
</style>
