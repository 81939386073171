<template>
    <el-row>
        <el-col>
            <el-row type="flex" class="top-cont" justify="center" style="color: white">
                <el-col :offset="6" :span="18">

                    <table style="width:100%;height:200px;margin-top:50px;color:white;">
                        <tr>
                          
                            <td colspan="3" style="font-size: 25px;font-weight: bold;">{{data.companyName}}</td>
                            <td style="text-align: right;padding-right: 50px">
                                <el-button v-if="!data.collectData.status" v-loading="loading.changeCollectStatus"
                                           type="info" plain class="custom-el-button" @click="handleCommand(1)">关注
                                </el-button>
                                <el-dropdown v-if="data.collectData.status" v-loading="loading.changeCollectStatus"
                                             @command="handleCommand">
                                    <el-button type="primary">
                                        {{filterDictText(dictOptions.dictCompanyCollectStatus,data.collectData.status)}}<i
                                            class="el-icon-arrow-down el-icon--right"></i>
                                    </el-button>
                                    <el-dropdown-menu slot="dropdown">
                                        <div v-for="(dict,index) in dictOptions.dictCompanyCollectStatus"
                                             :key="index">
                                            <el-dropdown-item v-if="dict.value!=data.collectData.status"
                                                              :command="dict.value">{{dict.text}}
                                            </el-dropdown-item>
                                        </div>
                                    </el-dropdown-menu>
                                </el-dropdown>

                            </td>
                        </tr>
                        <tr>
                            <td width="100" height="30">联系电话</td>
                            <td width="85" rowspan="2" style="text-align: center">
                                <el-divider direction="vertical" class="custom-el-divider--vertical"/>
                            </td>
                            <td height="30">企业法人</td>
                            <td></td>
                        </tr>
                        <tr style="color: #e4e7ed;">
                            <td><span
                                    v-if="data.esData.contactNumber&&data.esData.contactNumber.length>0">{{data.esData.contactNumber[0]}}</span>
                            </td>
                            <td><span
                                    v-if="data.bigData.subBaseinfo&&data.bigData.subBaseinfo.length>0">{{data.bigData.subBaseinfo[0].legalPersonName}}</span>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="4">
                                <el-button type="danger" style="background-color: #FA6400"
                                           @click="$router.push({path:'/guanjia'})">联系不上?试试招商管家!
                                </el-button>
                                  <el-button type="primary" 
                                        v-if="false"
                                           @click="openReport">助谈报告
                                </el-button>
                            </td>
                             
                        </tr>
                        <tr>
                            <td colspan="3">
                                <el-button v-for="tag in data.bsData.tags" :key="tag" type="info" size="mini" class="custom-tag-button"
                                            style="cursor:default;" 
                                            plain
                                            round disabled>
                                    {{tag}}
                                </el-button>
                                <el-button v-if="data.bsData.domain"   type="info" size="mini" class="custom-tag-button"
                                            style="cursor:default;" plain
                                            round disabled>
                                    {{data.bsData.domain}}
                                </el-button>
                                <el-button v-if="data.bsData.status" type="info" size="mini" class="custom-tag-button"
                                           plain
                                           round disabled>
                                    {{data.bsData.status}}
                                </el-button>  
                            </td>
                            <td style="height: 50px;font-size: 18px;font-weight: bold;text-align: right;padding-right: 50px;">
                                <span v-if="data.sp">
                                    匹配度<br/><span>{{new Number(data.sp*100).toFixed(0)+'%'}}</span>
                                </span>
                            </td>
                        </tr>
                    </table>
                </el-col>
            </el-row>
        </el-col>
        <el-col>
            <el-col :offset="4" :span="16">
                <el-container style="margin-bottom: 30px;">
                    <el-main v-loading="loading.moreInfo">
                        <el-card id="jbxx" class="custom-el-card">
                            <div slot="header">
                                <span class="h1_title" style="font-weight: bold;font-size: 20px;">企业基本信息</span>
                            </div>
                            <table>
                                <tr>
                                    <td class="title-blold">成立时间</td>
                                    <td><span v-if="data.bigData.subBaseinfo&&data.bigData.subBaseinfo.length>0">{{data.bigData.subBaseinfo[0].estiblishTime}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="title-blold">企业位置</td>
                                    <td>{{data.esData.regLocation}}</td>
                                </tr>
                                <tr>
                                    <td class="title-blold">企业产品关键词</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td class="title-blold">企业相关产品图谱<br/><span
                                            style="font-size: 14px;font-weight: initial;color: #777">包含其投资与合作方生产的产品</span>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td colspan="2">
                                        暂无数据
                                    </td>
                                </tr>

                            </table>
                        </el-card>
                        <el-card id="fkxx" class="custom-el-card">
                            <div slot="header">
                                <span class="h1_title" style="font-weight: bold;font-size: 20px;">风控信息</span>
                            </div>
                            <el-tabs value="sffx">
                                <el-tab-pane label="司法风险" name="sffx">
                                    <el-card
                                            v-if="data.bigData.subKtannouncement&&data.bigData.subKtannouncement.length>0"
                                            v-for="(kta,index) in data.bigData.subKtannouncement"
                                            :key="index" class="kta-el-card">
                                        <table class="kta-table">
                                            <tr>
                                                <td width="20"><i class="el-icon-user"/></td>
                                                <td class="title-blold">原告</td>
                                                <td width="20"><i class="el-icon-user"/></td>
                                                <td class="title-blold">被告</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td class="text-muted">{{kta.plaintiff}}</td>
                                                <td>&nbsp;</td>
                                                <td class="text-muted">{{kta.defendant}}</td>
                                            </tr>
                                            <tr>
                                                <td><i class="el-icon-timer"/></td>
                                                <td class="title-blold">开庭日期</td>
                                                <td><i class="el-icon-notebook-2"/></td>
                                                <td class="title-blold">案号</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td class="text-muted">
                                                    {{moment(kta.startDate).format('YYYY-MM-DD')}}
                                                </td>
                                                <td>&nbsp;</td>
                                                <td class="text-muted">{{kta.caseNo}}</td>
                                            </tr>
                                            <tr>
                                                <td><i class="el-icon-chat-line-square"/></td>
                                                <td class="title-blold">案由</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td class="text-muted">{{kta.caseReason}}</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </table>
                                    </el-card>
                                    <el-card
                                            v-if="!data.bigData.subKtannouncement||data.bigData.subKtannouncement.length==0">
                                        暂无数据
                                    </el-card>
                                </el-tab-pane>
                                <el-tab-pane label="行政许可" name="xzxk">
                                    <el-card v-if="data.bigData.subGetlicense&&data.bigData.subGetlicense.length>0"
                                             v-for="(license,index) in data.bigData.subGetlicense"
                                             :key="index" class="kta-el-card">
                                        <table class="kta-table">
                                            <tr>
                                                <td><i class="el-icon-user"/></td>
                                                <td class="title-blold">许可证名称</td>
                                                <td><i class="el-icon-user"/></td>
                                                <td class="title-blold">许可书文编号</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td class="text-muted max-width200">{{license.licencename}}</td>
                                                <td>&nbsp;</td>
                                                <td class="text-muted">{{license.licencenumber}}</td>
                                            </tr>
                                            <tr>
                                                <td><i class="el-icon-timer"/></td>
                                                <td class="title-blold">起始日期</td>
                                                <td><i class="el-icon-notebook-2"/></td>
                                                <td class="title-blold">截止日期</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td class="text-muted">
                                                    {{license.fromdate}}
                                                </td>
                                                <td>&nbsp;</td>
                                                <td class="text-muted">
                                                    <span>{{license.todate}}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><i class="el-icon-chat-line-square"/></td>
                                                <td class="title-blold">发证机关</td>
                                                <td>&nbsp;</td>
                                                <td class="title-blold">许可内容</td>
                                            </tr>
                                            <tr>
                                                <td>&nbsp;</td>
                                                <td class="text-muted">{{license.department}}</td>
                                                <td>&nbsp;</td>
                                                <td class="text-muted max-width200">{{license.scope}}</td>
                                            </tr>
                                        </table>
                                    </el-card>
                                    <el-card
                                            v-if="!data.bigData.subGetlicense||data.bigData.subGetlicense.length==0">
                                        暂无数据
                                    </el-card>
                                </el-tab-pane>
                            </el-tabs>
                        </el-card>
                        <el-card id="gsxx" class="custom-el-card">
                            <div slot="header">
                                <span class="h1_title" style="font-weight: bold;font-size: 20px;">工商信息</span>
                            </div>
                            <table v-if="data.bigData.subBaseinfo"
                                   v-for="(baseInfo,index) in data.bigData.subBaseinfo" :key="index"
                                   class="kta-table">
                                <tr>
                                    <td class="title-blold">法定代表人</td>
                                    <td class="title-blold">注册资本</td>
                                </tr>
                                <tr>
                                    <td class="text-muted">{{baseInfo.legalPersonName}}</td>
                                    <td class="text-muted">{{baseInfo.regCapital}}</td>
                                </tr>
                                <tr>
                                    <td class="title-blold">经营状态</td>
                                    <td class="title-blold">实缴资本</td>
                                </tr>
                                <tr>
                                    <td class="text-muted">{{baseInfo.regStatus}}</td>
                                    <td class="text-muted">{{baseInfo.actualCapital}}</td>
                                </tr>


                                <tr>
                                    <td class="title-blold">统-社会信用代码</td>
                                    <td class="title-blold">成立日期</td>
                                </tr>
                                <tr>
                                    <td class="text-muted">{{baseInfo.creditCode}}</td>
                                    <td class="text-muted">{{baseInfo.estiblishTime}}</td>
                                </tr>
                                <tr>
                                    <td class="title-blold">注册号</td>
                                    <td class="title-blold">纳税人识别号</td>
                                </tr>
                                <tr>
                                    <td class="text-muted">{{baseInfo.regNumber}}</td>
                                    <td class="text-muted">{{baseInfo.taxNumber}}</td>
                                </tr>
                                <tr>
                                    <td class="title-blold">企业类型</td>
                                    <td class="title-blold">组织机构代码</td>
                                </tr>
                                <tr>
                                    <td class="text-muted">{{baseInfo.companyOrgType}}</td>
                                    <td class="text-muted">{{baseInfo.orgNumber}}</td>
                                </tr>
                                <tr>
                                    <td class="title-blold">核准日期</td>
                                    <td class="title-blold">所属行业</td>
                                </tr>
                                <tr>
                                    <td class="text-muted">{{baseInfo.approvedTime}}</td>
                                    <td class="text-muted">{{baseInfo.industry}}</td>
                                </tr>
                                <tr>
                                    <td class="title-blold">所属地区</td>
                                    <td class="title-blold">登记机关</td>
                                </tr>
                                <tr>
                                    <td class="text-muted">-</td>
                                    <td class="text-muted">{{baseInfo.regInstitute}}</td>
                                </tr>
                                <tr>
                                    <td class="title-blold">曾用名</td>
                                    <td class="title-blold">英文名</td>
                                </tr>
                                <tr>
                                    <td class="text-muted">{{baseInfo.historyNames}}</td>
                                    <td class="text-muted">{{baseInfo.property3}}</td>
                                </tr>
                                <tr>
                                    <td class="title-blold">人员规模</td>
                                    <td class="title-blold">参保人数</td>
                                </tr>
                                <tr>
                                    <td class="text-muted">{{baseInfo.staffNumRange}}</td>
                                    <td class="text-muted">{{baseInfo.socialStaffNum}}</td>
                                </tr>
                                <tr>
                                    <td class="title-blold">企业地址</td>
                                    <td class="title-blold">营业期限</td>
                                </tr>
                                <tr>
                                    <td class="text-muted">{{baseInfo.regLocation}}</td>
                                    <td class="text-muted">
                                        {{baseInfo.fromTime}} 至 {{baseInfo.toTime}}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="title-blold" colspan="2">经营范围</td>
                                </tr>
                                <tr>
                                    <td class="text-muted" colspan="2">{{baseInfo.businessScope}}</td>
                                </tr>


                            </table>
                            <el-card
                                    v-if="!data.bigData.subBaseinfo">
                                暂无数据
                            </el-card>
                        </el-card>
                        <el-card id="gsbg" class="custom-el-card">
                            <div slot="header">
                                <span class="h1_title" style="font-weight: bold;font-size: 20px;">工商变更</span>
                            </div>
                            <el-table
                                    v-if="data.bigData.subChange"
                                    :data="data.bigData.subChange"
                                    style="width: 100%">
                                <el-table-column
                                        type="index"
                                        width="50">
                                </el-table-column>
                                <el-table-column
                                        prop="changeItem"
                                        label="变更项目">
                                </el-table-column>
                                <el-table-column
                                        label="变更前"
                                        :showOverflowTooltip="false">
                                    <template slot-scope="scope">
                                        <p v-html="replateHtml(scope.row.contentBefore)"></p>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        label="变更后"
                                        :showOverflowTooltip="false">
                                    <template slot-scope="scope">
                                        <p v-html="replateHtml(scope.row.contentAfter)"></p>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        width="200"
                                        prop="changeTime"
                                        label="变更日期">
                                </el-table-column>
                            </el-table>
                            <el-card
                                    v-if="!data.bigData.subChange">
                                暂无数据
                            </el-card>
                        </el-card>
                        <el-card id="rzxx" class="custom-el-card">
                            <div slot="header">
                                <span class="h1_title" style="font-weight: bold;font-size: 20px;">融资信息</span>
                            </div>
                            <el-table
                                    v-if="data.bigData.subRongzi"
                                    :data="data.bigData.subRongzi"
                                    style="width: 100%">
                                <el-table-column
                                        type="index"
                                        width="50">
                                </el-table-column>
                                <el-table-column
                                        prop="organizationName"
                                        label="投资方">
                                </el-table-column>
                                <el-table-column
                                        prop="money"
                                        label="交易金额">
                                </el-table-column>
                                <el-table-column
                                        prop="value"
                                        label="估值">
                                </el-table-column>
                                <el-table-column
                                        prop="share"
                                        label="比例">
                                </el-table-column>
                                <el-table-column
                                        prop="round"
                                        label="融资轮次">
                                </el-table-column>
                                <el-table-column
                                        prop="date"
                                        label="披露日期">
                                </el-table-column>
                                <el-table-column
                                        prop="eventDate"
                                        label="事件日期">
                                </el-table-column>
                            </el-table>
                            <el-card
                                    v-if="!data.bigData.subRongzi">
                                暂无数据
                            </el-card>
                        </el-card>
                        <el-card id="gdxx" class="custom-el-card">
                            <div slot="header">
                                <span class="h1_title" style="font-weight: bold;font-size: 20px;">股东信息</span>
                            </div>
                            <el-table
                                    v-if="data.bigData.subHolder"
                                    :data="data.bigData.subHolder"
                                    style="width: 100%">
                                <el-table-column
                                        type="index"
                                        width="50">
                                </el-table-column>
                                <el-table-column
                                        prop="name"
                                        label="股东">
                                </el-table-column>
                                <el-table-column
                                        width="100"
                                        prop="percent"
                                        label="出资比例">
                                </el-table-column>
                                <el-table-column
                                        width="200"
                                        prop="amomon"
                                        label="认缴出资额">
                                </el-table-column>
                                <el-table-column
                                        width="200"
                                        prop="time"
                                        label="认缴出资日期">
                                </el-table-column>
                            </el-table>
                            <el-card
                                    v-if="!data.bigData.subHolder">
                                暂无数据
                            </el-card>
                        </el-card>
                        <el-card id="zyry" class="custom-el-card">
                            <div slot="header">
                                <span class="h1_title" style="font-weight: bold;font-size: 20px;">主要人员</span>
                            </div>
                            <el-table
                                    v-if="data.bigData.subStaff"
                                    :data="data.bigData.subStaff"
                                    style="width: 100%">
                                <el-table-column
                                        type="index"
                                        width="50">
                                </el-table-column>
                                <el-table-column
                                        prop="name"
                                        label="姓名">
                                </el-table-column>
                                <el-table-column
                                        prop="typeJoin"
                                        label="职位">
                                </el-table-column>
                            </el-table>
                            <el-card
                                    v-if="!data.bigData.subStaff">
                                暂无数据
                            </el-card>
                        </el-card>
                        <el-card id="fzjg" class="custom-el-card">
                            <div slot="header">
                                <span class="h1_title" style="font-weight: bold;font-size: 20px;">分支机构</span>
                            </div>
                            <el-table
                                    v-if="data.bigData.subBranch"
                                    :data="data.bigData.subBranch"
                                    style="width: 100%">
                                <el-table-column
                                        type="index"
                                        width="50">
                                </el-table-column>
                                <el-table-column
                                        prop="name"
                                        label="企业名称">
                                </el-table-column>
                                <el-table-column
                                        width="100"
                                        prop="alias"
                                        label="企业简称">
                                </el-table-column>
                                <el-table-column
                                        width="200"
                                        prop="legalPersonName"
                                        label="负责人">
                                </el-table-column>
                                <el-table-column
                                        width="200"
                                        prop="estiblishTime"
                                        label="成立日期">
                                </el-table-column>
                                <el-table-column
                                        width="200"
                                        prop="regStatus"
                                        label="经营状态">
                                </el-table-column>
                            </el-table>
                            <el-card
                                    v-if="!data.bigData.subBranch">
                                暂无数据
                            </el-card>
                        </el-card>
                        <el-card id="cpxx" class="custom-el-card">
                            <div slot="header">
                                <span class="h1_title" style="font-weight: bold;font-size: 20px;">产品信息</span>
                            </div>
                            <el-table
                                    v-if="data.bigData.subAppbkinfo"
                                    :data="data.bigData.subAppbkinfo"
                                    style="width: 100%">
                                <el-table-column
                                        type="index"
                                        width="50">
                                </el-table-column>
                                <el-table-column
                                        prop="name"
                                        label="产品名称">
                                </el-table-column>
                                <el-table-column
                                        width="100"
                                        prop="filtername"
                                        label="产品简称">
                                </el-table-column>
                                <el-table-column
                                        width="200"
                                        prop="type"
                                        label="产品分类">
                                </el-table-column>
                                <el-table-column
                                        width="200"
                                        prop="classes"
                                        label="产品领域">
                                </el-table-column>
                            </el-table>
                            <el-card
                                    v-if="!data.bigData.subAppbkinfo">
                                暂无数据
                            </el-card>
                        </el-card>
                        <el-card id="xzcf" class="custom-el-card">
                            <div slot="header">
                                <span class="h1_title" style="font-weight: bold;font-size: 20px;">行政处罚</span>
                            </div>
                            <el-table
                                    v-if="data.bigData.subPunishment"
                                    :data="data.bigData.subPunishment"
                                    style="width: 100%">
                                <el-table-column
                                        type="index"
                                        width="50">
                                </el-table-column>
                                <el-table-column
                                        prop="punishNumber"
                                        label="决定书文号">
                                </el-table-column>
                                <el-table-column
                                        label="行政处罚内容"
                                        :showOverflowTooltip="false">
                                    <template slot-scope="scope">
                                        <p v-html="replateHtml(scope.row.content)"></p>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        label="决定机关"
                                        :showOverflowTooltip="false">
                                    <template slot-scope="scope">
                                        <p v-html="replateHtml(scope.row.departmentName)"></p>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        width="200"
                                        prop="publishDate"
                                        label="公示日期">
                                </el-table-column>
                            </el-table>
                            <el-card
                                    v-if="!data.bigData.subPunishment">
                                暂无数据
                            </el-card>
                        </el-card>
                        <el-card id="zpxx" class="custom-el-card">
                            <div slot="header">
                                <span class="h1_title" style="font-weight: bold;font-size: 20px;">招聘信息</span>
                            </div>
                            <el-table
                                    v-if="data.bigData.subEmployments"
                                    :data="data.bigData.subEmployments"
                                    style="width: 100%">
                                <el-table-column
                                        type="index"
                                        width="50">
                                </el-table-column>
                                <el-table-column
                                        prop="title"
                                        label="招聘职位">
                                </el-table-column>
                                <el-table-column
                                        prop="orisalary"
                                        label="月薪">
                                </el-table-column>
                                <el-table-column
                                        prop="education"
                                        label="学历">
                                </el-table-column>
                                <el-table-column
                                        prop="experience"
                                        label="工作经验">
                                </el-table-column>
                                <el-table-column
                                        prop="district"
                                        label="地区">
                                </el-table-column>
                                <el-table-column
                                        prop="createtime"
                                        label="创建时间">
                                </el-table-column>
                            </el-table>
                            <el-card
                                    v-if="!data.bigData.subEmployments">
                                暂无数据
                            </el-card>
                        </el-card>
                    </el-main>
                </el-container>
            </el-col>
            <el-col :span="4">
                <el-aside width="200px"
                          style="margin-top:20px;z-index: 999;">
                    <el-card>
                        <el-steps direction="vertical" :active="rightBarActiveIndex" process-status="success">
                            <el-step v-for="m in rightBarData" :key="m.hash">
                                <el-link slot="title" @click="gotoHashLink(m.hash)">{{m.title}}</el-link>
                            </el-step>
                        </el-steps>
                    </el-card>
                </el-aside>
            </el-col>
        </el-col>
    </el-row>
</template>
<script>
    import {engineResult, moreInfo, updateCollectStatus} from "../api/api";
    import moment from 'moment'
    import {initDictOptions, filterDictText} from '@/components/dict/JDictSelectUtil'

    export default {
        components: {},
        data() {
            return {
                id: '',
                loading: {
                    moreInfo: false,
                    changeCollectStatus: false
                },
                rightBarActiveIndex: 0,
                rightBarData: [
                    {
                        hash: 'jbxx',
                        title: '基本信息'
                    }, {
                        hash: 'fkxx',
                        title: '风控信息'
                    }, {
                        hash: 'gsxx',
                        title: '工商信息'
                    }, {
                        hash: 'gsbg',
                        title: '工商变更'
                    }, {
                        hash: 'rzxx',
                        title: '融资信息'
                    }, {
                        hash: 'gdxx',
                        title: '股东信息'
                    }, {
                        hash: 'zycy',
                        title: '主要成员'
                    }, {
                        hash: 'fzjg',
                        title: '分支机构'
                    }, {
                        hash: 'cpxx',
                        title: '产品信息'
                    }, {
                        hash: 'xzcf',
                        title: '行政处罚'
                    }, {
                        hash: 'zpxx',
                        title: '招聘信息'
                    }
                ],
                form: {},
                data: {
                    bsData: {},
                    esData: {},
                    bigData: {},
                    collectData: {}
                },
                dictOptions: {
                    dictCompanyCollectStatus: []
                }
            }
        },
        watch: {
            $route(route) {
                this.updateRightBarActiveIndex()
            }
        },
        created() {
            this.id = this.$route.params.id
            Object.assign(this.data, this.$route.query)
            this.updateRightBarActiveIndex()
            this.initDictConfig()
            this.initData()
        },
        methods: {
            moment,
            filterDictText,
            replateHtml(html) {
                if (html) {
                    html = html.replace(/&lt;em&gt;/g, '<span style="font-style: oblique">')
                    html = html.replace(/&lt;\/em&gt;/g, '</span>')
                }
                return html
            },
            gotoHashLink(hash) {
                let currHash = this.$route.hash
                if (`#${hash}` != currHash) {
                    this.$router.push({hash: hash, query: this.$route.query})

                }
            },
            updateRightBarActiveIndex() {
                let hash = this.$route.hash
                this.rightBarData.forEach((m, index) => {
                    if (hash === `#${m.hash}`) {
                        this.rightBarActiveIndex = index
                    }
                })
            },
            initDictConfig() {

                initDictOptions('crm_invest_company_status').then((res) => {
                    if (res.success) {
                        this.$set(this.dictOptions, 'dictCompanyCollectStatus', res.result)
                    }
                })

            },
            handleCommand(command) {
                let that = this
                if (command && !that.loading.changeCollectStatus) {
                    that.loading.changeCollectStatus = true
                    updateCollectStatus(that.id, {status: command}).then((res) => {
                        that.loading.changeCollectStatus = false
                        if (res.success && res.data) {
                            that.initData()
                        }
                    }).catch((err) => {
                        that.loading.changeCollectStatus = false
                        console.log(err)
                    })
                }
            },
            openReport(){
                let industryCode =this.data.bigData.subBaseinfo[0].industryCode;
                let routeData = this.$router.resolve({ path: '/talkreport/'+industryCode });
                window.open(routeData.href, '_blank');
            },
            initData() {
                let that = this
                that.loading.moreInfo = true
                moreInfo(this.id).then((res) => {
                    that.loading.moreInfo = false
                    if (res.success && res.data) {
                        
                        Object.assign(this.data, res.data)
                        // this.$set(this.data,res.data)
                        let subBaseinfo = res.data.bigData.subBaseinfo[0] 
                        let companyDetial ={ 
                            subAppbkinfo:res.data.bigData.subAppbkinfo,
                            companyName:res.data.companyName,
                            regLocation:subBaseinfo.regLocation,
                            logo:subBaseinfo.logo,
                            businessScope:subBaseinfo.businessScope
                        }
                        sessionStorage.setItem("companyDetial",JSON.stringify(companyDetial))
                    }
                }).catch((err) => {
                    that.loading.moreInfo = false
                    console.log(err)
                })
            }
        },
        watch: {
            $route(route) {
                this.updateRightBarActiveIndex()
            }
        },
    }

</script>

<style>
    .el-step__icon {
        width: 18px;
        height: 18px;
    }

    .el-step.is-vertical .el-step__line {
        left: 8px;
    }

    .h1_title {
        letter-spacing: 1px;
        padding-top: 10px;
        display: block;
    }

    .custom-el-card {
        font-size: 14px;
    }

    .custom-el-card .el-card__header {
        padding: 10px 20px 0;
        border-bottom: unset;
    }

    .custom-el-card .el-card__header .title {
        font-weight: bold;
        font-size: 18px;
    }

    .title-blold {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 30px;
    }

    .text-muted {
        color: #777;
    }

    .max-width200 {
        max-width: 200px;
    }

    .kta-el-card {
        width: 48%;
        height: 236px;
        float: left;
        margin: 5px 5px
    }

    .kta-el-card .kta-table {
        width: 100%;
        height: 100%;
    }

    .kta-el-card .kta-table td {
        padding: 3px;
    }
</style>
<style scoped>
    * {
        text-align: initial;
    }

    .top-cont {
        height: 300px;
        background-image: url('../assets/pic/wujin_pic.png');
        background-repeat: no-repeat;
        background-size: cover;
    }

    .custom-el-divider--vertical {
        height: 70%;
    }

    .custom-tag-button {
        cursor: default !important;
        color: white !important;
        background-color: #f4f4f52e !important;
        border-color: #e9e9eb7d !important;
    }

    .custom-el-button {
        color: white;
        background: #f4f4f538;
        border-color: #d3d4d6ba;
    }


</style>
