<template>
  <div class="agreement">
    <h1 align="center">用户使用协议</h1>
    <p>
      请您在使用本产品前务必仔细阅读并理解《用户许可使用协议》（以下简称“本协议”）中规定的多有权利和限制。
    </p>
    <p style="text-indent: 2em">
      我们一向尊重并会严格保护用户在使用本产品时的合法权益（包括用户隐私、用户数据等）不受到任何侵犯。
    </p>
    <p style="text-indent: 2em">
      本协议（包括本文最后部分的隐私政策）是用户（包括通过各种合法途径获取到本产品的自然人、法人或其他组织机构，以下简称“用户”或“您”）与我们之间针对本产品相关事项最终的、完整的且排他的协议，并取代、合并之前的当事人之间关于上述事项的讨论和协议。
    </p>
    <p style="text-indent: 2em">
      本协议将对用户使用本产品的行为产生法律约束力，您已承诺和保证有权利和能力订立本协议。用户开始使用本产品将视为已经接受本协议，请认真阅读并理解本协议中各种条款，包括免除和限制我们的免责条款和对用户的权利限制（未成年人审阅时应由法定监护人陪同），如果您不能接受本协议中的全部条款，请勿开始使用本产品。
    </p>
    <p>使用</p>
    <p>l &nbsp;账户</p>
    <p>您必须承诺和保证：</p>
    <p>1. 您使用本产品的行为必须合法</p>
    <p style="text-indent: 2em">
      本产品将会依据本协议“修改和终止”的规定保留或终止您的账户。您必须承诺对您的登录信息保密、不被其他人获取与使用，并且对您在本账户下的所有行为负责。您必须将任何有可能触犯法律的、未授权使用或怀疑为未授权使用的行为在第一时间通知本产品。本产品不对您因未能遵守上述要求而造成的损失承担法律责任。
    </p>
    <p>终端用户协议</p>
    <p>l &nbsp;许可</p>
    <p style="text-indent: 2em">
      依据本协议规定，本产品将授予您以下不可转让的、非排他的许可：
    </p>
    <p style="text-indent: 2em">1.使用本产品的权利；</p>
    <p style="text-indent: 2em">
      2.在您所有的网络通信设备、计算机设备和移动通信设备上下载、安装、使用本产品的权利。
    </p>
    <p>l &nbsp;限制性条款</p>
    <p style="text-indent: 2em">本协议对您的授权将受到以下限制：</p>
    <p style="text-indent: 2em">
      1.
      您不得对本产品进行任何形式的许可、出售、租赁、转让、发行或其他商业用途；
    </p>
    <p style="text-indent: 2em">
      2.
      除非法律禁止此类限制，否则您不得对本产品的任何部分或衍生产品进行修改、翻译、改编、合并、利用、分解、改造或反向编译、反向工程等；
    </p>
    <p style="text-indent: 2em">
      3. 您不得以创建相同或竞争服务为目的使用本产品；
    </p>
    <p style="text-indent: 2em">
      &nbsp;4.
      除非法律明文规定，否则您不得对本产品的任何部分以任何形式或方法进行生产、复制、发行、出售、下载或显示等；
    </p>
    <p style="text-indent: 2em">
      5. 您不得删除或破坏包含在本产品中的任何版权声明或其他所有权标记。
    </p>
    <p>l &nbsp;费用</p>
    <p style="text-indent: 2em">
      您必须自行负担购买本产品的费用，个人上网或第三方（包括但不限于电信或移动通讯提供商）收取的通讯费、信息费等相关费用。如涉及电信增值服务，我们建议您与增值服务提供商确认相关费用问题。
    </p>
    <p>l &nbsp;版本</p>
    <p style="text-indent: 2em">
      任何本产品的更新版本或未来版本、更新或者其他变更将受到本协议约束。
    </p>
    <p></p>
    遵守法律
    <p></p>
    <p style="text-indent: 2em">
      您同意遵守《中华人民共和国合同法》、《中华人民共和国著作权法》及其实施条例、《全国人民代表大会常务委员会关于维护互联网安全的决定》（“人大安全决定”）、《中华人民共和国保守国家秘密法》、《中华人民共和国电信条例》（“电信条例“）、《中华人民共和国计算机信息系统安全保护条例》、《中华人民共和国计算机信息网络国际联网管理暂行规定》及其实施办法、《计算机信息系统国际联网保密管理规定》、《互联网信息服务管理办法》、《计算机信息网络国际联网安全保护管理办法》、《互联网电子公告服务管理规定》（“电子公告规定”）等相关中国法律法规的任何及所有的规定，并对以任何方式使用您的密码和您的账号使用本服务的任何行为及其结果承担全部责任。如违反《人大安全决定》有可能构成犯罪，被追究刑事责任。《电子公告规定》则有明文规定，上网用户使用电子公告服务系统对所发布的信息负责。《电信条例》也强调，使用电信网络传输信息的内容及其后果由电信用户负责。在任何情况下，如果本网站有理由认为您的任何行为，包括但不限于您的任何言论和其它行为违反或可能违反上述法律和法规的任何规定，本网站可在任何时候不经任何事先通知终止向您提供服务。
    </p>
    <p>用户内容</p>
    <p>l &nbsp;用户内容</p>
    <p style="text-indent: 2em">
      1.用户内容是指该用户下载、发布或以其他方式使用本产品时产生的所有内容（例如：您的信息、图片、音乐或其他内容）。
    </p>
    <p style="text-indent: 2em">
      2.您是您的用户内容唯一的责任人，您将承担因您的用户内容披露而导致的您或任何第三方被识别的风险。
    </p>
    <p style="text-indent: 2em">
      3.您已同意您的用户内容受到权利限制（详见“权利限制”）。
    </p>
    <p>权利限制</p>
    <p style="text-indent: 2em">
      您已同意通过分享或其他方式使用本产品中的相关服务，在使用过程中，您将承担因下述行为所造成的风险而产生的全部法律责任：
    </p>
    <p style="text-indent: 2em">1.破坏宪法所确定的基本原则的；</p>
    <p style="text-indent: 2em">
      2.危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的；
    </p>
    <p style="text-indent: 2em">3.损害国家荣誉和利益的；</p>
    <p style="text-indent: 2em">4.煽动民族仇恨、民族歧视，破坏民族团结的；</p>
    <p style="text-indent: 2em">5.破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
    <p style="text-indent: 2em">
      6.散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
    </p>
    <p style="text-indent: 2em">7.侮辱或者诽谤他人，侵害他人合法权益的；</p>
    <p style="text-indent: 2em">8.含有法律、行政法规禁止的其他内容的。</p>
    <p style="text-indent: 2em">您已经同意不在本产品从事下列行为：</p>
    <p style="text-indent: 2em">
      1.发布或分享电脑病毒、蠕虫、恶意代码、故意破坏或改变计算机系统或数据的软件；
    </p>
    <p style="text-indent: 2em">
      2.未授权的情况下，收集其他用户的信息或数据，例如电子邮箱地址等；
    </p>
    <p style="text-indent: 2em">
      3.用自动化的方式恶意使用本产品，给服务器造成过度的负担或以其他方式干扰或损害网站服务器和网络链接；
    </p>
    <p style="text-indent: 2em">
      4.在未授权的情况下，尝试访问本产品的服务器数据或通信数据；
    </p>
    <p style="text-indent: 2em">5.干扰、破坏本产品其他用户的使用。</p>
    <p>修改和终止</p>
    <p>l &nbsp;修改</p>
    <p style="text-indent: 2em">
      本协议容许变更。如果本协议有任何实质性变更，我们将通过电子邮件来通知您。变更通知之后，继续使用本产品则为您已知晓此类变更并同意条款约束；
    </p>
    <p style="text-indent: 2em">
      我们保留在任何时候无需通知而修改、保留或关闭本产品任何服务之权利；
    </p>
    <p style="text-indent: 2em">
      您已同意我们无需因修改、保留或关闭本产品任何服务之权利；
    </p>
    <p style="text-indent: 2em">
      您已同意我们无需因修改、保留或关闭本产品任何服务的行为对您或第三方承担责任。
    </p>
    <p>l &nbsp;终止</p>
    <p style="text-indent: 2em">
      本协议自您接受之日起生效，在您使用本产品的过程中持续有效，直至依据本协议终止；
    </p>
    <p style="text-indent: 2em">
      尽管有上述规定，如果您使用本产品的时间早于您接受本协议的时间，您在此知晓并同意本协议于您接受本协议的时间，您在此知晓并同意本协议于您第一次使用本产品时生效，除非依据本协议提前终止；
    </p>
    <p style="text-indent: 2em">
      我们可能会依据法律的规定，保留您使用本产品或者本账户的权利；无论是否通知，我们将在任何时间以任何原因终止本协议，包括出于善意的相信您违反了我们可接受使用政策或本协议的其他规定；
    </p>
    <p style="text-indent: 2em">
      不受前款规定所限，如果用户侵犯第三人的版权且我们接到版权所有人或版权所有人的合法代理人的通知后，我们保留终止本协议的权利；
    </p>
    <p style="text-indent: 2em">
      一旦本协议终止，您使用本产品的权利即告终止。您应当知晓您的产品终止意味着您的用户内容将从我们的活动数据库中删除。我们不因终止本协议对您承担任何责任，包括终止您的用户账户和删除您的用户内容。
    </p>
    <p>第三方</p>
    <p>l &nbsp;您已知晓或同意我们的部分服务时基于第三方的技术支持获得；</p>
    <p style="text-indent: 2em">
      您已知晓本协议是您在与我们之前签订，而非您与上述第三方之间签订。我们是基于本产品所产生的内容、维护、支持服务、保证和由此产生的诉讼等事项的唯一责任人。您已同意遵守且授权给本产品限制您有条件地使用本产品的服务。
    </p>
    <p>l &nbsp;第三方信息和服务</p>
    <p style="text-indent: 2em">
      1.本产品包含了第三方的部分信息和服务。我们不控制且不对第三方的信息和服务负责；
    </p>
    <p style="text-indent: 2em">
      2.我们仅为您使用方便的目的或为承诺和保证第三方的需要而提供此类信息和服务；
    </p>
    <p style="text-indent: 2em">
      3.用户需对使用第三方信息和服务产生的风险承担法律责任；
    </p>
    <p style="text-indent: 2em">
      4.当用户访问第三方信息和服务时，适用第三方的条款和政策。
    </p>
    <p>赔偿</p>
    <p>
      l
      &nbsp;您已同意无害地使用本产品，避免因下述行为或相关行为遭受来第三方的任何投诉、诉讼、损失、损害、责任、成本和费用（包括律师费）：
    </p>
    <p style="text-indent: 2em">1.用户使用本产品的行为；</p>
    <p style="text-indent: 2em">2.用户的用户内容；</p>
    <p style="text-indent: 2em">3.用户违反本协议的行为。</p>
    <p style="text-indent: 2em">l &nbsp;我们保留专属抗辩权和请求赔偿的权利</p>
    <p style="text-indent: 2em">
      l
      &nbsp;您已同意，除非获得我们书面同意，您不得在您与我们共同对第三方提起的诉讼中单方和解。
    </p>
    <p style="text-indent: 2em">
      l &nbsp;我们将尽合理努力将此类诉讼、诉讼行为或进程通知您。
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;在任何情况下，本产品都不对您或任何第三方因本协议产生的任何间接性、后果性、惩罚性的、偶然的、特殊或惩罚性的损害赔偿承担责任。访问、使用本产品所产生的损坏计算机系统或移动通讯设备数据的风险将由您个人承担。
    </p>
    <p>适用法律</p>
    <p style="text-indent: 2em">本协议适用中华人民共和国法律；</p>
    <p style="text-indent: 2em">
      如果双方发生纠纷，应本着友好的圆柱协商解决；如协商不成，应向所在地的法院提起诉讼。
    </p>
    <p>独立性</p>
    <p style="text-indent: 2em">
      本协议中的某些条款因故无法适用，则本协议的其他条款继续适用且无法适用的条款将会被修改，以便其能够依法适用。
    </p>
    <p>完整性</p>
    <p style="text-indent: 2em">
      本协议（包括隐私政策）是您和本产品之间关于本产品相关事项的最终的、完整的、排他的协议，且取代和合并之前当事人关于此类事项（包括之前的最终用户许可、服务条款和隐私政策）的讨论和协议；
    </p>
    <p style="text-indent: 2em">
      每部分的题目只为阅读之便而无任何法律或合同义务；
    </p>
    <p style="text-indent: 2em">
      除非我们书面同意，您不得转让本协议所规定的权利义务。任何违反上述规定企图转让的行为均无效。
    </p>
    <h1 align="center">隐私政策</h1>
    <p style="text-indent: 2em">
      请您在开始使用我们的产品（一下简称“本产品”）之前请务必仔细阅读并理解《隐私政策》（以下简称“本政策”）。
    </p>
    <p style="text-indent: 2em">
      我们一向尊重并会严格保护用户在使用本产品时的合法权益（包括用户隐私、用户数据等）。我们力求明确说明我们是如何收集、使用、披露、传送及存储您的信息的。虽然本政策适用于我们的所有服务，但我们也针对特定服务制定了某些明确的某些明确的隐私权补充条文，其中包含有关我们的信息处理做法的其他信息。当我们进行其它数据收集处理活动时会另外取得您的同意。请务必仔细阅读本隐私政策，因为只要您使用我们的服务，就表示您同意隐私政策及补充条文中所描述的做法，如果您不同意隐私政策中所描述的做法，请不要使用我们的服务。
    </p>
    <p style="text-indent: 2em">
      您可以通过多种不同的方式使用我们的服务。基于此目的，我们将向您解释我们对信息的收集和使用方式，以及您可采用什么方式来保护自己的隐私权。
    </p>
    <p style="text-indent: 2em">我们的隐私政策包括了以下几个方面的问题：</p>
    <p style="text-indent: 2em">我们收集哪些信息</p>
    <p style="text-indent: 2em">我们如何收集和使用信息</p>
    <p style="text-indent: 2em">您如何访问和控制自己的个人信息</p>
    <p style="text-indent: 2em">信息的分享、安全以及隐私政策的使用范围和变更</p>
    <p>我们会收集哪些信息</p>
    <p style="text-indent: 2em">
      l
      &nbsp;您通过我们的服务向其他方提供的共享信息，以及您使用我们服务过程中收集的信息；
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;除了您提供的信息外，我们还可能通过您设备中的软件以及其他方式来收集您使用我们服务的相关信息，如设备信息、日志信息、位置信息等；
    </p>
    <p>我们如何收集和利用信息</p>
    <p style="text-indent: 2em">个人信息的收集和使用</p>
    <p style="text-indent: 2em">
      您与我们及其关联公司进行互动时，您可能会被要求提供您同意使用我们基本产品服务所必需的某些个人信息。该个人信息可能与其他信息合并在一起，被用于改进我们的产品或服务等。下文是我们可能收集的个人信息的类型以及我们如何使用该信息的一些示例。
    </p>
    <p style="text-indent: 2em">个人信息的收集</p>
    <p style="text-indent: 2em">
      当您创建APP账户、参加在线调查或者参加其他与我们互动时。
    </p>
    <p style="text-indent: 2em">个人信息的使用</p>
    <p style="text-indent: 2em">
      我们将严格遵守本隐私政策及其更新所载明的内容来使用您的个人信息。您的个人信息将仅用于收集时即已确定并且经过您同意的目的，如有除此之外的任何其他用途，我们都会提前征得您的同意。
    </p>
    <p style="text-indent: 2em">l &nbsp;向您提供服务；</p>
    <p style="text-indent: 2em">l &nbsp;为您的设备提供维护服务；</p>
    <p style="text-indent: 2em">
      l &nbsp;在法律允许的情况下进行免费抽奖、有奖竞赛或促销；
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;在我们提供服务时，用于身份验证、客户服务、安全规范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；
    </p>
    <p style="text-indent: 2em">
      l &nbsp;帮助我们设计新服务，改善我们现有服务；
    </p>
    <p style="text-indent: 2em">l &nbsp;软件认证或管理软件升级；</p>
    <p style="text-indent: 2em">l &nbsp;让您参与有关我们产品和服务的调查；</p>
    <p style="text-indent: 2em">非个人信息的收集和使用</p>
    <p style="text-indent: 2em">
      同时，为了运营和改善我们的技术和服务，我们将会自行收集使用您的非个人信息，这将有助于我们向您提供更好的用户体验和提高服务质量。
    </p>
    <p style="text-indent: 2em">非个人信息的收集</p>
    <p style="text-indent: 2em">
      日志信息—当您使用我们的服务等时，我们的服务器会自动记录某些日志信息。这类服务器日志信息可能包含如下信息：IP地址、浏览器类型、浏览器语言、refer来源页、操作系统、日期/时间标记和点击流数据。
    </p>
    <p>Cookie的使用</p>
    <p style="text-indent: 2em">
      Cookie技术：您访问我们平台相关网站或使用我们官网平台提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做是帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie，FlashCookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;在您未拒绝接受Cookies的情况下，本应用会在您的计算机上设定或取用Cookies
      ，以便您能登录或使用依赖于Cookies的本应用平台服务或功能。本应用使用Cookies可为您提供更加周到的个性化服务，包括推广服务。
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;您有权选择接受或拒绝接受Cookies。您可以通过修改浏览器设置的方式拒绝接受Cookies。但如果您选择拒绝接受Cookies，则您可能无法登录或使用依赖于Cookies的本应用网络服务或功能。
    </p>
    <p style="text-indent: 2em">
      l &nbsp;通过本应用所设Cookies所取得的有关信息，将适用本政策。
    </p>
    <p>您如何访问和控制自己的个人信息</p>
    <p style="text-indent: 2em">
      我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。
    </p>
    <p>信息的分享、安全以及隐私政策的使用范围和变更</p>
    <p style="text-indent: 2em">我们如何共享信息</p>
    <p>
      除非本隐私政策载明的有限共享，我们会严格保密您的个人信息。未经您的同意，我们将不会向第三方披露您的信息以用于其各自的独立市场营销或商业用途。您于此授权，以下情形我们将会向第三方共享您的个人信息而无需通过您的同意：
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;业务合作伙伴。我们也可能与可信的业务合作伙伴（包括无线运营商）共享您的信息。这些实体可能使用您的信息来提供您请求的服务、对您的喜好进行预测、并可能向您提供促销材料、广告以及其他材料。
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;服务提供商。我们也可能向为我们或代表我们提供服务的公司披露您的信息，如协助我们处理账单或代表我们发送电子邮件的公司，但这些实体只能将您的信息用于为我们提供服务。
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;法律要求或保护我们的服务所必需的其他各方。在有些情况下我们可能会向其他各方披露您的信息；
    </p>
    <p style="text-indent: 2em">
      l &nbsp;遵守法律或服从强制性的法律程序（如搜查证或其他法院命令）；
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;确认或强制遵循我们的服务策略；以及保护我们及我们的各个附属公司、业务合作伙伴或客户的权利、财产或安全；
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;与公司事务有关的其他各方。在公司合并、转让或破产过程中，我们可能会向第三方披露您的信息；
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;征得您同意的其他各方。除本政策中描述的披露情况之外，我们也可能在您同意共享或提出共享请求时与第三方共享您的信息；
    </p>
    <p>我们如何确保您的信息安全</p>
    <p style="text-indent: 2em">
      l
      &nbsp;您的账户均有安全保护功能，请妥善保管您的账户及密码信息。我们仅在本《隐私政策》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息；
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题；
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;我们只允许那些为了帮我们处理个人信息而需要知晓这些信息的员工、授权代为处理服务公司的人员访问个人信息，而且他们需要履行严格的合同保密义务，如果其未能履行这些义务，就可能会被追究法律责任或被终止其与我们的关系；
    </p>
    <p style="text-indent: 2em">
      l
      &nbsp;对我们来说，您的信息的安全非常重要。因此，我们将不断努力保障您的个人信息安全，并实施存储和传输全程安全加密等保障手段、以免您的信息在未经授权的情况下被访问、使用或被披露。同时某些加密数据的具体内容，除了用户自己，其他人都无权访问。
    </p>
    <p>对儿童个人信息的收集和使用</p>
    <p style="text-indent: 2em">
      我们建议13周岁以下的儿童在其法定监护人的许可和指导下使用产品或服务。
    </p>
    <p style="text-indent: 2em">
      我们不会在明知对方为儿童的情况下收集其个人信息，也不会向任何第三方透露其个人信息，监护人有权拒绝我们及其关联公司进一步收集被监护人的个人信息，或要求我们及关联公司删除该被监护人个人信息。如果我们发现我们收集了年龄不满13周岁的儿童的个人信息，我们将采取措施尽快地删除此等信息。
    </p>
    <p>变更</p>
    <p style="text-indent: 2em">
      我们可能适时修订本《隐私政策》的条款，该修订构成本《隐私政策》的一部分。如该等修订造成您在本《隐私政策》下权利的实质减少，我们将在修订生效前通过在主页显著位置提示或者向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。
    </p>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  //创建前设置
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "min-width:0px !important;");
    document
      .querySelector("html")
      .setAttribute("style", "min-width:0px !important;");
  },
  //销毁前清除
  beforeUnmount() {
    document.querySelector("body").removeAttribute("style");
    document.querySelector("html").removeAttribute("style");
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.agreement {
  width: 80%;
  margin: 50px auto;
}
</style>
