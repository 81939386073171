import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/login'
import Agreement from '@/views/agreement'
import UserAgreement from '@/views/userAgreement'
import PrivacyAgreement from '@/views/privacyAgreement'
import MyPark from '@/views/myPark'
import Guanjia from '@/views/guanjia'
import ZhaoShang from '@/views/zhaoShang'
import GuanZhuList from '@/views/GuanZhuList';
// import CompanyDetail from '@/views/companyDetail';
import MerchantsEngine from '@/views/merchantsEngine'
import MerchantsEngineResult from '@/views/merchantsEngineResult'
import MerchantsEngineDetail from '@/views/merchantsEngineDetail'
import IndustryChainMap from '@/views/industryChainMap'
import ProjectLibrary from '@/views/projectLibrary'
import ProjectLibraryDetail from '@/views/projectLibraryDetail'
import IndustryChainMapDetail from "../views/industryChainMapDetail";
import FavoriteCompanyList from '@/views/favoriteCompanyList'

import KeyIndustry from '@/views/keyIndustry'
import TalkReport from '@/views/talkReport'

Vue.use(Router)

const scrollBehavior = function (to, from, savedPosition) {
    if (to.hash) {
        return {
            // 通过 to.hash 的值來找到对应的元素
            selector: to.hash
        }
    }
}

export default new Router({
    mode: 'history',
    base: '/',
    scrollBehavior,
    routes: [
        {
            path: '/',
            redirect: '/login',
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        {
            path: '/agreement',
            name: 'Agreement',
            component: Agreement
        },
        {
            path: '/userAgreement',
            name: 'UserAgreement',
            component: UserAgreement
        },
        {
            path: '/privacyAgreement',
            name: 'PrivacyAgreement',
            component: PrivacyAgreement
        },
        {
            path: '/myPark',
            name: 'MyPark',
            component: MyPark
        },

        {
            path: '/keyIndustry',
            name: 'KeyIndustry',
            component: KeyIndustry,
            meta: {
                keepAlive: true,
                isBack: false
            }
        },
        {
            path: '/guanjia',
            name: 'Guanjia',
            component: Guanjia
        },
        {
            path: '/zhaoshang',
            name: 'ZhaoShang',
            component: ZhaoShang
        },
        {
            path: '/merchantsEngine',
            name: 'MerchantsEngine',
            component: MerchantsEngine
        },
        {
            path: '/merchantsEngineResult',
            name: 'MerchantsEngineResult',
            component: MerchantsEngineResult
        },
        {
            path: '/merchantsEngineDetail/:id',
            name: 'MerchantsEngineDetail',
            component: MerchantsEngineDetail
        },
        {
            path: '/favoriteCompanyList',
            name: 'FavoriteCompanyList',
            component: FavoriteCompanyList,
            meta: {
                keepAlive: true,
                isBack: false
            }
        },
        {
            path: '/industryChainMap',
            name: 'IndustryChainMap',
            component: IndustryChainMap
        },
        {
            path: '/industryChainMapDetail',
            name: 'IndustryChainMapDetail',
            component: IndustryChainMapDetail
        },
        {
            path: '/projectLibrary',
            name: 'ProjectLibrary',
            component: ProjectLibrary,
        },
        {
            path: '/projectLibraryDetail',
            name: 'ProjectLibraryDetail',
            component: ProjectLibraryDetail,
        },
        {
            path: '/yuanqu/guanzhu/:id',
            name: 'guanzhu_list',
            component: GuanZhuList,
        },
        // {
        //     path: '/companydetail/:id',
        //     name: 'company_detail',
        //     component: CompanyDetail,
        // },
        {
            path: '/talkreport/:industryCode',
            name: 'talk_report',
            component: TalkReport
        }
    ]
})
