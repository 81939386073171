<template>
  <div id="app">
    <el-backtop></el-backtop>
    <div v-if="$route.name!='Login'&&$route.name!='Agreement'&&$route.name!='UserAgreement'&&$route.name!='PrivacyAgreement'">
      <m-header></m-header>
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
      <m-footer></m-footer>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import mHeader from "@/common/header.vue";
import mFooter from "@/common/footer.vue";
export default {
  name: "App",
  watch: {
    $route: function(to, from) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
  components: {
    mHeader,
    mFooter
  }
};
</script>

<style lang="scss">
@import "assets/css/public.css";
@import "assets/scss/style";
</style>
