<template>
  <!-- 关注/搜索企业列表 -->
  <div>
    <!-- 分类tag -->
    <el-main>
      <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="16">

          <div class="banner-tag">
            <div class="tag-title">
              <span class="title-words">关注企业</span>
            </div>
            <el-row :gutter="10" style="margin-top:20px;">
              <el-col :span="3">
                <div class="grid-content tag-content-left"><span>注册资本</span></div>
              </el-col>
              <el-col :span="21">
                <div class="grid-content tag-content-right">
                  <div>

                    <el-checkbox-group v-model="checkboxGroup1" size="small">
                      <el-row :gutter="10">
                        <el-col :span="2">
                          <el-checkbox-button label="不限"></el-checkbox-button>
                        </el-col>
                        <el-col :span="22" style="padding-top: 5px;padding-left: 20px">
                          <el-checkbox v-for="jine in jinges" :label="jine" :key="jine">{{jine}}</el-checkbox>
                        </el-col>
                      </el-row>

                    </el-checkbox-group>

                  </div>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="10" style="margin-top:16px;">
              <el-col :span="3">
                <div class="grid-content tag-content-left"><span>所属行业</span></div>
              </el-col>
              <el-col :span="21">
                <div class="grid-content tag-content-right">
                  <div>

                    <el-checkbox-group v-model="checkboxGroup2" size="small">
                      <el-row :gutter="10">
                        <el-col :span="2">
                          <el-checkbox-button label="不限"></el-checkbox-button>
                        </el-col>
                        <el-col :span="22" style="padding-top: 5px;">
                          <el-checkbox-button v-for="hangye in hangyes" :label="hangye" :key="hangye">{{hangye}}
                          </el-checkbox-button>
                        </el-col>
                      </el-row>

                    </el-checkbox-group>

                  </div>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="10" style="margin-top:16px;">
              <el-col :span="3">
                <div class="grid-content tag-content-left"><span>地域</span></div>
              </el-col>
              <el-col :span="21">
                <div class="grid-content tag-content-right">
                  <div>
                    <el-checkbox-group v-model="checkboxGroup3" size="small">
                      <el-row :gutter="10">
                        <el-col :span="2">
                          <el-checkbox-button label="不限"></el-checkbox-button>
                        </el-col>
                        <el-col :span="22" style="padding-top: 5px;">
                          <el-checkbox-button v-for="diqu in diqus" :label="diqu" :key="diqu">{{diqu}}
                          </el-checkbox-button>
                        </el-col>
                      </el-row>

                    </el-checkbox-group>

                  </div>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="10" style="margin-top:16px;">
              <el-col :span="3">
                <div class="grid-content tag-content-left"><span>最近投资时间</span></div>
              </el-col>
              <el-col :span="21">
                <div class="grid-content tag-content-right">

                  <div style="display:inline;">

                    <el-checkbox-group v-model="checkboxGroup4" size="small">
                      <el-row :gutter="10">
                        <el-col :span="2">
                          <el-checkbox-button label="不限"></el-checkbox-button>
                        </el-col>
                        <el-col :span="22" style="padding-top: 5px;">
                          <el-checkbox-button v-for="shijian in shijians" :label="shijian" :key="shijian">{{shijian}}
                          </el-checkbox-button>
                          <el-date-picker v-model="value1" type="daterange" range-separator="-"
                            start-placeholder="年/月/日" end-placeholder="年/月/日" style="margin-left:5px;" size="mini">

                          </el-date-picker>
                        </el-col>
                      </el-row>

                    </el-checkbox-group>

                  </div>
                </div>
              </el-col>
            </el-row>

          </div>

          <!-- 企业列表 -->
          <div class="company-list-down">
            <div class="list-title">
              <div style="float:left" class="title-left">共有123家企业</div>
              <div style="float:right" class="title-right my-click" @click="onDesc"><span
                  :class="{'desc-color':desc==false}">匹配度排序 <img style="width:12px;height:12px" :src="desc_pic"
                    alt=""></span><span style="margin-left:40px;">注册资金排序 <img style="width:12px;height:12px"
                    src="../assets/pic/paixu_wu.png" alt=""></span></div>
            </div>
            <el-divider></el-divider>
            <div class="company-detail-list" v-if="desc">

              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between"
                :class="{active_bg_select:act_bg_index===1}" @mouseenter="act_bg_index=1">
                <div style="display:flex">
                  <div style="position:relative;">
                    <div style="position:absolute;top:-15px;left:-5px;">
                      <el-tag type="danger" effect="dark" size="small">NEW</el-tag>
                    </div>
                    <img src="../assets/pic/longji.png" style="height:80px;weight:80px" alt="">
                  </div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">山东隆基机械股份有限公司</router-link></div>
                    <div class="company-address">山东省龙口市外向型经济开发区</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">上市企业</el-button>
                      <el-button size="mini" round class="company-btn">纳税大户</el-button>
                      <el-button size="mini" round class="company-btn">新三板</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">96%</span>
                    <span class="right-num-money">41610<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===2}" @mouseenter="act_bg_index=2">
                <div style="display:flex;">
                  <div style="position:relative;">
                    <div style="position:absolute;top:-15px;left:-5px;">
                      <el-tag type="danger" effect="dark" size="small">NEW</el-tag>
                    </div>
                    <img src="../assets/pic/xugong.png" style="height:80px;weight:80px" alt="">
                  </div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">徐工集团工程机械股份有限公司</router-link></div>
                    <div class="company-address">江苏省徐州经济技术开发区驮蓝山路26号</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-red-btn">龙头企业</el-button>
                      <el-button size="mini" round class="company-btn">中国百强企业</el-button>
                      <el-button size="mini" round class="company-btn">世界五百强</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">98%</span>
                    <span class="right-num-money">783366<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===3}" @mouseenter="act_bg_index=3">
                <div style="display:flex;">
                  <div style="position:relative;">
                    <div style="position:absolute;top:-15px;left:-5px;">
                      <el-tag type="danger" effect="dark" size="small">NEW</el-tag>
                    </div>
                    <img src="../assets/pic/qishi.png" style="height:80px;weight:80px" alt="">
                  </div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">珠海启世机械设备股份有限公司</router-link></div>
                    <div class="company-address">珠海市创新四路16号1栋第一层A区、2栋第一层</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">上市企业</el-button>
                      <el-button size="mini" round class="company-btn">纳税大户</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">95%</span>
                    <span class="right-num-money">4900<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===4}" @mouseenter="act_bg_index=4">
                <div style="display:flex;">
                  <div><img src="../assets/pic/henjiu.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">江苏恒久机械股份有限公司</router-link></div>
                    <div class="company-address">徐州经济技术开发区杨山路99号</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">高新技术企业</el-button>
                      <el-button size="mini" round class="company-btn">新三板</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">75%</span>
                    <span class="right-num-money">6699<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===5}" @mouseenter="act_bg_index=5">
                <div style="display:flex;">
                  <div><img src="../assets/pic/zhonggong.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">锦阳众工机械股份有限公司</router-link></div>
                    <div class="company-address">锦阳高新区菩提寺村</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">高新技术企业</el-button>
                      <el-button size="mini" round class="company-btn">新三板</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">69%</span>
                    <span class="right-num-money">5070<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===6}" @mouseenter="act_bg_index=6">
                <div style="display:flex;">
                  <div><img src="../assets/pic/ruiniu.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">上海瑞纽机械股份有限公司</router-link></div>
                    <div class="company-address">浦东新区宣桥镇园德路105号</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">行业百强企业</el-button>
                      <el-button size="mini" round class="company-btn">中国百强企业</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">70%</span>
                    <span class="right-num-money">6000<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===7}" @mouseenter="act_bg_index=7">
                <div style="display:flex;">
                  <div><img src="../assets/pic/kaisi.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">湖南凯斯机械股份有限公司</router-link></div>
                    <div class="company-address">常德市安乡县深柳镇长岭洲居委会洞庭大道南路河东工业区</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">纳税大户</el-button>
                      <el-button size="mini" round class="company-btn">上市企业</el-button>
                      <el-button size="mini" round class="company-btn">新三板</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">91%</span>
                    <span class="right-num-money">4627<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===8}" @mouseenter="act_bg_index=8">
                <div style="display:flex;">
                  <div><img src="../assets/pic/zhonggang.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">天津重钢机械装备股份有限公司</router-link></div>
                    <div class="company-address">天津市滨海新区塘沽厦门路139号</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">纳税大户</el-button>
                      <el-button size="mini" round class="company-btn">高新技术企业</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">50%</span>
                    <span class="right-num-money">7965<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===9}" @mouseenter="act_bg_index=9">
                <div style="display:flex;">
                  <div><img src="../assets/pic/hanseng.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">常州汉森机械股份有限公司</router-link></div>
                    <div class="company-address">常州新北区环保四路89号</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">纳税大户</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">68%</span>
                    <span class="right-num-money">7260<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>
              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===10}" @mouseenter="act_bg_index=10">
                <div style="display:flex;">
                  <div><img src="../assets/pic/jiebao.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">浙江杰豹机械股份有限公司</router-link></div>
                    <div class="company-address">浙江省临海市古城街道许墅村</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">高新技术企业</el-button>
                      <el-button size="mini" round class="company-btn">新三板</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">87%</span>
                    <span class="right-num-money">4213<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

            </div>
            <div class="company-detail-list" v-else>
              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;"
                :class="{active_bg_select:act_bg_index===1}" @mouseenter="act_bg_index=1">
                <div style="display:flex;">
                  <div style="position:relative;">
                    <div style="position:absolute;top:-15px;left:-5px;">
                      <el-tag type="danger" effect="dark" size="small">NEW</el-tag>
                    </div>
                    <img src="../assets/pic/xugong.png" style="height:80px;weight:80px" alt="">
                  </div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">徐工集团工程机械股份有限公司</router-link></div>
                    <div class="company-address">江苏省徐州经济技术开发区驮蓝山路26号</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-red-btn">龙头企业</el-button>
                      <el-button size="mini" round class="company-btn">中国百强企业</el-button>
                      <el-button size="mini" round class="company-btn">世界五百强</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">98%</span>
                    <span class="right-num-money">783366<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>
              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===2}" @mouseenter="act_bg_index=2">
                <div style="display:flex">
                  <div style="position:relative;">
                    <div style="position:absolute;top:-15px;left:-5px;">
                      <el-tag type="danger" effect="dark" size="small">NEW</el-tag>
                    </div>
                    <img src="../assets/pic/longji.png" style="height:80px;weight:80px" alt="">
                  </div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">山东隆基机械股份有限公司</router-link></div>
                    <div class="company-address">山东省龙口市外向型经济开发区</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">上市企业</el-button>
                      <el-button size="mini" round class="company-btn">纳税大户</el-button>
                      <el-button size="mini" round class="company-btn">新三板</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">96%</span>
                    <span class="right-num-money">41610<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===3}" @mouseenter="act_bg_index=3">
                <div style="display:flex;">
                  <div style="position:relative;">
                    <div style="position:absolute;top:-15px;left:-5px;">
                      <el-tag type="danger" effect="dark" size="small">NEW</el-tag>
                    </div>
                    <img src="../assets/pic/qishi.png" style="height:80px;weight:80px" alt="">
                  </div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">珠海启世机械设备股份有限公司</router-link></div>
                    <div class="company-address">珠海市创新四路16号1栋第一层A区、2栋第一层</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">上市企业</el-button>
                      <el-button size="mini" round class="company-btn">纳税大户</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">95%</span>
                    <span class="right-num-money">4900<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>
              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===7}" @mouseenter="act_bg_index=7">
                <div style="display:flex;">
                  <div><img src="../assets/pic/kaisi.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">湖南凯斯机械股份有限公司</router-link></div>
                    <div class="company-address">常德市安乡县深柳镇长岭洲居委会洞庭大道南路河东工业区</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">纳税大户</el-button>
                      <el-button size="mini" round class="company-btn">上市企业</el-button>
                      <el-button size="mini" round class="company-btn">新三板</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">91%</span>
                    <span class="right-num-money">4627<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>
              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===10}" @mouseenter="act_bg_index=10">
                <div style="display:flex;">
                  <div><img src="../assets/pic/jiebao.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">浙江杰豹机械股份有限公司</router-link></div>
                    <div class="company-address">浙江省临海市古城街道许墅村</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">高新技术企业</el-button>
                      <el-button size="mini" round class="company-btn">新三板</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">87%</span>
                    <span class="right-num-money">4213<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>
              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===4}" @mouseenter="act_bg_index=4">
                <div style="display:flex;">
                  <div><img src="../assets/pic/henjiu.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">江苏恒久机械股份有限公司</router-link></div>
                    <div class="company-address">徐州经济技术开发区杨山路99号</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">高新技术企业</el-button>
                      <el-button size="mini" round class="company-btn">新三板</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">75%</span>
                    <span class="right-num-money">6699<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>
              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===6}" @mouseenter="act_bg_index=6">
                <div style="display:flex;">
                  <div><img src="../assets/pic/ruiniu.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">上海瑞纽机械股份有限公司</router-link></div>
                    <div class="company-address">浦东新区宣桥镇园德路105号</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">行业百强企业</el-button>
                      <el-button size="mini" round class="company-btn">中国百强企业</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">70%</span>
                    <span class="right-num-money">6000<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>
              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===5}" @mouseenter="act_bg_index=5">
                <div style="display:flex;">
                  <div><img src="../assets/pic/zhonggong.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">锦阳众工机械股份有限公司</router-link></div>
                    <div class="company-address">锦阳高新区菩提寺村</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">高新技术企业</el-button>
                      <el-button size="mini" round class="company-btn">新三板</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">69%</span>
                    <span class="right-num-money">5070<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===9}" @mouseenter="act_bg_index=9">
                <div style="display:flex;">
                  <div><img src="../assets/pic/hanseng.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">常州汉森机械股份有限公司</router-link></div>
                    <div class="company-address">常州新北区环保四路89号</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">纳税大户</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">68%</span>
                    <span class="right-num-money">7260<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

              <div style="padding:15px;border-radius:10px; display:flex;justify-content:space-between;padding-top:15px;"
                :class="{active_bg_select:act_bg_index===8}" @mouseenter="act_bg_index=8">
                <div style="display:flex;">
                  <div><img src="../assets/pic/zhonggang.png" style="height:80px;weight:80px" alt=""></div>
                  <div style="margin-left:15px;">
                    <div class="company-title"><router-link to="/companydetail/1">天津重钢机械装备股份有限公司</router-link></div>
                    <div class="company-address">天津市滨海新区塘沽厦门路139号</div>
                    <div class="company-btn-div">
                      <el-button size="mini" round class="company-btn">纳税大户</el-button>
                      <el-button size="mini" round class="company-btn">高新技术企业</el-button>
                      <el-button size="mini" round class="company-btn">...</el-button>
                    </div>
                  </div>
                </div>
                <div style="margin-right:10px;">
                  <div style="display:flex;">
                    <span class="right-num-percent">50%</span>
                    <span class="right-num-money">7965<span class="right-num-money2">万</span></span>
                  </div>
                </div>
              </div>

            </div>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage2" :page-sizes="[10, 20, 50, 100]" :page-size="10"
              layout="sizes, prev, pager, next" :total="1000" style="padding:20px;">
            </el-pagination>
          </div>
        </el-col>
      </el-row>

    </el-main>

  </div>
</template>
<script>
const jineOptions = ["0-500万", "501-2000万", "2001-10,000万", "10,000万以上"];
const hangyeOptions = [
  "汽车工业",
  "房地产建筑",
  "化工日化",
  "机械设备",
  "家电数码",
  "建材合金",
  "纺织服装",
  "轻工业",
  "制造业",
  "高新技术",
  "农林牧渔",
  "科技服务",
  "电子产业",
  "服务业",
  "贸易行业",
  "开采业",
  "节能环保",
  "能源电力",
  "新材料产业",
  "文化服务业",
  "航空航天",
  "国防科技产业",
  "金融保险",
  "加工产业",
  "大数据",
  "工程机械",
  "基因技术",
  "军民融合",
  "汽车制造",
  "区块链",
  "人工智能",
  "生物医药",
  "新能源装备",
  "化工产业",
  "先进轨道交通装备",
  "新材料",
  "移动互联网",
  "装配式建筑",
  "电动汽车",
  "5G产业",
  "新信息产业",
  "高端装备产业",
  "无人机",
  "检验检测行业",
  "交通运输",
  "医疗医药",
  "视频饮料",
  "旅游酒店餐馆"
];
const diquOptions = [
  "上海",
  "河北",
  "辽宁",
  "黑龙江",
  "浙江",
  "福建",
  "山东",
  "湖北",
  "广东",
  "四川",
  "云南",
  "甘肃",
  "台湾",
  "广西",
  "重庆",
  "山西",
  "吉林",
  "江苏",
  "安徽",
  "江西",
  "河南",
  "湖南",
  "贵州",
  "海南",
  "陕西",
  "青海",
  "内蒙古",
  "西藏",
  "新疆"
];
const shijianOptions = ["近一个月", "近一年", "近五年"];
export default {
  name: "Guanzhulist",

  data() {
    return {
      currentPage2:1,
      show: false,
      isAuth: true,
      token: "",
      centerDialogVisible: false,
      value1: "",
      value2: "",
      squareUrl:
        "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      profile: "",
      hangyes: hangyeOptions,
      jinges: jineOptions,
      diqus: diquOptions,
      shijians: shijianOptions,
      checkboxGroup1: ["不限"],
      checkboxGroup2: ["不限"],
      checkboxGroup3: ["不限"],
      checkboxGroup4: ["不限"],
      currentPage1: 5,
      act_bg_index: 0,
      desc: true,
      desc_pic: require("../assets/pic/paixu_wu.png")
    };
  },
  created() {},
  components: {},
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    onDesc() {
      //console.log("3333333333")
      if (this.desc) {
        this.desc = false;
        this.desc_pic = require("../assets/pic/paixu_xia.png");
      } else {
        this.desc = true;
        this.desc_pic = require("../assets/pic/paixu_wu.png");
      }
    }
  }
};
</script>

<style scoped>
.company-red-btn {
  /* width:48px;
  height:12px; */
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(224, 32, 32, 1);
  line-height: 12px;
  border-radius: 50px;
  border: 1px solid rgba(224, 32, 32, 1);
}
.right-num-percent {
  /* width:39px; */
  height: 20px;
  font-size: 20px;
  font-family: DINPro-Bold, DINPro;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
}
.right-num-money {
  /* width:39px; */
  height: 20px;
  font-size: 20px;
  font-family: DINPro-Bold, DINPro;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
  line-height: 20px;
  margin-left: 70px;
}
.right-num-money2 {
  /* width:12px; */
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  line-height: 12px;
  margin-left: 3px;
}
.company-detail-list {
  text-align: left;
  padding: 15px;
}
.company-btn {
  background: rgba(246, 249, 248, 1);
}
.title-right {
  /* width:70px; */
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  line-height: 14px;
}
.title-left {
  /* width:105px; */
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 14px;
}
.company-list-down {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}
.list-title {
  padding: 20px;
}
.tag-content-left {
  /* width:56px; */
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 14px;
  padding-top: 10px;
}
.title-words {
  text-align: left;
  /* width:238px; */
  height: 24px;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  line-height: 24px;
}
.banner-tag {
  text-align: left;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px;
  background: white;
}
.company-address {
  /* width:200px; */
  height: 12px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  line-height: 12px;
  margin-top: 10px;
}
.company-title {
  /* width:270px; */
  height: 18px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 18px;
}
.company-btn-div {
  margin-top: 20px;
}
.desc-color {
  color: #004098 !important;
}
</style>