import axios from 'axios'
import * as Qs from 'qs'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import router from '@/router/index'
import {Loading, Message} from 'element-ui';
import {getToken} from "../util/auth"; 
var baseUrl = process.env.VUE_APP_BASE_API;
switch (process.env.VUE_APP_ENV) {
    case 'test': ;case 'development':  
        baseUrl = process.env.VUE_APP_BASE_API
        break;
    case 'prod': 
        baseUrl = process.env.VUE_APP_BASE_API+'/jeecg-boot'
        break;
    default: 
        baseUrl = process.env.VUE_APP_BASE_API
        break;
}
//请求超时设置(3分钟)
axios.defaults.timeout = 3 * 60 * 1000
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true
// NProgress Configuration
NProgress.configure({
    showSpinner: false
})
axios.interceptors.request.use(config => {
    NProgress.start() // start progress bar
    let token = getToken()
    if (token) {
        config.headers['X-Access-Token'] = token
    }


    return config;
}, err => {  //请求错误处理
    NProgress.done()
    Message({
        message: '请求错误',
        type: 'error'
    })
    Promise.reject(err)
});

let noAuthTipFlag = true

axios.interceptors.response.use((response) => {
    NProgress.done()
    const status = Number(response.status) || 200
    const message = response.data.message || '未知错误'
    const resCode = response.data.code || 200

    if (status === 401 || (status === 200 && resCode === 510)) {
        if (noAuthTipFlag) {
            noAuthTipFlag = false
            //未授权或者授权失败
            Message({
                message: message,
                type: 'error'
            })
            setTimeout(() => {
                router.replace({path: '/login'})
            }, 3000)
        }
        return Promise.reject(new Error(message))
    }

    // 如果请求为非200否者默认统一处理
    if (status !== 200) {
        Message({
            message: message,
            type: 'error'
        })
        return Promise.reject(new Error(message))
    } else if (resCode === 500) {
        Message({
            message: '系统错误',
            type: 'error'
        })
    }

    // if (loadingInstance1) loadingInstance1.close();

    noAuthTipFlag = true

    return response.data
}, (err) => {
    NProgress.done()
    if (/timeout/.test(err.message)) {
        Message({
            message: '请求超时',
            type: 'error'
        })
    }
    return Promise.reject(new Error(err))

    //debugger
    //return err
})


const reqHeaderForm = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

const reqHeaderJson = {
    headers: {
        'Content-Type': "application/json"
    }
}

//get
export function getAction(url, parameter) {
    return axios({
        url: baseUrl+url,
        method: 'get',
        params: parameter
    })
}

//post
export function postAction(url, parameter) {
    return axios({
        url:  baseUrl+url,
        method: 'post',
        data: parameter
    })
}

export function postFileAction(url, parameter) {
    return axios({
        url: baseUrl+url,
        method: 'post',
        data: parameter,
        headers: reqHeaderForm
    })
}

export function postFormAction(url, parameter) {
    return axios({
        url:  baseUrl+url,
        method: 'post',
        data: parameter,
        transformRequest: function (data) {
            return Qs.stringify(data)
        },
        headers: reqHeaderForm
    })
}

//post method= {post | put}
export function httpAction(url, parameter, method) {
    return axios({
        url: baseUrl+url,
        method: method,
        data: parameter
    })
}

//重点产业数据
//export const keyIndustryData = () => postAction('/pluginData/keyIndustryData')
export const keyIndustryData = () => getAction('/merchants/industryChainMap/findIndustryImg')
//字典标签专用（通过code获取字典数组）
export const ajaxGetDictItems = (code, params) => getAction(`/merchants/sys/dict/getDictItems/${code}`, params)
//获取分类字典数据
export const ajaxGetCategoryDictItems = (params) => getAction('/merchants/sys/category/loadTreeData', params)

//大数据引擎搜索结果
export const engineResult = (params) => postAction('/merchants/base/engine/result', params)
//更多企业信息
export const moreInfo = (key) => postAction('/merchants/base/engine/moreInfo/' + key)
//更新企业收藏状态
export const updateCollectStatus = (key, params) => postFormAction('/merchants/base/engine/updateCollectStatus/' + key, params)
//供应链数据
export const industryChainMapData = (params) => postFormAction('/merchants/industryChainMap/allTypes', params)
export const industryChainMapResultData = (params) => postAction('/merchants/industryChainMap/result', params)
//招商地图数据
export const investmentMapData = (params) => postAction('/merchants/investmentMap/data', params)
//招商地图获取10家企业

export const investmentMapGet10ByCity = (params) => getAction('/merchants/investmentMap/get10ByCity', params)
//export const investmentMapGet10ByCity = (params) => postAction('/app/companyQuery/result', params)
export const loadDepartMenu = (params) => postAction('/merchants/departConfig/currentDepartConfig', params)

export const login = (params) => postAction('/merchants/sys/login', params)

export const getHelpReport = (params) => getAction('/base/report/getHelpReport', params)
export const findMyIndustryChain =() =>getAction('/merchants/industryChainMap/findMyIndustryChain')
export const allIndustryChain = () =>getAction('/merchants/industryChainMap/allIndustryChain')
export const recommendIndustryChain = () =>getAction('/merchants/industryChainMap/recommendIndustryChain')
export const allIndustryChainDetail = () =>getAction('/merchants/industryChainMap/allIndustryChainDetail')

export default {
    login,
    baseUrl
}

