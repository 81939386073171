import { render, staticRenderFns } from "./privacyAgreement.vue?vue&type=template&id=58828636&scoped=true&"
import script from "./privacyAgreement.vue?vue&type=script&lang=js&"
export * from "./privacyAgreement.vue?vue&type=script&lang=js&"
import style0 from "./privacyAgreement.vue?vue&type=style&index=0&id=58828636&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58828636",
  null
  
)

export default component.exports