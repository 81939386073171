<template>
    <el-row>
        <el-row type="flex" justify="center">
            <el-col :span="16">
                <el-card class="me-search-result-el-card" v-loading="loading.searchResult">
                    <el-row>
                        <el-col :span="20">
                            共有 {{pagination.total}} 家企业
                        </el-col>
                        <!--                        <el-col :span="4" style="text-align: center;">-->
                        <!--                            <el-link :underline="false">匹配度排序<i class="el-icon-sort"></i></el-link>-->
                        <!--                            <el-link :underline="false">注册资金排序<i class="el-icon-sort"></i></el-link>-->
                        <!--                        </el-col>-->
                    </el-row>
                    <el-divider/>
                    <el-row v-if="pagination.total>0">
                        <el-col :xs="24" v-for="com in data.result"
                                style="padding: 5px;" :key="com.id">
                            <el-card shadow="never" class="me-search-result-item-el-card">

                                <div style="float:left;width: 70px;margin-top: 6px;line-height: 70px;">
                                    <img class="logo" :src="com.logo?com.logo:require('@/assets/pic/defalut_building.png')" style="vertical-align: middle;"/>
                                </div>
                                <div style="width: calc(100% - 85px);margin-left: 85px;">
                                    <el-row>
                                        <el-col :span="20">
                                            <el-link :underline="false" @click="gotoDetal(com)"
                                                     style="font-weight: bold;font-size: 16px;margin-bottom: 3px;">
                                                {{com.name}}
                                            </el-link>
                                            <el-tooltip>
                                                <div slot="content">{{com.regLocation}}</div>
                                                <div style="color: #aaa;font-size: 13px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                                                    {{com.regLocation}}
                                                </div>
                                            </el-tooltip>
                                            <div style="font-size: 14px;margin-top:15px;" v-if="com.bsData">
                                                <el-button v-for="tag in com.bsData.tags" :key="tag" type="info" size="mini"
                                                           style="cursor:default;" plain
                                                           round disabled>
                                                    {{tag}}
                                                </el-button>
                                                <el-button v-if="com.bsData.domain"   type="info" size="mini"
                                                           style="cursor:default;" plain
                                                           round disabled>
                                                    {{com.bsData.domain}}
                                                </el-button>
                                                <el-button v-if="com.bsData.status"   type="info" size="mini"
                                                           style="cursor:default;" plain
                                                           round disabled>
                                                    {{com.bsData.status}}
                                                </el-button>
                                                
                                            </div>
                                        </el-col>
                                        <el-col :span="4" style="text-align: center;padding: 30px 0px;">
                                            <span style="font-size: 18px;font-weight: bold;">
                                                <span v-if="com.sp">{{new Number(com.sp*100*0.5).toFixed(0)+'%'}}</span>
                                                <span v-else> - </span>
                                            </span>
                                            <el-divider direction="vertical"></el-divider>
                                            <span v-if="com.cap&&com.cap!='-'">
                                                <span style="font-size: 18px;font-weight: bold;">{{/\d+(\.\d+)?/.exec(com.cap)[0]}}</span>
                                            <span style="font-size: 11px;">{{com.cap.replace(/\d+(\.\d+)?/g,'')}}</span>
                                            </span>
                                            <span v-else> - </span>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                    <el-row v-else>
                        <el-col :span="24"><h3 style="text-align: center">无结果</h3></el-col>
                    </el-row>
                    <el-divider v-if="pagination.total>0"/>
                    <el-pagination v-if="pagination.total>0" v-bind="pagination"
                                   @size-change="handlePaginationSizeChange"
                                   @current-change="handlePaginationCurrentChange"
                                   class="me-search-result-el-pagination">
                    </el-pagination>
                </el-card>
            </el-col>
        </el-row>
    </el-row>
</template>
<script>
    import City from "@/components/dict/city"
    import {initDictOptions, filterDictText} from '@/components/dict/JDictSelectUtil'
    import {engineResult, getAction} from "../api/api";


    export default {
        components: {},
        data() {
            return {
                loading: {
                    icmData: false,
                    searchResult: false
                },
                referer: {
                    source: ''
                },
                elCascaderProps: {
                    emitPath: false,
                    checkStrictly: true,
                    expandTrigger: 'hover',
                    label: 'text',
                    value: 'text'
                    // lazy: true,
                    // lazyLoad: (node, resolve) => {
                    //     // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                    //     if (node.root) {
                    //         //异步加载数据
                    //
                    //
                    //     } else {
                    //         resolve([]);
                    //     }
                    // }
                },
                form: {
                    search: {
                        kw: '',
                        sCapital: {
                            notLimited: true,
                            value: []
                        },
                        sCat: {
                            notLimited: true,
                            value: []
                        },
                        sProvince: {
                            notLimited: true,
                            value: []
                        }
                    }
                },
                rules: {
                    search: {
                        searchKeyWord: [{required: true, message: '请输入搜索关键词!', trigger: 'blur'}]
                    }
                },
                data: {
                    icm: [],
                    result: {}
                },
                pagination: {
                    background: true,
                    layout: "sizes, prev, pager, next",
                    pageSizes: [10, 20, 50, 100],
                    pageSize: 10,
                    pagerCount: 5,
                    currentPage: 1,
                    total: 0
                },
                dictOptions: {
                    dictCapital: [],
                    dictCat: [],
                    dictProvince: []
                },
                search: {
                    dictCapital: [],
                    dictCat: [],
                    dictProvince: []
                },
                url: {
                    list: '/merchants/merchantsFavoriteCompany/list'
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            if (from.path.indexOf("/merchantsEngineDetail") > -1) {
                to.meta.isBack = true;
            } else {
                to.meta.isBack = false;
            }
            next();
        },
        // activated() {
        //     if (!this.$route.meta.isBack) {
        //         this.pagination.currentPage = 1
        //         this.initDictConfig()
        //         this.loadSearchResult(this.$route.params.status)
        //     } else {
        //         this.$route.meta.isBack = false;
        //     }
        // },
        created() {
            this.pagination.currentPage = 1
            this.initDictConfig()
            this.$nextTick(() => {
                this.loadSearchResult(this.$route.query.status)
            })
        },
        methods: {
            getRandomDefLogo() {
                let ran = Math.ceil(Math.random() * 15).toString(16)
                return require(`../assets/pic/deflogo${ran}.png`)
            },
            gotoDetal(info) {
                // let routeUrl = this.$router.resolve({
                //     path: "/merchantsEngineDetail/" + id
                // });
                // window.open(routeUrl.href, '_blank');
                // this.$router.push({
                //     path: "/merchantsEngineDetail/" + info.id,
                //     query: {
                //         sp: info.sp
                //     }
                // })

                let routeUrl = this.$router.resolve({
                    path: "/merchantsEngineDetail/" + info.id,
                    query: {
                        sp: new Number(info.sp)*0.5
                    }
                });
                window.open(routeUrl.href, '_blank');

            },
            loadSearchResult(status) {
                let that = this
                that.loading.searchResult = true
                that.search.status = status
                that.search.pageSize = this.pagination.pageSize
                that.search.currentPage = this.pagination.currentPage
                getAction(this.url.list, that.search).then((res) => {
                    that.loading.searchResult = false
                    if (res.success) {
                        that.data.result = res.data
                        that.pagination.total = res.count
                    } else {
                        Message({
                            title: "提示",
                            message: res.message,
                            type: "error",
                            duration: 1500
                        });
                    }
                }).catch(() => {
                    that.loading.searchResult = false
                })
            },
            initDictConfig() {

                initDictOptions('zs_condition_registered_capital').then((res) => {
                    if (res.success) {
                        this.$set(this.dictOptions, 'dictCapital', res.result)
                    }
                })
                initDictOptions('zs_condition_registered_cat').then((res) => {
                    if (res.success) {
                        this.$set(this.dictOptions, 'dictCat', res.result)
                    }
                })

                //序列化省份
                for (let key in City.province_list) {
                    this.dictOptions.dictProvince.push({
                        value: City.province_list[key],
                        text: City.province_list[key],
                        title: City.province_list[key]
                    })
                }
            },
            handleCascaderChange() {
                let query = this.$router.history.current.query;
                let path = this.$router.history.current.path;
                //对象的拷贝
                let newQuery = JSON.parse(JSON.stringify(query));
                newQuery.kw = this.form.search.kw;
                this.$router.push({path, query: newQuery});
                this.loadSearchResult(this.$route.query.status)
            },
            handleNotLimitedChange(checked, formItemName) {
                if (checked) {
                    this.form.search[formItemName].value = []
                } else {
                    this.form.search[formItemName].notLimited = true
                }
                this.loadSearchResult(this.$route.query.status)
            },
            handleCheckboxChange(checked, formItemName) {

                if (checked) {
                    this.form.search[formItemName].notLimited = false
                } else {
                    if (this.form.search[formItemName].value.length === 0) {
                        this.form.search[formItemName].notLimited = true
                    }
                }
                this.loadSearchResult(this.$route.query.status)
            },
            handlePaginationSizeChange(pageSize) {
                this.pagination.pageSize = pageSize
                this.loadSearchResult(this.$route.query.status)
            },
            handlePaginationCurrentChange(currentPage) {
                this.pagination.currentPage = currentPage
                this.loadSearchResult(this.$route.query.status)
            },
            handleSearchSubmit(formName) {
                let that = this
                that.$refs[formName].validate((valid) => {
                    if (valid) {
                        console.log(this.form.search)
                        // that.loading.searchResult = true
                        // setTimeout(() => {
                        //     // that.loading.searchResult = false
                        //     // alert('submit!');
                        // }, 3000)
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    }

</script>


<style scoped>
    * {
        text-align: initial;
    }

    .me-search-el-form-item {
        margin: 5px 0px;
    }

    .me-search-el-card {
        margin: 30px 0px;
    }

    .me-search-result-el-card {
        margin: 20px 0px;
    }

    .me-search-result-item-el-card {
        border: 0px;
    }

    .me-search-result-item-el-card:hover {
        /*border: 1px solid rgba(0, 64, 152, 0.0862745098039216);*/
        background-color: rgba(0, 64, 152, 0.1);
    }

    .me-search-result-el-pagination {

    }
     .logo{
        width:70px;
        max-height:70px;
    }
</style>
