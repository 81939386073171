<template>
    <el-row>
        <el-row type="flex" justify="center">
            <el-col :xs="24" :sm="22" :md="20" :lg="18" :xl="16">
                <el-main v-loading="loading.rootData">
                    <el-container>
                        <el-aside width="200px">
                            <el-card :body-style="{padding:0}">
                                <div slot="header">
                                    <h5>我的产业链</h5>
                                </div>
                                <el-menu :default-active="form.tn" style="border:none">
                                    <el-menu-item v-for="(fir,index) in data" :key="index"
                                                  style="    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;"
                                                  :index="fir.name" @click="activeICM(fir.name)">
                                        <i class="el-icon-s-flag"></i>
                                        <span slot="title">{{fir.name}}</span>
                                    </el-menu-item>
                                </el-menu>
                            </el-card>
                        </el-aside>
                        <el-container>
                            <el-main style="padding: 0px 20px;">
                                <el-card v-if="!visible.right">
                                    <img src="../assets/pic/xitu.png" class="image" style="width: 100%;">
                                </el-card>
                                <el-card v-else>
                                    <el-row class="com-m-b-20">
                                        <el-col :span="8">
                                            <el-input v-model="form.search.kw" placeholder="输入节点进行定位" disabled>
                                                <i slot="suffix" class="el-icon-search el-input__icon"></i>
                                            </el-input>
                                        </el-col>
                                    </el-row>

                                    <div v-if="data && data.length>0"
                                         v-for="(sec,secIndex) in rightData"
                                         :key="secIndex">
                                        <div :class="classs[secIndex]" class="custom-tag-div com-m-b-10"
                                             style="font-weight: bold">
                                            {{sec.name}}
                                        </div>
                                        <div :class="classs[secIndex]" class="custom-tag-div com-m-b-10"
                                             style="padding: 15px;width: 100%;" >
                                             <section v-if=" sec.children[0].hasOwnProperty('children') && sec.children[0].children.length>0"> 
                                                <div :class="classs[secIndex]" class="custom-tag-div"
                                                     style="background-color: white;margin: 5px;vertical-align: top;text-align: center;    padding: 0 8px;"
                                                       :style="renderNodeStyle(secIndex,thi.isHigh)"
                                                       v-for="(thi,thiIndex) in sec.children" :key="thiIndex" plain
                                                        >
                                                        <div :class="classs[secIndex]" 
                                                            type="text" 
                                                            class="custom-tag-btn"
                                                            :style="renderFourStyle(true,thi.isHigh,secIndex)" 
                                                            
                                                            >
                                                                {{thi.name}}
                                                        </div>
                                                        <div :class="classs[secIndex]" 
                                                            type="text" 
                                                            class="custom-tag-btn"
                                                            :ref="'renderFour'+four.name"
                                                            :style="renderFourStyle(false,four.isHigh,secIndex)"
                                                            @mouseover="mouseFourStyle(true,fourIndex,secIndex,four.name,four.isHigh)"
                                                            @mouseleave="mouseFourStyle(false,fourIndex,secIndex,four.name,four.isHigh)"
                                                            v-for="(four,fourIndex) in sec.children[thiIndex].children" :key="fourIndex" plain
                                                            @click="gotoResult(four.name)">
                                                                {{four.name}}
                                                        </div>
                                                </div>
                                             </section>
                                             <section v-else>
                                                <el-button :class="classs[secIndex]" class="custom-tag-div"
                                                     style="background-color: white;margin: 5px;"
                                                       :style="renderNodeStyle(secIndex,thi.isHigh)"
                                                       v-for="(thi,thiIndex) in sec.children" :key="thiIndex" plain
                                                       @click="gotoResult(thi.name)">
                                                    {{thi.name}}
                                                </el-button>
                                             </section>
                                        </div>
                                    </div>
                                </el-card>
                            </el-main>
                        </el-container>
                    </el-container>
                </el-main>
            </el-col>
        </el-row>
    </el-row>
</template>
<script>
    import {allIndustryChainDetail} from "../api/api";

    let base64 = require('js-base64').Base64
    
    let tmpArr = [
        'custom-tag-div-defc',
        'custom-tag-div-warnc',
        'custom-tag-div-danc',
        'custom-tag-div-four',
        'custom-tag-div-five',
        'custom-tag-div-six',
    ]
    let customColors = ['#004098', '#ED7D31', '#E02020', '#0091FF', '#815EDB', '#20CAE0', '#004098', '#ED7D31', '#E02020', '#004098', '#ED7D31', '#E02020', '#004098', '#ED7D31', '#E02020', '#004098', '#ED7D31', '#E02020', '#004098', '#ED7D31', '#E02020', '#004098', '#ED7D31', '#E02020']
    let customClasss = []
    let currIndex = 0
    for (let i = 0; i < 100; i++, currIndex++) {
        currIndex = currIndex > tmpArr.length - 1 ? 0 : currIndex
        customClasss.push(tmpArr[currIndex])
    }

    export default {
        components: {},
        data() {
            return {
                visible: {
                    right: true,
                },
                classs: customClasss,
                customColors,
                loading: {
                    rootData: false
                },
                form: {
                    search: {
                        kw: ''
                    }
                },
                data: [],
                rightData: []
            }
        },
        created() {
            Object.assign(this.form, this.$route.query)
            this.loadDefaultData()
        },
        methods: {
            renderNodeStyle(index, isHigh) {
                if (!isHigh) {
                    return {
                        color: customColors[index] + '66',
                        borderColor: customColors[index] + '66'
                    }
                }
                return {}
            },
            renderFourStyle(isNode, isHigh,secIndex) { 
                if (isNode) {
                    return {
                        backgroundColor:customColors[secIndex],
                        color:'#ffffff',
                    }
                }else{
                     if (!isHigh) {
                        return {
                            color: customColors[secIndex] + '66',
                        }
                    }else{
                         return {
                                color:customColors[secIndex],
                            } 
                    }
                }
                 
               
                
            },
            mouseFourStyle(isOver,isFirst,index,name,isHigh){
                
                let renderFour = this.$refs['renderFour'+name][0]; 
                if(isOver){
                    //鼠标划入
                    renderFour.style.backgroundColor = customColors[index];
                    renderFour.style.color='#ffffff'
                }else{
                    //鼠标划出
                    renderFour.style.backgroundColor = '#ffffff';
                    if (!isHigh) {
                        renderFour.style.color = customColors[index]+ '66'
                    }else{
                        renderFour.style.color = customColors[index]
                    }
                }
                
            },
            gotoResult(typeName) {
                let title = this.form.tn.substring(0,this.form.tn.length-2);
                let routeUrl = this.$router.resolve({
                    path: "/merchantsEngineResult",
                    query: {
                        searchType: 2,
                        kw: typeName,
                        source: 'icm',
                        title:title
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            activeICM(type) {
                if (this.data.length > 0 && type) {
                    if (this.data.length > 0) {
                        let findResult = this.data.filter(o => {
                            return o.name === type
                        })
                        this.visible.right = true
                        if (findResult.length === 0) {
                            // this.$message.warning("产业链不存在")
                            this.visible.right = false
                            return
                        }
                        this.rightData = findResult[0].children
                        this.form.tn = type
                        this.$router.push({
                            query: {
                                tn: base64.encode(type)
                            }
                        })
                    }
                }

            },
            loadDefaultData() {
                let that = this;
                that.loading.rootData = true
                allIndustryChainDetail().then(res => {
                    if (res.success && res.result && res.result.length > 0) {
                        that.data = res.result
                        that.activeICM(base64.decode(that.form.tn))
                        that.loading.rootData = false
                    } else {
                        that.loading.rootData = false
                        that.visible.right = false
                    }
                }).catch(() => {
                    this.loading.rootData = false
                })
            }
        }
    }

</script>


<style scoped>
    * {
        text-align: initial;
    }
    .custom-tag-btn{
        background-color: white;
        display: block;
        /* width:100%; */
        margin:0 auto;
        text-align: center;
        padding:0px 8px ; 
        font-size: 12px;
        border-radius: 4px;
        box-sizing: border-box;
        cursor:pointer;
        min-width: 128px;
        margin-top:1px;
    }
    .custom-tag-btn:first-child{
        margin-top:8px;
    }
    .custom-tag-btn:last-child{
        margin-bottom:8px;
    }
    .custom-tag-div {
        display: inline-block;
        padding: 0 10px;
        line-height: 30px;
        font-size: 12px;
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        /*white-space: nowrap;*/
    }

    .custom-tag-div.custom-tag-div-defc {
        background-color: #ecf5ff;
        border-color: #d9ecff;
        color: #004098;
    }

    .custom-tag-div .el-button.is-disabled {
        color: #C0C4CC;
        cursor: not-allowed;
        background-image: none;
        background-color: #FFF;
        border-color: #EBEEF5
    }

    .custom-tag-div.custom-tag-div-defc.el-button:focus, .custom-tag-div.custom-tag-div-defc.el-button {
        border-color: #004098;
    }

    .custom-tag-div.custom-tag-div-defc.el-button:focus, .custom-tag-div.custom-tag-div-defc.el-button:hover {
        color: #004098ff !important;
        /*border-color: #004098ff !important;*/
        font-weight: bold;
    }

    .custom-tag-div.custom-tag-div-warnc {
        background-color: #fdf6ec;
        border-color: #faecd8;
        color: #ED7D31;
    }

    .custom-tag-div.custom-tag-div-warnc.el-button:focus, .custom-tag-div.custom-tag-div-warnc.el-button {
        border-color: #ED7D31;
    }

    .custom-tag-div.custom-tag-div-warnc.el-button:focus, .custom-tag-div.custom-tag-div-warnc.el-button:hover {
        color: #ED7D31ff !important;
        /*border-color: #ED7D31ff !important;*/
        font-weight: bold;
    }

    .custom-tag-div.custom-tag-div-danc {
        background-color: #fef0f0;
        border-color: #fde2e2;
        color: #E02020
    }

    .custom-tag-div.custom-tag-div-danc.el-button:focus, .custom-tag-div.custom-tag-div-danc.el-button {
        border-color: #E02020;
    }

    .custom-tag-div.custom-tag-div-danc.el-button:focus, .custom-tag-div.custom-tag-div-danc.el-button:hover {
        color: #E02020 !important;
        /*border-color: #E02020 !important;*/
        font-weight: bold;
    }
    .custom-tag-div.custom-tag-div-four {
        background-color: rgba(0, 145, 255, 0.05);
        border-color: rgba(0, 145, 255, 0.05);
        color: #0091FF
    }

    .custom-tag-div.custom-tag-div-four.el-button:focus, .custom-tag-div.custom-tag-div-four.el-button {
        border-color: #0091FF;
    }

    .custom-tag-div.custom-tag-div-four.el-button:focus, .custom-tag-div.custom-tag-div-four.el-button:hover {
        color: #0091FF !important;
        /*border-color: #0091FF !important;*/
        font-weight: bold;
    }
    .custom-tag-div.custom-tag-div-five {
        background-color: rgba(129, 94, 219, 0.1);
        border-color: rgba(129, 94, 219, 0.1);
        color: #815EDB
    }

    .custom-tag-div.custom-tag-div-five.el-button:focus, .custom-tag-div.custom-tag-div-five.el-button {
        border-color: #815EDB;
    }

    .custom-tag-div.custom-tag-div-five.el-button:focus, .custom-tag-div.custom-tag-div-five.el-button:hover {
        color: #815EDB !important;
        /*border-color: #815EDB !important;*/
        font-weight: bold;
    }
    .custom-tag-div.custom-tag-div-six {
        background-color: rgba(32, 202, 224, 0.1);
        border-color: rgba(32, 202, 224, 0.1);
        color: #20CAE0
    }

    .custom-tag-div.custom-tag-div-six.el-button:focus, .custom-tag-div.custom-tag-div-six.el-button {
        border-color: #20CAE0;
    }

    .custom-tag-div.custom-tag-div-six.el-button:focus, .custom-tag-div.custom-tag-div-six.el-button:hover {
        color: #20CAE0 !important;
        /*border-color: #20CAE0 !important;*/
        font-weight: bold;
    }
    .com-m-b-10 {
        margin-bottom: 10px;
    }

    .com-m-b-20 {
        margin-bottom: 20px;
    }
</style>
