<template>
  <div class="agreement" >
    <h1 align="center">隐私政策</h1>
    <p style="text-indent:2em;"> 请您在开始使用我们的产品（一下简称“本产品”）之前请务必仔细阅读并理解《隐私政策》（以下简称“本政策”）。</p>
    <p style="text-indent:2em;">
      我们一向尊重并会严格保护用户在使用本产品时的合法权益（包括用户隐私、用户数据等）。我们力求明确说明我们是如何收集、使用、披露、传送及存储您的信息的。虽然本政策适用于我们的所有服务，但我们也针对特定服务制定了某些明确的某些明确的隐私权补充条文，其中包含有关我们的信息处理做法的其他信息。当我们进行其它数据收集处理活动时会另外取得您的同意。请务必仔细阅读本隐私政策，因为只要您使用我们的服务，就表示您同意隐私政策及补充条文中所描述的做法，如果您不同意隐私政策中所描述的做法，请不要使用我们的服务。
    </p>
    <p style="text-indent:2em;"> 您可以通过多种不同的方式使用我们的服务。基于此目的，我们将向您解释我们对信息的收集和使用方式，以及您可采用什么方式来保护自己的隐私权。</p>
    <p style="text-indent:2em;"> 我们的隐私政策包括了以下几个方面的问题：</p>
    <p style="text-indent:2em;"> 我们收集哪些信息</p>
    <p style="text-indent:2em;"> 我们如何收集和使用信息</p>
    <p style="text-indent:2em;"> 您如何访问和控制自己的个人信息</p>
    <p style="text-indent:2em;"> 信息的分享、安全以及隐私政策的使用范围和变更</p>
    <p> 我们会收集哪些信息</p>
    <p style="text-indent:2em;"> l &nbsp;您通过我们的服务向其他方提供的共享信息，以及您使用我们服务过程中收集的信息；</p>
    <p style="text-indent:2em;"> l &nbsp;除了您提供的信息外，我们还可能通过您设备中的软件以及其他方式来收集您使用我们服务的相关信息，如设备信息、日志信息、位置信息等；</p>
    <p> 我们如何收集和利用信息</p>
    <p style="text-indent:2em;"> 个人信息的收集和使用</p>
    <p style="text-indent:2em;">
      您与我们及其关联公司进行互动时，您可能会被要求提供您同意使用我们基本产品服务所必需的某些个人信息。该个人信息可能与其他信息合并在一起，被用于改进我们的产品或服务等。下文是我们可能收集的个人信息的类型以及我们如何使用该信息的一些示例。
    </p>
    <p style="text-indent:2em;"> 个人信息的收集</p>
    <p style="text-indent:2em;"> 当您创建APP账户、参加在线调查或者参加其他与我们互动时。</p>
    <p style="text-indent:2em;"> 个人信息的使用</p>
    <p style="text-indent:2em;"> 我们将严格遵守本隐私政策及其更新所载明的内容来使用您的个人信息。您的个人信息将仅用于收集时即已确定并且经过您同意的目的，如有除此之外的任何其他用途，我们都会提前征得您的同意。
    </p>
    <p style="text-indent:2em;"> l &nbsp;向您提供服务；</p>
    <p style="text-indent:2em;"> l &nbsp;为您的设备提供维护服务；</p>
    <p style="text-indent:2em;"> l &nbsp;在法律允许的情况下进行免费抽奖、有奖竞赛或促销；</p>
    <p style="text-indent:2em;"> l &nbsp;在我们提供服务时，用于身份验证、客户服务、安全规范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；</p>
    <p style="text-indent:2em;"> l &nbsp;帮助我们设计新服务，改善我们现有服务；</p>
    <p style="text-indent:2em;"> l &nbsp;软件认证或管理软件升级；</p>
    <p style="text-indent:2em;"> l &nbsp;让您参与有关我们产品和服务的调查；</p>
    <p style="text-indent:2em;"> 非个人信息的收集和使用</p>
    <p style="text-indent:2em;"> 同时，为了运营和改善我们的技术和服务，我们将会自行收集使用您的非个人信息，这将有助于我们向您提供更好的用户体验和提高服务质量。</p>
    <p style="text-indent:2em;"> 非个人信息的收集</p>
    <p style="text-indent:2em;">
      日志信息—当您使用我们的服务等时，我们的服务器会自动记录某些日志信息。这类服务器日志信息可能包含如下信息：IP地址、浏览器类型、浏览器语言、refer来源页、操作系统、日期/时间标记和点击流数据。</p>
    <p> Cookie的使用</p>
    <p style="text-indent:2em;">
      Cookie技术：您访问我们平台相关网站或使用我们官网平台提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做是帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全。这些数据文件可能是Cookie，FlashCookie，或您的浏览器或关联应用程序提供的其他本地存储（统称“Cookie”）。
    </p>
    <p style="text-indent:2em;"> l &nbsp;在您未拒绝接受Cookies的情况下，本应用会在您的计算机上设定或取用Cookies
      ，以便您能登录或使用依赖于Cookies的本应用平台服务或功能。本应用使用Cookies可为您提供更加周到的个性化服务，包括推广服务。</p>
    <p style="text-indent:2em;"> l
      &nbsp;您有权选择接受或拒绝接受Cookies。您可以通过修改浏览器设置的方式拒绝接受Cookies。但如果您选择拒绝接受Cookies，则您可能无法登录或使用依赖于Cookies的本应用网络服务或功能。</p>
    <p style="text-indent:2em;"> l &nbsp;通过本应用所设Cookies所取得的有关信息，将适用本政策。</p>
    <p> 您如何访问和控制自己的个人信息</p>
    <p style="text-indent:2em;">
      我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。</p>
    <p> 信息的分享、安全以及隐私政策的使用范围和变更</p>
    <p style="text-indent:2em;"> 我们如何共享信息</p>
    <p> 除非本隐私政策载明的有限共享，我们会严格保密您的个人信息。未经您的同意，我们将不会向第三方披露您的信息以用于其各自的独立市场营销或商业用途。您于此授权，以下情形我们将会向第三方共享您的个人信息而无需通过您的同意：</p>
    <p style="text-indent:2em;"> l
      &nbsp;业务合作伙伴。我们也可能与可信的业务合作伙伴（包括无线运营商）共享您的信息。这些实体可能使用您的信息来提供您请求的服务、对您的喜好进行预测、并可能向您提供促销材料、广告以及其他材料。</p>
    <p style="text-indent:2em;"> l
      &nbsp;服务提供商。我们也可能向为我们或代表我们提供服务的公司披露您的信息，如协助我们处理账单或代表我们发送电子邮件的公司，但这些实体只能将您的信息用于为我们提供服务。</p>
    <p style="text-indent:2em;"> l &nbsp;法律要求或保护我们的服务所必需的其他各方。在有些情况下我们可能会向其他各方披露您的信息；</p>
    <p style="text-indent:2em;"> l &nbsp;遵守法律或服从强制性的法律程序（如搜查证或其他法院命令）；</p>
    <p style="text-indent:2em;"> l &nbsp;确认或强制遵循我们的服务策略；以及保护我们及我们的各个附属公司、业务合作伙伴或客户的权利、财产或安全；</p>
    <p style="text-indent:2em;"> l &nbsp;与公司事务有关的其他各方。在公司合并、转让或破产过程中，我们可能会向第三方披露您的信息；</p>
    <p style="text-indent:2em;"> l &nbsp;征得您同意的其他各方。除本政策中描述的披露情况之外，我们也可能在您同意共享或提出共享请求时与第三方共享您的信息；</p>
    <p> 我们如何确保您的信息安全</p>
    <p style="text-indent:2em;"> l &nbsp;您的账户均有安全保护功能，请妥善保管您的账户及密码信息。我们仅在本《隐私政策》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息；</p>
    <p style="text-indent:2em;"> l
      &nbsp;我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题；
    </p>
    <p style="text-indent:2em;"> l
      &nbsp;我们只允许那些为了帮我们处理个人信息而需要知晓这些信息的员工、授权代为处理服务公司的人员访问个人信息，而且他们需要履行严格的合同保密义务，如果其未能履行这些义务，就可能会被追究法律责任或被终止其与我们的关系；</p>
    <p style="text-indent:2em;"> l
      &nbsp;对我们来说，您的信息的安全非常重要。因此，我们将不断努力保障您的个人信息安全，并实施存储和传输全程安全加密等保障手段、以免您的信息在未经授权的情况下被访问、使用或被披露。同时某些加密数据的具体内容，除了用户自己，其他人都无权访问。
    </p>
    <p> 对儿童个人信息的收集和使用</p>
    <p style="text-indent:2em;"> 我们建议13周岁以下的儿童在其法定监护人的许可和指导下使用产品或服务。</p>
    <p style="text-indent:2em;">
      我们不会在明知对方为儿童的情况下收集其个人信息，也不会向任何第三方透露其个人信息，监护人有权拒绝我们及其关联公司进一步收集被监护人的个人信息，或要求我们及关联公司删除该被监护人个人信息。如果我们发现我们收集了年龄不满13周岁的儿童的个人信息，我们将采取措施尽快地删除此等信息。
    </p>
    <p> 变更</p>
    <p style="text-indent:2em;">
      我们可能适时修订本《隐私政策》的条款，该修订构成本《隐私政策》的一部分。如该等修订造成您在本《隐私政策》下权利的实质减少，我们将在修订生效前通过在主页显著位置提示或者向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。
    </p>

  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  //创建前设置
beforeCreate () {
      document.querySelector('body').setAttribute('style', 'min-width:0px !important;')
       document.querySelector('html').setAttribute('style', 'min-width:0px !important;')
},
//销毁前清除
beforeDestroy () {
      document.querySelector('body').removeAttribute('style')
      document.querySelector('html').removeAttribute('style')
},
  created() {},
  mounted() {},
  methods: {},
  computed: {}
};
</script>

<style lang="scss" scoped>
.agreement{
    width: 80%;
    margin:50px auto;
}
</style>
