<template>
    <div>
        <!-- banner大图 -->
        <div class="banner-pic" style="text-align:left">
            <el-main style="background:transparent;text-align:left;">
                <el-row type="flex" class="row-bg" justify="center">
                    <el-col :span="16">
                        <h1 style="padding-top:50px;padding-bottom:30px">项目库</h1>
                        <span class="banner-pic-words">汇集各种企业的投资需求</span>
                        <div class="banner-search">
                            <span>关键词搜索</span>
                            <div class="banner-search-input">
                                <input class="banner-search-input-box" type="text" placeholder="搜索 “ 电子信息 ” 试试吧"
                                       v-model="search.name" size="60" style="padding:14px 10px">
                                <el-button class="search-submit" @click="searchQuery()" type="primary">搜索</el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>

            </el-main>
        </div>

        <!-- tag标签栏 -->
        <el-main style="background-color:#F5F7F9">
            <el-row type="flex" class="row-bg" justify="center">
                <el-col :span="16">
                    <div class="tag-div">
                        <el-row :gutter="24">
                            <el-col :md="24" :sm="24">
                                <search-select :renderData="demandTypes" v-model="search.demandTypes"
                                               allText="不限" title="需求类型" :reset="search.reset"></search-select>
                            </el-col>
                            <el-col :md="24" :sm="24">
                                <search-select :renderData="industrys" v-model="search.industrys"
                                               allText="不限" title="所属产业" :reset="search.reset"></search-select>
                            </el-col>
                            <el-col :md="24" :sm="24">
                                <search-select :renderData="investmentAmountsRmb" v-model="search.investmentAmountsRmb"
                                               allText="不限(人民币)" title="投资金额" :reset="search.reset"></search-select>
                            </el-col>
                            <el-col :md="24" :sm="24">
                                <search-select :renderData="investmentAmountsMy" v-model="search.investmentAmountsMy"
                                               allText="不限(美元)" title="" :reset="search.reset"></search-select>
                            </el-col>
                            <el-col :md="24" :sm="24">
                                <search-select :renderData="intentAreas" v-model="search.intentAreas"
                                               allText="全国" title="意向地区" :reset="search.reset"></search-select>
                            </el-col>
                            <!--                            <el-col :md="24" :sm="24">-->
                            <!--                                <search-select :renderData="releaseTimes" v-model="search.releaseTimes"-->
                            <!--                                               allText="不限" title="发布时间" :reset="search.reset"></search-select>-->
                            <!--                            </el-col>-->
                            <!--                            <el-col :md="24" :sm="24">-->
                            <!--                                <search-select :renderData="status" v-model="search.status"-->
                            <!--                                               allText="不限" title="有效状态" :reset="search.reset"></search-select>-->
                            <!--                            </el-col>-->
                            <el-col :md="24" :sm="24" style="padding-top:20px;">
                                <el-button type="primary" icon="el-icon-search" @click="searchQuery()">搜索</el-button>
                                <el-button type="primary" icon="el-icon-refresh" @click="clearSearchParam()">清空
                                </el-button>
                            </el-col>
                        </el-row>
                    </div>
                    <!-- detail-div -->
                    <div class="project-detail" v-loading="loading">
                        <div class="count" style="text-align:left;padding-left:20px;padding-top:30px;">
                            <span>共有{{total}}个项目</span></div>
                        <el-divider></el-divider>

                        <div class="detail-words" v-for="(item,index) in listData" :key="index">
                            <div>
                                <el-row :gutter="10" class="detail-content-div">
                                    <el-col :span="15">
                                        <div class="grid-content">
                                            <el-row :gutter="5" style="text-align:left;">
                                                <el-col :span="2">
                                                    <img src="../assets/pic/title_pic1.png"
                                                         v-if="item.demand_type=='资金'" class="detail-words-pic">
                                                    <img src="../assets/pic/title_pic2.png"
                                                         v-if="item.demand_type=='土地'" class="detail-words-pic">
                                                    <img src="../assets/pic/title_pic3.png"
                                                         v-if="item.demand_type=='厂房'" class="detail-words-pic">
                                                    <img src="../assets/pic/title_pic4.png"
                                                         v-if="item.demand_type=='仓库'" class="detail-words-pic">
                                                </el-col>
                                                <el-col :span="18" style="margin-left:60px;">
                                                    <div class="detail-words-title"><a @click="detail(item)" :title="item.name">{{item.name | ellipsis}}</a>
                                                    </div>
                                                    <div class="detail-words-tag"><span class="left-tag">所属产业</span>
                                                        <span class="detail-words-gray">{{item.industry}}</span></div>
                                                    <div class="detail-words-tag"><span class="left-tag">目标地区</span>
                                                        <span class="detail-words-gray">{{item.intent_area}}</span>
                                                    </div>
                                                    <div class="detail-words-tag"><span class="left-tag">时间</span> <span
                                                            class="detail-words-gray">{{item.effective_begin_time}} ~{{item.effective_end_time}}</span>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </el-col>
                                    <el-col :span="3">
                                        <div class="grid-content detail-money-div">
                                            <div><span class="detail-money-num-big">{{unitConvert(item.investment_amount).num}}</span>
                                                <span
                                                        class="detail-money-num-small">{{unitConvert(item.investment_amount).unit}}</span>
                                            </div>
                                            <div class="detail-money-gray">预计投资总额</div>
                                        </div>
                                    </el-col>
                                    <el-col :span="3">
                                        <div class="grid-content detail-money-div">
                                            <div><span
                                                    class="detail-money-num-big">{{unitConvert(item.tax_amount).num}}</span>
                                                <span
                                                        class="detail-money-num-small">{{unitConvert(item.tax_amount).unit}}</span>
                                            </div>
                                            <div class="detail-money-gray">预计税金总额</div>
                                        </div>
                                    </el-col>
                                    <el-col :span="3">
                                        <div class="grid-content">
                                            <el-button type="warning" class="detail-btn" @click="detail(item)">项目详情
                                            </el-button>
                                        </div>
                                    </el-col>
                                </el-row>
                            </div>

                        </div>
                        <div class="pag">
                            <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page.sync="currentPage"
                                    :page-sizes="[10, 20, 30, 40]"
                                    :page-size="pageSize"
                                    layout="sizes, prev, pager, next"
                                    :total="total">
                            </el-pagination>
                        </div>
                    </div>

                </el-col>
            </el-row>
        </el-main>
    </div>
</template>
<script>

    const industrys = ['汽车工业', '房地产建筑', '化工日化', '机械设备', '家电数码', '建材合金', '纺织服装', '轻工业', '制造业', '高新技术', '农林牧渔', '科技服务', '电子产业', '服务业', '贸易行业', '开采业', '节能环保', '能源电力', '新材料产业', '文化服务业', '航空航天', '国防科技产业', '金融保险', '加工产业', '大数据', '工程机械', '基因技术', '军民融合', '汽车制造', '区块链', '人工智能', '生物医药', '新能源装备', '化工产业', '先进轨道交通装备', '新材料', '移动互联网', '装配式建筑', '电动汽车', '5G产业', '新信息产业', '高端装备产业', '无人机', '检验检测行业', '交通运输', '医疗医药', '视频饮料', '旅游酒店餐馆',];
    const diquOptions = ['上海', '河北', '辽宁', '黑龙江', '浙江', '福建', '山东', '湖北', '广东', '四川', '云南', '甘肃', '台湾', '广西', '重庆', '山西', '吉林', '江苏', '安徽', '江西', '河南', '湖南', '贵州', '海南', '陕西', '青海', '内蒙古', '西藏', '新疆'];
    const shijianOptions = ['近一个月', '厂房', '资金', '仓库'];
    const zhuangtaiOptions = [{"value": "1", "text": "有效", "title": "有效"}, {"value": "2", "text": "失效", "title": "失效"}];

    import SearchSelect from '@/common/SearchSelect'
    // import AreaSelect from '@/common/AreaSelect'
    import {getAction} from '@/api/api'
    import {Message} from "element-ui";
    import ProjectLibraryDetail from '@/views/projectLibraryDetail'
    import {initDictOptions, filterDictText} from '@/components/dict/JDictSelectUtil'

    export default {
        name: "projectLibrary",
        filters: {
            ellipsis (value) {
                if (!value) return ''
                if (value.length > 18) {
                    return value.slice(0,18) + '...'
                }
                return value
            }
        },
        components: {
            SearchSelect,
            ProjectLibraryDetail,
            // AreaSelect,
        },
        data() {
            return {
                loading: false,
                show: false,
                isAuth: true,
                token: "",
                centerDialogVisible: false,
                squareUrl:
                    "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
                profile: "",
                demandTypes: [],
                industrys: [],
                investmentAmountsRmb: [],
                investmentAmountsMy: [],
                intentAreas: [],
                releaseTimes: shijianOptions,
                status: zhuangtaiOptions,
                currentPage: 1,
                act_bg_index: 0,
                pageSize: 10,
                total: 0,
                search: {
                    pageNo: "",
                    pageSize: ""
                },
                url: {
                    list: "/merchants/projectLibrary/list",
                    getGroupConcatString: "/merchants/projectLibrary/getGroupConcatString",
                },
                listData: ""
            };
        },
        created() {
            this.initDictConfig()
            this.searchQuery()
        },
        methods: {
            initDictConfig() {
                initDictOptions('zs_project_library_demand_type').then((res) => {
                    if (res.success) {
                        this.demandTypes = res.result
                    }
                })
                initDictOptions('zs_project_library_investment_amount').then((res) => {
                    if (res.success) {
                        let oriArr = res.result;
                        oriArr.sort((a, b) => {
                            return a.value.replace("_", "") * 1 - b.value.replace("_", "") * 1;
                        })
                        for (let i of oriArr) {
                            if (/^1_\d/i.test(i.value)) {
                                this.investmentAmountsRmb.push(i);
                            } else {
                                this.investmentAmountsMy.push(i);
                            }
                        }
                    }
                })
                getAction(this.url.getGroupConcatString, {}).then((res) => {
                    if (res.success) {
                        var intentArea = res.result.intentArea
                        var industry = res.result.industry
                        if (industry.length != 0) {
                            this.industrys = this.forEachArr(industry)
                        }
                        if (intentArea.length != 0) {
                            this.intentAreas = this.forEachArr(intentArea)
                        }
                    } else {
                        Message({
                            title: "提示",
                            message: res.message,
                            type: "error",
                            duration: 1500
                        });
                    }
                })

            },

            forEachArr(arr) {
                var resArr = []
                for (var j = 0, len = arr.length; j < len; j++) {
                    var res = {}
                    res['value'] = arr[j]
                    res['text'] = arr[j]
                    res['title'] = arr[j]
                    resArr.push(res)
                }
                return resArr;
            },
            handleSizeChange(val) {
                this.pageSize = val
                this.searchQuery()
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.searchQuery()
                console.log(`当前页: ${val}`);
            },
            searchQuery() {
                this.loading = true
                this.search.pageNo = this.currentPage
                this.search.pageSize = this.pageSize
                getAction(this.url.list, this.search).then((res) => {
                    if (res.success) {
                        this.loading = false
                        this.total = res.result.total;
                        this.currentPage = res.result.current;
                        this.listData = res.result.records
                    } else {
                        this.loading = false
                        Message({
                            title: "提示",
                            message: res.message,
                            type: "error",
                            duration: 1500
                        });
                    }
                })
            },
            clearSearchParam() {
                this.search.reset = !!this.search.reset ? 0 : 1;
                this.searchQuery();
            },
            detail: function (record) { 
                sessionStorage.setItem("projectDetialRecord",JSON.stringify(record));
                this.$router.push({name: 'ProjectLibraryDetail', params: {record: record}});
            },
            unitConvert: function (num) {
                var moneyUnits = ["万元", "亿元", "万亿"]
                var dividend = 10000;
                var curentNum = num;
                //转换数字
                var curentUnit = moneyUnits[0];
                //转换单位
                for (var i = 0; i < 4; i++) {
                    curentUnit = moneyUnits[i]
                    if (this.strNumSize(curentNum) < 5) {
                        break;
                    }
                    curentNum = curentNum / dividend
                }
                var m = {num: 0, unit: ""}
                if (curentUnit == '万元') {
                    m.num = curentNum.toFixed(0)
                } else {
                    m.num = Math.floor(curentNum * 100) / 100
                }
                m.unit = curentUnit;
                return m;
            },
            strNumSize: function (tempNum) {
                var stringNum = tempNum.toString()
                var index = stringNum.indexOf(".")
                var newNum = stringNum;
                if (index != -1) {
                    newNum = stringNum.substring(0, index)
                }
                return newNum.length
            }
        },
    };
</script>

<style scoped>
    .el-checkbox-group {

    }

    .detail-btn {
        width: 80%;
        height: 44px;
        background: rgba(250, 100, 0, 1);
        border-radius: 4px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .detail-money-num-big {
        /* width:38px; */
        height: 20px;
        font-size: 20px;
        font-family: DINPro-Medium, DINPro;
        font-weight: 500;
        color: rgba(250, 100, 0, 1);
        line-height: 20px;
    }

    .detail-money-num-small {
        /* width:24px; */
        height: 12px;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(250, 100, 0, 1);
        line-height: 12px;
    }

    .detail-money-gray {
        /* width:84px; */
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 14px;
    }

    .detail-money-div {
        text-align: left;
    }

    .detail-words {

    }

    .detail-words-gray {
        /* width:64px; */
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        line-height: 16px;
        margin-left: 10px;
    }

    .detail-words-title {
        /* width:180px; */
        height: 20px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 1);
        line-height: 20px;
    }

    .detail-words-tag {
        /* width:64px; */
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        line-height: 16px;
        padding: 5px 0;
        margin-top: 5px;
    }

    .detail-content-div {
        padding: 20px 0;
        padding-left: 10px;
    }

    .detail-words-pic {
        width: 80px;
        height: 80px;
        padding: 5px;
    }

    .project-detail {
        margin-top: 60px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        /* padding: 20px; */
    }

    .tag-content-left {
        /* height:14px; */
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 14px;
        padding: 3px 0;
        /* text-align:left; */
    }

    .tag-content-right {
        margin-left: 5px;
        text-align: left;
    }

    .body-background {
        /* width:1920px; */
        /* height:2890px; */
        /* background:rgb(10, 34, 58); */
    }

    .tag-div {
        width: 100%;
        /* height:360px; */
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        margin-top: 14px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px;

    }

    .banner-pic {
        width: 100%;
        height: 440px;
        background-image: url("../assets/pic/xiagmuku_banner.png");
        background-size: cover;
    }

    h1 {
        font-size: 44px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        line-height: 44px;
    }

    .banner-pic-words {
        /* height:20px; */
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        line-height: 20px;
        padding-top: 30px;
    }

    .banner-search {
        width: 828px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px;
        margin-top: 64px;
        padding: 30px;
    }

    .banner-search span {
        /* width:120px; */
        height: 24px;
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        line-height: 24px;
    }

    .banner-search-input {
        /* display:inline-block; */
        margin-top: 15px;
    }

    .search-submit {
        width: 120px;
        height: 48px;
        background: rgba(0, 64, 152, 1);
        border-radius: 2px;
        margin-left: 10px;
        font-size: 16px;
        letter-spacing: 5px;
    }

    .search-submit p {
        /* width:32px; */
        height: 16px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        line-height: 16px;
        /*margin-top: 15px;*/
    }

    .time {
        font-size: 13px;
        color: #999;
    }

    .bottom {
        margin-top: 13px;
        line-height: 12px;
    }

    .button {
        padding: 0;
        float: right;
    }

    .image {
        width: 100%;
        display: block;
    }

    .clearfix:before,
    .clearfix:after {
        display: table;
        content: "";
    }

    .clearfix:after {
        clear: both
    }

    .pag {
        padding: 20px;
    }

    .detail-words {
        padding: 20px
    }

    .left-tag {
        display: inline-block;
        width: 80px;
    }
</style>